import React, { CSSProperties, SyntheticEvent } from 'react';
import classnames from 'classnames';

import {Color, ColorScheme} from '../../types';

import './Row.scss';

type RowGapSize = 'tiny' | 'extra-sm' | 'sm' | 'md' | 'lg' | 'extra-lg' | 'huge' | 'biggest';
type RowHorizontal = 'center' | 'start' | 'space-between' | 'end';
type RowVertical = 'start' | 'center' | 'space-between' | 'end';
type RowBorderRadius = 'all' | 'top' | 'bottom';
type RowBorder = 'all' | 'bottom' | 'top' | 'right' | 'left';

const RowHorizontalToClassNameMap: Record<RowHorizontal, string> = {
  center: 'h-c',
  start: 'h-s',
  'space-between': 'h-s-b',
  end: 'h-e',
};

const RowVerticalToClassNameMap: Record<RowVertical, string> = {
  start: 'v-s',
  center: 'v-c',
  end: 'v-e',
  'space-between': 'v-s-b',
};

const RowGapSizeToClassNameMap: Record<RowGapSize, string> = {
  tiny: 'g-tiny',
  'extra-sm': 'g-extra-sm',
  sm: 'g-sm',
  md: 'g-md',
  lg: 'g-lg',
  'extra-lg': 'g-extra-lg',
  huge: 'g-huge',
  biggest: 'g-biggest',
};

const RowPaddingHorizontalSizeToClassNameMap: Record<RowGapSize, string> = {
  tiny: 'ph-tiny',
  'extra-sm': 'ph-extra-sm',
  sm: 'ph-sm',
  md: 'ph-md',
  lg: 'ph-lg',
  'extra-lg': 'ph-extra-lg',
  huge: 'ph-huge',
  biggest: 'ph-biggest',
};

const RowPaddingVerticalSizeToClassNameMap: Record<RowGapSize, string> = {
  tiny: 'pv-tiny',
  'extra-sm': 'pv-extra-sm',
  sm: 'pv-sm',
  md: 'pv-md',
  lg: 'pv-lg',
  'extra-lg': 'pv-extra-lg',
  huge: 'pv-huge',
  biggest: 'pv-biggest',
};

const RowBorderRadiusToClassNameMap: Record<RowBorderRadius, string> = {
  top: 'br-t',
  bottom: 'br-b',
  all: 'br-all',
};

const RowBorderToClassNameMap: Record<RowBorder, string> = {
  all: 'b',
  'top': 'b-t',
  'bottom': 'b-b',
  'right': 'b-r',
  'left': 'b-l',
};

export type RowProps = {
  tagName?: 'li' | 'span' | 'ul' | React.FC,
  className?: string,
  id?: string,
  directionHorizontal?: boolean,
  horizontal?: RowHorizontal,
  vertical?: RowVertical,
  autoColumns?: boolean,
  border?: RowBorder,
  gap?: RowGapSize,
  paddingVertical?: RowGapSize,
  paddingHorizontal?: RowGapSize,
  color?: ColorScheme,
  bgColor?: Color,
  hover?: boolean,
  active?: boolean,
  borderRadius?: RowBorderRadius,
  layout?: 'flex' | 'grid' | 'block',
  style?: CSSProperties;
  children?: React.ReactNode,
  onClick?: (e: SyntheticEvent<any, MouseEvent>) => void;
  dataAttributes?: {[field: string]: any},
};

// Experimental component. Need to reduce props count.
const Row: React.FC<RowProps> = (props) => {
  const Tag = props.tagName || 'div';

  return (
    <Tag
      className={classnames('row', props.className, props.color,
        props.gap ? RowGapSizeToClassNameMap[props.gap] : '',
        props.paddingVertical ? RowPaddingVerticalSizeToClassNameMap[props.paddingVertical] : '',
        props.paddingHorizontal
          ? RowPaddingHorizontalSizeToClassNameMap[props.paddingHorizontal]
          : '',
        props.horizontal ? RowHorizontalToClassNameMap[props.horizontal] : '',
        props.vertical ? RowVerticalToClassNameMap[props.vertical] : '',
        props.borderRadius ? RowBorderRadiusToClassNameMap[props.borderRadius] : '',
        props.border ? RowBorderToClassNameMap[props.border] : '',
        props.directionHorizontal ? 'h' : 'v', {
          'auto-cols': props.autoColumns,
          'flex': props.layout === 'flex',
          'grid': props.layout === 'grid',
          'hover': props.hover,
          'active': props.active,
        })}
      style={{
        background: props.bgColor ? 'var(--' + props.bgColor + ')' : undefined,
        ...props.style
      }}
      id={props.id}
      {...props.dataAttributes}
      onClick={props.onClick}
    >{props.children}</Tag>
  );
};

Row.defaultProps = {
  layout: 'grid',
};

export default Row;
