import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import { Row, Typography } from '../index';
import Scroll from '../scroll/Scroll';
import { Color } from '../../types';
import { sortAlphabetically } from '../../utils/sorts';

import './AlphabeticFilter.scss';

type ItemsType = any;

type AlphabeticFilterProps = {
  color?: Color,
  items: ItemsType[],
  getFirstCharFn: (item: ItemsType) => string,
  renderItems: (items: ItemsType[]) => React.ReactNode,
};

const AlphabeticFilter: React.FC<AlphabeticFilterProps> = ({
  items, getFirstCharFn, renderItems, color
}) => {
  const [filterChar, setFilterChar] = useState('');

  const [chars, chatToItemsMap] = useMemo(() => {
    const chars = new Set<string>();
    const chatToItemsMap: Record<string, ItemsType[]> = {};

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const char = getFirstCharFn(item);

      if (!chatToItemsMap[char]) {
        chatToItemsMap[char] = [];
      }

      chatToItemsMap[char].push(item);
      chars.add(char);
    }

    return [sortAlphabetically(Array.from(chars), true, (char) => char), chatToItemsMap];
  }, [items, getFirstCharFn]);

  return (
    <Row directionHorizontal style={{ gridTemplateColumns: '1fr auto', height: '100%', }}>
      <Scroll fixScrollbarCoversContent>
        {
          (filterChar ? [filterChar] : chars).map((char) => {
            return (
              <Row key={char}>
                <Typography component='h3' variant='poppins'>{char.toUpperCase()}</Typography>
                {renderItems(chatToItemsMap[char])}
              </Row>
            );
          })
        }
      </Scroll>
      <Row gap='sm' vertical='start' style={{ justifyItems: 'center' }}>
        {
          chars.map((char) => {
            return (
              <button
                key={char}
                type='button'
                className={classnames('btn-char', color, {
                  active: char === filterChar,
                })}
                onClick={() => {
                  setFilterChar(char === filterChar ? '' : char);
                }}
              >{char.toUpperCase()}</button>
            );
          })
        }
      </Row>
    </Row>
  );
};

AlphabeticFilter.defaultProps = {
  color: 'blue',
};

export default AlphabeticFilter;
