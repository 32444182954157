import {useEffect} from 'react';

import {EventKeys} from '../utils/dom';

export const useListenKey = (config: {
  enabled: boolean,
  key: EventKeys,
  onKeyDown: (e: KeyboardEvent) => void
}) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === config.key) {
        config.onKeyDown(event);
      }
    };

    if (config.enabled) {
      window.addEventListener('keydown',  keyDownHandler);
    }

    return () => {
      if (config.enabled) {
        window.removeEventListener('keydown', keyDownHandler);
      }
    };
  }, [config]);
};
