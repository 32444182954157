import React from 'react';

import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';
import { ColorScheme } from '../../types';

import './SelectCheckmark.scss';

interface SelectCheckmarkProps {
  color?: ColorScheme;
}

const SelectCheckmark: React.FC<SelectCheckmarkProps> = (props) => {
  return (
    <FontIconView icon={AeFontIcons.selectedMark} className={'select-checkmark ' + props.color} />
  );
};

SelectCheckmark.defaultProps = {
  color: 'blue',
};

export default SelectCheckmark;
