import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Row, TextArea, Typography } from '../index';
import { MessageItemType } from '../../api/chat.types';
import { useModalContext } from '../modal/ModalProvider';
import { useMainContext } from '../../MainProvider';
import { useSetVetterMessageStatus } from '../../query/chat';
import { renderField } from '../custom-field/customField';
import GradeStarsView from './GradeStarsView';
import { SeekerCommonType } from '../../api/seekers.types';
import { makeLabelFullName } from '../../utils/common';
import { useGetChatListEvangelists } from '../../query/evangelists';

const PastorCommentTitle = () => {
  const { t } = useTranslation();

  return (
    <Typography
      component='h4'
      color='text-title-light'
    >{t('supervisorComment')}</Typography>
  );
};

interface ModalMessageReviewEditFormData {
  notes: string,
  isReject: boolean,
}
interface ModalMessageReviewEditProps {
  message: MessageItemType,
  recipientId: string,
  evangelistId: string,
}

const ModalMessageReviewEdit: React.FC<ModalMessageReviewEditProps> = (props) => {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();
  const [score, setScore] = useState(Number(props.message.msgScore));
  const { setVetterMessageStatusMutation } = useSetVetterMessageStatus();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ModalMessageReviewEditFormData>();

  const handleSubmitValid: SubmitHandler<ModalMessageReviewEditFormData> = async (data) => {
    await setVetterMessageStatusMutation.mutateAsync({
      isUpdate: !!props.message.msgScore,
      msgType: props.message.msgType,
      recipientId: props.recipientId,

      evangelistId: props.evangelistId,
      messageId: props.message.id,
      msgStatus: data.isReject ? 'denied' : 'approved',
      msgNotes: data.notes,
      msgScore: score.toString(),
    });
    
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitValid)}>
      <Row gap='md'>
        <GradeStarsView score={score} onSetScore={setScore} />

        <PastorCommentTitle />
        {renderField(TextArea, {
          ...register('notes'),
          defaultValue: props.message.msgNotes || '',
          errorText: t(errors.notes?.message || ''),
          textareaHeight: 'md',
          placeholder: t('hintMessage'),
        })}

        <Row directionHorizontal gap='md'>
          <Button
            size='sm'
            type="submit"
            disabled={isSubmitting}
            variant='outline'
            color='red'
            onClick={() => {
              setValue('isReject', true);
            }}
          >
            {t('reject')}
          </Button>
          <Button
            size='sm'
            type="submit"
            disabled={isSubmitting}
            variant='outline'
            color='blue'
            onClick={() => {
              setValue('isReject', false);
            }}
          >
            {t('approve')}
          </Button>
        </Row>
      </Row>
    </form>
  );
};

export const useModalMessageReviewEdit = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  return (message: MessageItemType, recipientId: string, evangelistId: string) => {
    openModal(<ModalMessageReviewEdit message={message} recipientId={recipientId} evangelistId={evangelistId} />, {
      title: t(message.msgStatus === 'verifying' ? 'reviewMessage' : 'editReview'),
      titleAlign: 'center',
      widthSize: 'sm',
    });
  };
};

interface ModalMessageReviewProps {
  message: MessageItemType,
  recipientId: string,
  evangelistId: string,
}

const ModalMessageReview: React.FC<ModalMessageReviewProps> = ({
  message, recipientId, evangelistId
}) => {
  const { t } = useTranslation();
  const { isUiRoleNotDM } = useMainContext();
  const { closeModal } = useModalContext();
  const openModalMessageReviewEdit = useModalMessageReviewEdit();

  const isRejected = message.msgStatus === 'denied';
  const isNotes = message.msgNotes && message.msgNotes !== 'null';

  return (
    <Row gap='md'>
      <Typography
        align='center'
        component='body1'
        color='text-title-light'
      >
        {isUiRoleNotDM && t('stars', {
          count: Number(message.msgScore),
        }) + ' - '}
        <Typography color={isRejected ? 'red' : 'green'}>
          {t(isRejected ? 'rejected' : 'approved')}
        </Typography>
      </Typography>

      <Row gap='md'>
        {isNotes && (
          <>
            <PastorCommentTitle />
            <Typography component='body2'>{message.msgNotes}</Typography>
          </>
        )}
      </Row>

      {isUiRoleNotDM && (
        <Button
          variant='outline'
          color='blue'
          onClick={() => {
            closeModal();
            openModalMessageReviewEdit(message, recipientId, evangelistId);
          }}
        >
          {t('editReview')}
        </Button>
      )}
    </Row>
  );
};

export const useModalMessageReview = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  return (message: MessageItemType, recipientId: string, evangelistId: string) => {
    openModal((message.msgStatus === 'denied' || message.msgStatus === 'approved')
      ? <ModalMessageReview message={message} recipientId={recipientId} evangelistId={evangelistId} />
      : <ModalMessageReviewEdit message={message} recipientId={recipientId} evangelistId={evangelistId} />, {
      title: t('reviewMessage'),
      titleAlign: 'center',
      widthSize: 'sm',
    });
  };
};

interface ModalMessageReviewAllProps {
  seeker: SeekerCommonType
}

const ModalMessageReviewAll: React.FC<ModalMessageReviewAllProps> = ({ seeker }) => {
  const { t } = useTranslation();
  const { closeModal, updateModal } = useModalContext();
  const [score, setScore] = useState(5);
  const { setVetterMessageStatusMutation } = useSetVetterMessageStatus();

  const { getChatListEvangelistsItemById } = useGetChatListEvangelists();

  const evangelist = seeker.evangelistId ? getChatListEvangelistsItemById(seeker.evangelistId) : null;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ModalMessageReviewEditFormData>();

  const handleSubmitValid: SubmitHandler<ModalMessageReviewEditFormData> = async (data) => {
    await setVetterMessageStatusMutation.mutateAsync({
      isUpdate: false,
      recipientId: seeker.id,
      messageId: 'all',
      msgStatus: data.isReject ? 'denied' : 'approved',
      msgNotes: data.notes,
      msgScore: score.toString(),
      evangelistId: seeker.evangelistId,
    });

    closeModal();
  };

  useEffect(() => {
    updateModal({ loading: isSubmitting });
  }, [isSubmitting]);

  return (
    <form onSubmit={handleSubmit(handleSubmitValid)}>
      <Row gap='md'>
        <Typography component='body2' align='center' dangerouslySetInnerHTML={{
          __html: t('vetAllMessagesSubtitle', {
            name: evangelist ? makeLabelFullName(evangelist) : '…',
          }),
        }}/>

        <GradeStarsView disabled={isSubmitting} score={score} onSetScore={setScore} />

        {/*<PastorCommentTitle />*/}

        {/*{renderField(TextArea, {*/}
        {/*  ...register('notes'),*/}
        {/*  defaultValue: '',*/}
        {/*  errorText: t(errors.notes?.message || ''),*/}
        {/*  textareaHeight: 'md',*/}
        {/*  placeholder: t('hintMessage'),*/}
        {/*})}*/}

        <Row directionHorizontal gap='md'>
          <Button
            size='sm'
            type="submit"
            disabled={isSubmitting}
            variant='outline'
            color='red'
            onClick={() => {
              setValue('isReject', true);
            }}
          >
            {t('reject')}
          </Button>
          <Button
            size='sm'
            type="submit"
            disabled={isSubmitting}
            variant='outline'
            color='blue'
            onClick={() => {
              setValue('isReject', false);
            }}
          >
            {t('approve')}
          </Button>
        </Row>

        {
          // Please, implement some general loader for such cases, it's a fast decision
          isSubmitting && (
            <Typography
              component='h5'
              color='gray'
              align='center'
              style={{
                position: 'absolute',
                bottom: 3,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {t('loading')}
            </Typography>
          )
        }
      </Row>
    </form>
  );
};

export const useModalMessageReviewAll = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const open = (seeker: SeekerCommonType) => {
    openModal(<ModalMessageReviewAll seeker={seeker} />, {
      title: t('vetAllMessages'),
      titleAlign: 'center',
      widthSize: 'sm',
    });
  };

  return { open };
};
