import {
  AdminEvangelistItem,
  APIAdminEvangelistItem,
  APIEvangelistItem,
  APIMessageCounts,
  APIMessageMetaData,
  APIQuickAdminEvangelistItem,
  EvangelistItemType,
  LeaderStats,
  LeaderStatsAPI,
  MessageCounts,
  MessageMetaData,
  QuickAdminEvangelistItem,
  StatsMap
} from './evangelists.types';

const normalizeMessageCounts = (msgCounts: APIMessageCounts | null): MessageCounts => {
  return {
    unread: Number(msgCounts?.unread) || 0,
    outgoing: Number(msgCounts?.outgoing) || 0,
    incoming: Number(msgCounts?.incoming) || 0,
    ungraded: Number(msgCounts?.ungraded) || 0,
  };
};

const normalizeMessageMetaData = (lastMessage: APIMessageMetaData | null): MessageMetaData => {
  return {
    date: lastMessage?.date || '',
    text: lastMessage?.text || '',
    sourceType: lastMessage?.sourceType || null,
  };
};

export const normalizeChatItemEvangelist = (
  item: APIEvangelistItem
): EvangelistItemType => {
  return {
    id: item.id,
    firstname: item.firstname,
    lastname: item.lastname,
    activeSeekers: item.activeSeekers,
    inactiveSeekers: item.inactiveSeekers,
    totalSeekers: item.totalSeekers,
    campaigns: item.campaigns,
    score: item.score,
    msgScore: item.msgScore,
    responseTime: item.responseTime,
    lastLeaderContact: item.lastLeaderContact,
    evangelistId: undefined,
    ...normalizeMessageCounts(item.msgCounts),
    ...normalizeMessageMetaData(item?.lastMessage),
  };
};

export const normalizeChatItemAdminEvangelist = (
  item: APIAdminEvangelistItem
): AdminEvangelistItem => {
  item.msgCounts = item.messageCounts;

  let totalLimitCount = 0;
  let totalRequestCount = 0;
  for (let i = 0; i < item.categories.length; i++) {
    const category = item.categories[i];
    totalLimitCount += Number(category.categoryLimit);
    totalRequestCount += Number(category.requestCount);
  }

  return {
    id: item.id,
    evangelistId: Number(item.id),
    firstname: item.firstname,
    lastname: item.lastname,
    status: item.status,
    email: item.email,
    phone: item.phone,
    verifiedByPastor: item.verifiedByPastor,
    approvedMsgs: item.approvedMsgs,
    declinedMsgs: item.declinedMsgs,
    avgScore: item.avgScore,
    roleStatus: item.roleStatus,
    role: item.role,
    lastLeaderContact: null,
    campaigns: item.campaigns,
    msgScore: item.msgScore,
    score: item.score,
    responseTime: item.responseTime,
    activeSeekers: item.activeSeekers,
    totalSeekers: item.totalSeekers,
    languages: item.languages,
    totalLimitCount,
    totalRequestCount,
    createDate: item.createDate,
    lastAccess: item.lastAccess,
    lastLogin: item.lastLogin,
    vetterId: item.vetterId,
    ...normalizeMessageCounts(item.msgCounts),
    ...normalizeMessageMetaData(item?.lastMessage),
  };
};

export const normalizeQuickAdminEvangelistItem = (item: APIQuickAdminEvangelistItem): QuickAdminEvangelistItem => {
  return {
    id: item.id,
    activeSeekers: parseInt(item.activeSeekers),
    languages: item.languages,
    firstname: item.firstname,
    lastname: item.lastname,
    status: item.status,
    email: item.email,
    verifiedByPastor: item.verifiedByPastor,
    roleStatus: item.roleStatus,
    role: item.role,
    campaigns: item.campaigns,
    msgScore: item.msgScore,
    score: item.score,
    responseTime: item.responseTime,
    createDate: item.createDate,
    lastLogin: item.lastLogin,
    vetterId: item.vetterId,
    ...normalizeMessageCounts(item.msgCounts),
  };
};

const normalizeLeaderStats = (item: LeaderStatsAPI): LeaderStats => {
  return {
    msgsGraded: parseInt(item.msgsGraded) || 0,
    msgsNoted: parseInt(item.msgsNoted) || 0,
    teamChatsSent: parseInt(item.teamChatsSent) || 0,
    privateChatsSent: parseInt(item.privateChatsSent) || 0,
    msgsRejected: parseInt(item.msgsRejected) || 0
  };
};

export const normalizeLeaderStatsMap = (map: StatsMap<LeaderStatsAPI>): StatsMap<LeaderStats> => {
  const statsNormalized: StatsMap<LeaderStats> = {};

  for (const key in map) {
    statsNormalized[key] = normalizeLeaderStats(map[key]);
  }

  return statsNormalized;
};
