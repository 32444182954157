import { apiRequest } from './api';
import { ApiParamUserData } from './api.types';
import { TreeLanguageItemApi } from './treeLanguages.types';
import { normalizeTreeLanguageItem } from './treeLanguages.utils';

export const fetchTreeLanguagesRequest = (userData: ApiParamUserData) =>
  apiRequest<{ languages: TreeLanguageItemApi[] }>('getResourceMapLanguages', {
    id: userData.id,
    agentToken: userData.agentToken
  }).then((response) => response.languages.map(normalizeTreeLanguageItem));
