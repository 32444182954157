import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetNextPageParamFunction,
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';

import { useMainContext } from '../MainProvider';
import { useGetByIdWithCache } from '../hooks/useCache';
import {
  PageRequestProps,
  PromiseResolveValue, SetQueryDataFnTyped,
  UpdateQueryDataFn,
  UpdateQueryDataFnWithStatus,
  UpdateQueryFnStatus
} from '../types';
import {
  AdminSeekerItem,
  SeekerCommonType,
  SeekerItemType,
  SeekersListStatus
} from '../api/seekers.types';
import {
  addSeekerRequest,
  AddSeekerRequestParamSeeker,
  deleteSeekerRequest,
  getSeekerListRequest,
  getSeekersRequest,
  SeekerListParams,
  SeekerSortType,
  transferSeekerRequest,
  TransferSeekerRequest,
} from '../api/seekers';
import { useNotificationContext } from '../PushNotificationsProvider';
import { NO_NOTIFICATIONS_RE_FETCH_INTERVAL } from '../utils/constants';
import { createChatItemUpdated, MessageFactors } from '../utils/chat';
import { updateBroadcastLists } from './broadcasts';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { makeLabelFullName } from '../utils/common';
import { ItemFilter } from './common.types';

export const QUERY_KEY_SEEKERS_LIST = 'seekersList';
export const QUERY_KEY_ALL_SEEKERS = 'allSeekers';
export const QUERY_KEY_SEEKERS_PAGE = 'seekersPage';

export const makeQueryKeySeekers = (status: SeekersListStatus, isLeader: boolean) => {
  if (status === 'active') {
    return [QUERY_KEY_SEEKERS_LIST, 'active', isLeader];
  }

  return [QUERY_KEY_SEEKERS_LIST, 'inactive', isLeader];
};

const setQueryDataSeekersList: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getSeekersRequest>>, {
  isUiRoleLeader: boolean;
  seekerListStatus: SeekersListStatus;
}> = (queryClient, cb, queryKeyParams) => {
  const queryKey = makeQueryKeySeekers(
    queryKeyParams.seekerListStatus, queryKeyParams.isUiRoleLeader
  );

  // FIXME: May be to think how to get rid of 'any'.
  queryClient.setQueryData<any>(queryKey, cb);
};

type QueryClientAllSeekersData = InfiniteData<
  PromiseResolveValue<ReturnType<typeof getSeekerListRequest>>
> | undefined;

const setQueryDataAllSeekers: SetQueryDataFnTyped<QueryClientAllSeekersData> = (
  queryClient,
  cb
) => {
  queryClient.setQueryData<any>(QUERY_KEY_ALL_SEEKERS, cb);
};

export const updateSeekerListItem: UpdateQueryDataFnWithStatus<{
  isUiRoleLeader: boolean;
  seekerListStatus: SeekersListStatus;
  filter: ItemFilter<SeekerItemType>,
  newProperties?: Partial<SeekerItemType>,
  messagesCountsFactors?: Partial<MessageFactors>,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';

  setQueryDataSeekersList(queryClient, (oldQueryData) => {
    if (oldQueryData) {
      const {filter, newProperties, messagesCountsFactors} = options;

      return {
        evangelists: oldQueryData.evangelists,
        seekers: oldQueryData.seekers.map((item) => {
          if (typeof filter === 'string' ? item.id === filter : filter(item)) {
            status = 'success';

            return createChatItemUpdated(item, newProperties || {}, messagesCountsFactors);
          }

          return item;
        })
      };
    }

    status = 'fail/no-data';

    return oldQueryData;
  }, {
    isUiRoleLeader: options.isUiRoleLeader,
    seekerListStatus: options.seekerListStatus,
  });

  return {
    status,
  };
};

export const updateSeekerListByIds: UpdateQueryDataFn<{
  ids: string[],
  isUiRoleLeader: boolean;
  seekerListStatus: SeekersListStatus;
  newProperties: Partial<SeekerItemType>,
  messagesCountsFactors?: {
    outgoing?: number,
    unread?: number,
  },
}> = (queryClient, options) => {
  if (!options.ids.length) {
    // eslint-disable-next-line no-console
    console.log('Specify seeker ids for {updateSeekerListByIds}', 'background: red; color: white;');
    return;
  }

  setQueryDataSeekersList(queryClient, (oldData) => {
    if (oldData?.seekers.length) {
      const oldDataSeekersSpread = [...oldData?.seekers];

      const {ids, newProperties, messagesCountsFactors} = options;

      let seeker;
      for (let i = 0; i < oldDataSeekersSpread.length; i++) {
        seeker = oldDataSeekersSpread[i];

        if (ids.includes(seeker.id)) {
          oldDataSeekersSpread[i] = createChatItemUpdated(
            oldDataSeekersSpread[i],
            newProperties || {},
            messagesCountsFactors,
          );
        }
      }

      return {
        seekers: oldDataSeekersSpread,
        evangelists: oldData.evangelists,
      };
    }

    return oldData;
  }, {
    isUiRoleLeader: options.isUiRoleLeader,
    seekerListStatus: options.seekerListStatus,
  });
};

export const removeSeekerListItemsByIds: UpdateQueryDataFn<{
  ids: string[],
  isUiRoleLeader: boolean;
  seekerListStatus: SeekersListStatus;
}> = (queryClient, options) => {
  setQueryDataSeekersList(queryClient, (oldData) => {
    if (oldData?.seekers.length) {
      const removeIds = options.ids;
      
      return {
        seekers: oldData.seekers.filter((seeker) => {
          return !removeIds.includes(seeker.id);
        }),
        evangelists: oldData.evangelists,
      };
    }

    return oldData;
  }, {
    isUiRoleLeader: options.isUiRoleLeader,
    seekerListStatus: options.seekerListStatus,
  });
};

export const pushToSeekerList: UpdateQueryDataFn<{
  newItem: SeekerItemType,
  isUiRoleLeader: boolean;
  seekerListStatus: SeekersListStatus;
}> = (queryClient, options) => {
  setQueryDataSeekersList(queryClient, (oldData) => {
    const newItem = options.newItem;

    return {
      evangelists: oldData ? [...oldData.evangelists, newItem.id] : [newItem.id],
      seekers: oldData ? [...oldData.seekers, newItem] : [newItem],
    };
  }, {
    isUiRoleLeader: options.isUiRoleLeader,
    seekerListStatus: options.seekerListStatus,
  });
};

const seekersListInitialData: {
  seekers: SeekerItemType[],
  evangelists: string[],
} = {
  seekers: [],
  evangelists: [],
};

export const getSeekerId = (item: SeekerCommonType) => item.id;

export const useGetSeekers = (status: SeekersListStatus, config?: {
  enabled: boolean,
  staleTime?: number,
}) => {
  const {
    userData,
    isUiRoleNotDM,
    isRoleContainsDMRole,
  } = useMainContext();
  const { isAppReceivesNotifications } = useNotificationContext();

  const queryKey = useMemo(
    () => makeQueryKeySeekers(status, isUiRoleNotDM), [status, isUiRoleNotDM]
  );

  const {
    isLoading,
    data = seekersListInitialData,
    isFetching,
    refetch,
    isError,
  } = useQuery(queryKey, () =>
    getSeekersRequest(userData, status, isUiRoleNotDM, isRoleContainsDMRole), {
    refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
    staleTime: isAppReceivesNotifications ? Infinity : 0,
    ...config,
  });
  const get = useGetByIdWithCache(queryKey.toString(), data?.seekers, getSeekerId);

  return {
    isFetching,
    isSeekersLoading: isLoading,
    isSeekersError: isError,
    refetchSeekers: refetch,
    seekers: data?.seekers,
    getSeekerById: get,
  };
};

export const getAllSeekersId = (item: AdminSeekerItem) => item.id;

type QueryClientSeekersListData = InfiniteData<
  PromiseResolveValue<ReturnType<typeof getSeekerListRequest>>
> | undefined;

const adminSeekerDefault: QueryClientSeekersListData = {
  pages: [],
  pageParams: [],
};

export const SEEKERS_CHUNK_SIZE = 100;

const getNextPageParam: GetNextPageParamFunction<AdminSeekerItem[]> = (seekersInitialChunck, allChunks) => {
  let offset = 0;
  allChunks.forEach((item) => {
    offset += item.length;
  });
  let totalCount = 0;
  if (seekersInitialChunck.length > 0) {
    totalCount = seekersInitialChunck[0].totalCount || 0;
  }
  if (offset === totalCount) {
    return undefined;
  }

  return {
    offset,
    limit: SEEKERS_CHUNK_SIZE
  };
};

export const useGetAllSeekers = (
  options: SeekerListParams = {},
  config?: { staleTime?: number, enabled?: boolean }
) => {
  const { userData } = useMainContext();
  
  const queryKey = [QUERY_KEY_ALL_SEEKERS, ...(Object.values(options).flat())];

  const {
    isLoading,
    data = adminSeekerDefault,
    fetchNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isError,
    isFetching,
    isFetched,
    refetch,
  } = useInfiniteQuery(queryKey, async ({ pageParam }) => await getSeekerListRequest(userData, {
    offset: pageParam?.offset ?? 0,
    limit: pageParam?.limit ?? SEEKERS_CHUNK_SIZE,
    ...options,
  }), {
    getPreviousPageParam: () => undefined,
    getNextPageParam,
    ...config
  });

  const seekersFlat = useMemo(() => {
    return data.pages.flat();
  }, [data.pages]);

  const get = useGetByIdWithCache(queryKey, seekersFlat, getAllSeekersId);

  return {
    allSeekers: seekersFlat,
    refetchSeekers: refetch,
    getSeekerById: get,
    isAllSeekersFetching: isFetching,
    isAllSeekersLoading: isLoading,
    isAllSeekersFetched: isFetched,
    isAllSeekersError: isError,
    fetchNextPageAllSeekers: fetchNextPage,
    fetchPreviousPageAllSeekers: fetchPreviousPage,
    hasPreviousAllSeekersPage: hasPreviousPage,
    hasNextAllSeekersPage: hasNextPage,
    isFetchingAllSeekersNextPage: isFetchingNextPage,
    isFetchingAllSeekersPreviousPage: isFetchingPreviousPage,
  };
};

export const useGetSeekerList = (params: SeekerListParams, options?: { cacheTime?: number, enabled?: boolean }) => {
  const { userData } = useMainContext();

  const queryKey = [QUERY_KEY_SEEKERS_PAGE, ...Object.values(params)];

  const result = useQuery(queryKey, () => getSeekerListRequest(userData, params), {
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 60,//1 hour
    ...options,
  });

  return result;
};

export const useGetAllStatusesSeekers = () => {
  const {
    seekers: activeSeekers,
    isSeekersLoading: isActiveSeekersLoading,
    getSeekerById: getActiveSeekerById,
  } = useGetSeekers('active');
  const {
    seekers: inactiveSeekers,
    isSeekersLoading: isInactiveSeekersLoading,
    getSeekerById: getInactiveSeekerById,
  } = useGetSeekers('inactive');

  return {
    isSeekersLoading: isActiveSeekersLoading || isInactiveSeekersLoading,
    getSeekerById: (isActiveSeekersLoading || isInactiveSeekersLoading)
      ? () => undefined
      : (id: string) => getActiveSeekerById(id) || getInactiveSeekerById(id),
    seekers: [...activeSeekers, ...inactiveSeekers],
  };
};

export const useAddSeeker = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useToastEnhanced();
  const { userData, isUiRoleNotDM } = useMainContext();

  const addSeekerMutation = useMutation((params: {
    seekers: AddSeekerRequestParamSeeker[], broadcastId?: string
  }) => {
    return addSeekerRequest(userData, params.seekers, params.broadcastId);
  }, {
    onSuccess: (response, mutationPayload) => {
      const successfullyAddedSeekersIds: string[] = [];
      const successfullyAddedSeekersFullNames: string[] = [];
      const statusErrorChannelIds: string[] = [];

      // ===== Update seekers list logic ===== //
      response.seekerIds.forEach((seekerAddResult) => {
        if (seekerAddResult.status === 'success') {
          const seekerId = seekerAddResult.seekerId.toString();
          successfullyAddedSeekersIds.push(seekerId);

          const suitablePayloadData = mutationPayload.seekers.find(
            (seeker) => seeker.channelAddress === seekerAddResult.channelAddress
          );

          if (!suitablePayloadData) {
            return;
          }

          successfullyAddedSeekersFullNames.push(makeLabelFullName({
            firstname: suitablePayloadData.firstname,
            lastname: suitablePayloadData.lastname || '',
          }));

          pushToSeekerList(queryClient, {
            isUiRoleLeader: isUiRoleNotDM,
            seekerListStatus: 'active',
            newItem: {
              id: seekerId,
              status: 'active',
              sourceType: 's',
              church: null,
              alreadyBaptized: false,
              chatStatus: null,
              firstname: suitablePayloadData.firstname,
              lastname: suitablePayloadData.lastname || '',
              language: suitablePayloadData.language,
              locationCode: suitablePayloadData.zipcode || '',
              country: suitablePayloadData.country || '',
              city: suitablePayloadData.city,
              date: '',
              text: '',
              unread: 0,
              ungraded: 0,
              outgoing: 0,
              incoming: 0,
              open: 0,
              closed: 0,
              waiting: 0,
              prayer: 0,
              question: 0,
              study: 0,
            },
          });
        } else {
          statusErrorChannelIds.push(seekerAddResult.channelAddress);
        }
      });

      // ===== Update broadcast logic ===== //
      if (successfullyAddedSeekersIds.length) {
        if (mutationPayload.broadcastId) {
          updateBroadcastLists(queryClient, {
            broadcastId: mutationPayload.broadcastId,
            updateBroadcastItemSeekers: (oldSeekers) => {
              return [...oldSeekers, ...successfullyAddedSeekersIds];
            },
          });
        }
      }

      if (successfullyAddedSeekersFullNames.length || successfullyAddedSeekersIds.length) {
        showToast({
          title: t('addedInterests') + ':',
          body: successfullyAddedSeekersFullNames.length
            ? successfullyAddedSeekersFullNames.join(', ')
            : successfullyAddedSeekersIds.length,
        });
      }

      if (statusErrorChannelIds.length) {
        showToast({
          title: t('unableToAdd') + ':',
          body: statusErrorChannelIds.join(', '),
        }, {
          type: 'error',
        });
      }
    },
  });

  return {
    addSeekerMutation,
  };
};

export const useTransferSeeker = () => {
  const queryClient = useQueryClient();
  const { userData, isUiRoleCM, isUiRoleLeader, isUiRoleNotDM, isRoleAdmin } = useMainContext();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();

  return useMutation((data: TransferSeekerRequest & { dmName: string }) => (
    transferSeekerRequest(userData, {
      isUiRoleCM,
      isUiRoleLeader,
      isRoleAdmin,
    }, {
      seekerId: data.seekerId,
      destEvangelistId: data.destEvangelistId,
    })
  ), {
    onSuccess: (response, data) => {
      setQueryDataAllSeekers(queryClient, ((oldData) => {
        const oldDataPages = oldData?.pages || [];
        if (oldDataPages.length) {
          return {
            pages: oldDataPages.map((page) => page.map((item) => {
              if (item.id === data.seekerId) {
                return { ...item, evangelist: data.dmName, evangelistId: data.destEvangelistId };
              }
              return item;
            })),
            pageParams: oldData?.pageParams || []
          };
        }

        return oldData;
      }), undefined);
      updateSeekerListByIds(queryClient, {
        isUiRoleLeader: isUiRoleNotDM,
        seekerListStatus: 'active',
        ids: [data.seekerId],
        newProperties: {
          evangelistId: data.destEvangelistId,
        },
      });

      showToast({ title: t('changesSuccess') });
    }
  });
};

export const updateAllSeekerListItem: UpdateQueryDataFnWithStatus<{
  filter: ItemFilter<AdminSeekerItem>
  newProperties?: Partial<AdminSeekerItem>,
  messagesCountsFactors?: Partial<MessageFactors>,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';
  const { filter, newProperties, messagesCountsFactors } = options;

  setQueryDataAllSeekers(queryClient, ((oldData) => {
    const oldDataPages = oldData?.pages || [];
    if (oldDataPages.length) {
      return {
        pages: oldDataPages.map((page) => page.map((item) => {
          if (typeof filter === 'string' ? item.id === filter : filter(item)) {
            return createChatItemUpdated(item, newProperties || {}, messagesCountsFactors);
          }
          return item;
        })),
        pageParams: oldData?.pageParams || []
      };
    }

    status = 'fail/no-data';

    return oldData;
  }), undefined);

  return {
    status
  };
};

export const useDeleteSeeker = () => {
  const queryClient = useQueryClient();
  const { userData } = useMainContext();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();

  return useMutation((seekerId: string) => (
    deleteSeekerRequest(seekerId, userData)
  ), {
    onSuccess: (response, data) => {
      setQueryDataAllSeekers(queryClient, ((oldData) => {
        const oldDataPages = oldData?.pages || [];
        if (oldDataPages.length) {
          return {
            pages: oldDataPages.map((page) => page.filter((item) => item.id !== data)),
            pageParams: oldData?.pageParams || []
          };
        }

        return oldData;
      }), undefined);

      showToast({ title: t('removeInterestSuccess') });
    }
  });
};
