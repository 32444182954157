import React  from 'react';
import classnames from 'classnames';

import TabButton from './TabButton';
import {ColorScheme} from '../../types';

import './TabsHead.scss';
import { Tooltip } from '../tooltip/Tooltip';

type TabsHeadProps = {
  color?: ColorScheme,
  bgColor?: ColorScheme,
  items: (string | React.ReactNode)[],
  current: number,
  onClickTab: (index: number) => void,
}

const TabsHead: React.FC<React.HTMLProps<HTMLDivElement> & TabsHeadProps> = (props) => {
  return (
    <div className={classnames('tabs-head', props.className)}>
      {
        props.items.map((item, index) => {
          return (

            <Tooltip
              key={index}
              className={'tooltip-wrap'}
              content={typeof (item) === 'string' ? item : 'Tab item'}
            >
              <TabButton
                type='button' // Prevent form submit when the component is in the <form /> tag.
                color={props.color}
                onClick={() => {
                  props.onClickTab(index);
                }}
                active={props.current === index}
              >{item}
              </TabButton>
            </Tooltip>
          );
        })
      }
    </div>
  );
};

export default TabsHead;
