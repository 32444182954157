import { ChannelListItem, ChannelPlatform } from '../api/channels.types';
import React from 'react';
import {
  EmailIcon,
  FacebookIcon,
  ShareIconProps,
  SignalIcon,
  SMSIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon
} from '../assets/ShareIcons';

const channelPlatformsVerified: ChannelPlatform[] = ['sms', 'email'];

export const getIsChannelVerified = (channel: ChannelListItem) => {
  return channelPlatformsVerified.includes(channel.platform);
};

export const ChannelPlatformToIconMap: Record<ChannelPlatform, React.FC<ShareIconProps>> = {
  fb: FacebookIcon,
  sms: SMSIcon,
  signal: SignalIcon,
  tel: TelegramIcon,
  wat: WhatsappIcon,
  vib: ViberIcon,
  email: EmailIcon
};
