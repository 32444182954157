import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

type AudioPlayerValue = {
  isPlaying: boolean,
  src: string,
  setSrc: (src: string) => void,
  play: (src?: string, position?: number) => Promise<void>,
  getCurrentTime: () => number,
  getDuration: () => number,
  updateCurrentTime: (time: number) => void,
  pause: () => void,
  audioElementRef: React.MutableRefObject<HTMLAudioElement | null>,
  brokenSources: string[],
}

const AudioPlayerContext = createContext<AudioPlayerValue>({} as any);

const AudioPlayerProvider: React.FC = ({ children }) => {
  const ref = useRef<HTMLAudioElement | null>(null);
  const [src, setSrc] = useState('');
  const [brokenSources, setBrokenSources] = useState<string[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const getCurrentTime = useCallback(() => {
    if (ref.current) {
      return ref.current.currentTime;
    }

    return 0;
  }, []);

  const getDuration = useCallback(() => {
    if (ref.current) {
      return ref.current.duration;
    }

    return 0;
  }, []);

  const updateCurrentTime: AudioPlayerValue['updateCurrentTime'] = useCallback((time) => {
    if (ref.current) {
      ref.current.currentTime = time;
    }
  }, []);

  const pause = useCallback(() => {
    if (ref.current) {
      ref.current.pause();
    }
  }, []);

  const _play = useCallback((position?: number) => {
    if (ref.current) {
      // Mute audio until {play} resolved to prevent listening wrong position or source.
      let mutedTemp = ref.current?.muted;
      ref.current.muted = true;

      return ref.current?.play().then(() => {
        if (position) {
          updateCurrentTime(position);
        }

        if (ref.current) {
          ref.current.muted = mutedTemp;
        }
      });
    }

    return undefined;
  }, [updateCurrentTime]);

  const play: AudioPlayerValue['play'] = useCallback(async (newSrc, position) => {
    return new Promise((resolve => {
      if (ref.current) {
        if (newSrc && newSrc !== src) { // If new src.
          // Pause currently playing track.
          if (isPlaying) {
            pause();
          }

          // Update src.
          setSrc(newSrc);

          const canPlayThroughHandler = () => {
            if (ref.current) {
              _play(position);

              ref.current?.removeEventListener('canplaythrough', canPlayThroughHandler);
            }
          };

          // Add listener to call {play} method.
          ref.current.addEventListener('canplaythrough', canPlayThroughHandler);

          const handleLoadedMetadata = () => {
            resolve();

            ref.current?.removeEventListener('loadedmetadata', handleLoadedMetadata);
          };

          // Add listener to resolve promise when audio duration is available to read.
          ref.current.addEventListener('loadedmetadata', handleLoadedMetadata);
        } else {
          // Just start play.
          _play(position)?.then(() => {
            resolve();
          });
        }
      }
    }));
  }, [_play, pause, isPlaying, src]);

  return (
    <AudioPlayerContext.Provider value={{
      isPlaying,
      src,
      setSrc,
      play,
      pause,
      getCurrentTime,
      updateCurrentTime,
      getDuration,
      audioElementRef: ref,
      brokenSources,
    }}>
      {children}
      <audio
        ref={ref}
        src={src}
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        onEnded={() => {
          setIsPlaying(false);
        }}
        onError={() => {
          setIsPlaying(false);

          if (src) {
            setBrokenSources((oldBrokenSources) => [
              ...oldBrokenSources,
              src,
            ]);
          }
        }}
      />
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayerContext = () => useContext(AudioPlayerContext);

export default AudioPlayerProvider;
