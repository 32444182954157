import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars-2';

import AeFontIcons from '../../../assets/Icons';
import { Button, ListItemRemove } from '../../../components';
import { OneOfUserLocations } from '../../../api/account';
import { useModalContext } from '../../../components/modal/ModalProvider';
import ModalUserLocations from './ModalUserLocations';
import { useGetCountries, useGetLocations, useModifyLocations } from '../../../query/locations';

const ViewLocations = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalContext();
  const { userLocations, isUserLocationsLoading } = useGetLocations();
  const { addLocationsMutation, deleteLocationsMutation } = useModifyLocations();

  const { countries, isCountriesLoading } = useGetCountries();

  const abbreviationToCountryMap = useMemo(() => countries?.reduce(
    (acc, { abbreviation, country }) => {
      acc[abbreviation] = country;
      return acc;
    }, {} as {
      [field: string]: string,
    }), [countries]);

  const handleClickAddLocation = () => {
    openModal((
      <ModalUserLocations
        countries={countries || []}
        isCountriesLoading={isCountriesLoading}
        citiesAdded={userLocations?.cities.map(({city}) => city) || []}
        onSubmit={async ({values}) => {
          const { cities, postalCodes } = values;

          if (cities.length) {
            const country = values.country.key;

            addLocationsMutation.mutate({
              cities: cities.map((item) => ({ country, city: item.label }))
            } as OneOfUserLocations);
          }

          if (postalCodes.length) {
            addLocationsMutation.mutate({
              postalCodes: postalCodes.map((postalCode) => ({ postalCode }))
            } as OneOfUserLocations);
          }

          closeModal();
        }}
        onCancel={closeModal}
      />
    ), {
      title: t('addLocation'),
      widthSize: 'md'
    });
  };

  const renderLocation = (location: string, onClickRemove: () => void) => {
    return (
      <ListItemRemove
        key={location}
        onClickRemove={onClickRemove}
      >{location}</ListItemRemove>
    );
  };

  return (
    <>
      <Scrollbars
        autoHeight
        autoHeightMax={260}
        renderView={(props) => <ul {...props} />}
      >
        {
          userLocations?.countries.map(({ country }) => {
            return renderLocation(country, () => {
              deleteLocationsMutation.mutate(
                { countries: [{ country }] } as OneOfUserLocations
              );
            });
          })
        }
        {
          userLocations?.cities.map(({ city, country }) => {
            let location = city;

            if (abbreviationToCountryMap?.[country]) {
              location += ', ' + abbreviationToCountryMap[country];
            }

            return renderLocation(location, () => {
              deleteLocationsMutation.mutate(
                { cities: [{ country, city }] } as OneOfUserLocations
              );
            });
          })
        }
        {
          userLocations?.postalCodes.map(({ postalCode }) => {
            return renderLocation(postalCode, () => {
              deleteLocationsMutation.mutate(
                { postalCodes: [{ postalCode }] } as OneOfUserLocations
              );
            });
          })
        }
      </Scrollbars>

      <Button
        disabled={isUserLocationsLoading}
        variant='outline'
        color='blue'
        onClick={handleClickAddLocation}
        iconBefore={AeFontIcons.plus}
      >
        {t('addLocation')}
      </Button>
    </>
  );
};

export default ViewLocations;
