import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAudioPlayerContext } from '../components/audioplayer/AudioPlayerProvider';

export const useAudioPlayer = (trackSrc: string) => {
  const {
    src,
    isPlaying,
    play,
    pause,
    getCurrentTime,
    updateCurrentTime,
    getDuration,
    audioElementRef,
    brokenSources,
  } = useAudioPlayerContext();
  const thisAudioPLayerInPlayer = trackSrc === src;
  const [isFetching, setIsFetching] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const isError = useMemo(() => {
    return brokenSources.includes(trackSrc);
  }, [trackSrc, brokenSources]);

  const getDurationParsed = useCallback(() => {
    return getDuration() * 1000;
  }, [getDuration]);

  const getCurrentTimeParsed = useCallback(() => {
    return getCurrentTime() * 1000;
  }, [getCurrentTime]);

  const playEnhanced = useCallback(() => {
    setIsFetching(true);

    play(trackSrc, time / 1000)
      .finally(() => {
        setIsFetching(false);
      });
  }, [play, trackSrc, time]);

  const updateCurrentTimeEnhanced = useCallback((value: number) => {
    if (thisAudioPLayerInPlayer) {
      updateCurrentTime(value / 1000);
    }

    setTime(value);
  }, [thisAudioPLayerInPlayer, updateCurrentTime]);

  useEffect(() => {
    let timerId: any;

    if (isPlaying && thisAudioPLayerInPlayer) {
      timerId = setTimeout(() => {
        const currentTimeNew = getCurrentTimeParsed();
        let currentTimeFinal;

        // Prevent the same time set because it invokes current time to stagnation.
        if (time === currentTimeNew) {
          currentTimeFinal = currentTimeNew + 0.01;
        } else {
          currentTimeFinal = currentTimeNew;
        }

        setTime(currentTimeFinal);
      },
      // If the audio is shorter than 3s, calculate delay for 3 time updates.
      (duration && duration < 3000)
        ? duration / 3
        : 1000
      );
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [
    duration,
    thisAudioPLayerInPlayer,
    getCurrentTimeParsed,
    isPlaying,
    time
  ]);

  useEffect(() => {
    const audioElement = audioElementRef.current;

    // Update duration on pause/end to have an actual audio current time.
    const handleStopPlaying = () => {
      let position;

      const currentTime = getCurrentTimeParsed();
      const durationRounded = Number(duration.toFixed().slice(0, -2));
      const currentTimeRounded = Number(currentTime.toFixed().slice(0, -2));

      // Reset position if the difference of {currentTime} and {duration} is less than 100ms.
      if (durationRounded - currentTimeRounded < 1) {
        position = 0;
      } else {
        position = currentTime;
      }

      setTime(position);
      updateCurrentTime(position / 1000);
    };

    // If troubles with {duration} detecting, it can be passed beforehand.
    // Messages and recorded data have {duration}.
    // It is more appropriate to use duration from the meta as it is real one, but not calculated.
    const checkForActualDuration = () => {
      const newDuration = getDurationParsed();

      if (
        newDuration
        && newDuration !== Infinity
        && !isNaN(newDuration)
        && duration !== newDuration
      ) {
        setDuration(newDuration);

        if (audioElement) {
          audioElement.removeEventListener('timeupdate', checkForActualDuration);
        }
      }
    };

    if (audioElement && thisAudioPLayerInPlayer) {
      audioElement.addEventListener('pause', handleStopPlaying);
      audioElement.addEventListener('ended', handleStopPlaying);

      audioElement.addEventListener('loadeddata', checkForActualDuration);
      audioElement.addEventListener('loadedmetadata', checkForActualDuration);

      audioElement.addEventListener('timeupdate', checkForActualDuration);
    }

    return () => {
      if (audioElement && thisAudioPLayerInPlayer) {
        audioElement.removeEventListener('pause', handleStopPlaying);
        audioElement.removeEventListener('ended', handleStopPlaying);

        audioElement.removeEventListener('loadeddata', checkForActualDuration);
        audioElement.removeEventListener('loadedmetadata', checkForActualDuration);

        audioElement.removeEventListener('timeupdate', checkForActualDuration);
      }
    };
  }, [
    audioElementRef,
    duration,
    thisAudioPLayerInPlayer,
    getCurrentTimeParsed,
    getDurationParsed,
    updateCurrentTime,
  ]);

  return {
    isFetching,
    isError,
    isPlaying: thisAudioPLayerInPlayer ? isPlaying : false,
    currentTime: time,
    duration,
    play: playEnhanced,
    pause,
    updateCurrentTime: updateCurrentTimeEnhanced,
  };
};
