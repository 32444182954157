import React from 'react';
import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';
import Button from '../button/Button';
import Row from '../row/Row';

import styles from './GradeStarsView.module.scss';

type GradeStarsViewProps = {
  isSmall?: boolean
  disabled?: boolean
  score: number,
  onSetScore: (score: number) => void,
}

const GradeStarsView: React.FC<GradeStarsViewProps> = ({
  disabled,
  isSmall,
  score,
  onSetScore
}) => {

  return (
    <Row
      directionHorizontal
      gap={isSmall ? 'tiny' : 'extra-lg'}
      autoColumns
      className={styles['root']}
      horizontal='center'>
      {
        new Array(5).fill('').map((_, index) => {
          return (
            <Button
              key={index}
              disabled={disabled}
              size={isSmall ? 'extra-sm' : 'biggest'}
              type='button'
              variant='transparent'
              color={index + 1 <= score ? 'green' : 'gray-light'}
              onClick={() => {
                onSetScore(index + 1);
              }}>
              <FontIconView icon={AeFontIcons.starFilled} />
            </Button>
          );
        })
      }
    </Row>
  );
};

export default GradeStarsView;
