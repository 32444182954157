import parseISO from 'date-fns/parseISO';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';

import { SeekerCommonType } from '../api/seekers.types';
import {ChatItemCampaignType, ListCampaignsItemConcise} from '../api/campaigns.types';
import {Prayer} from '../api/prayer';
import { EvangelistItemType, AdminEvangelistItem } from '../api/evangelists.types';
import { GetByIdWithCacheFn } from '../hooks/useCache';
import { RequestItemType } from '../api/request.types';
import { BroadcastItemType } from '../api/broadcasts.types';
import { ChatItemLeaderCMType } from '../api/chat.types';
import { StudentItem } from '../api/students.types';
import { SelectOption } from '../components/select/Select.types';

export type SortByDateValues = '' | 'newestFirst' | 'oldestFirst';
export type SortByEvangelistValues = '' | 'filterResponseTime' | 'filterChatScore'
  | 'filterDmScore' | 'filterSeekers' | 'filterLeader';
export type SortByStudentValues = '' | 'oldestContact' | 'recentContact' | 'oldestChat' 
  | 'recentChat' | 'highestLesson' | 'lowestLesson'

export type SortsValues = {
  date: SortByDateValues,
  evangelist: SortByEvangelistValues,
  student: SortByStudentValues
};

export type AllSortsFormData = {
  date: SelectOption<SortByDateValues>;
  evangelist: SelectOption<SortByEvangelistValues>;
  student: SelectOption<SortByStudentValues>;
};

export const allSortsDefault: AllSortsFormData = {
  date: {key: '', label: ''},
  evangelist: {key: '', label: ''},
  student: {key: '', label: ''}
};

type GetDateFn<T> = (item: T) => string;
type GetNameFn<T> = (item: T) => string;

type MakeSortedFn<T, SortBy = never> =
  (items: T[], sortBy?: SortBy, ...additionalArgs: any) => T[];

export const getSeekerItemDate: GetDateFn<SeekerCommonType> = (item) => {
  return item.date || '';
};

export const getRequestItemDate: GetDateFn<RequestItemType> = (item) => {
  return item.request.date || '';
};

export const getPrayerItemDate: GetDateFn<Prayer> = (item) => {
  return item.lastPrayed || item.creationDate || '';
};

export const getLeaderItemDate: GetDateFn<ChatItemLeaderCMType> = (item) => {
  return item?.date;
};

export const getCampaignItemDate: GetDateFn<ChatItemCampaignType> = (item) => {
  return item?.date;
};

export const getBroadcastItemDate: GetDateFn<BroadcastItemType> = (item) => {
  return item?.date;
};

export const getEvangelistItemDate: GetDateFn<EvangelistItemType | AdminEvangelistItem> = (item) => {
  return item?.date;
};

export const getApiCampaignItemStartDate: GetDateFn<ListCampaignsItemConcise> = (item) => {
  return item?.startDate;
};

export const sortByDate = <T>(list: T[], asc: boolean, getDateFn: GetDateFn<T>) => {
  list.sort((prev, next) => {
    const prevDate = getDateFn(prev);
    const nextDate = getDateFn(next);
    const prevDateISOParsed = prevDate ? parseISO(prevDate) : 0;
    const nextDateISOParsed = nextDate ? parseISO(nextDate) : 0;

    if (isEqual(prevDateISOParsed, nextDateISOParsed)) {
      return 0;
    }

    if (asc) {
      return isAfter(prevDateISOParsed, nextDateISOParsed) ? -1 : 1;
    }

    return isAfter(prevDateISOParsed, nextDateISOParsed) ? 1 : -1;
  });
};

const getLeaderItemName: GetNameFn<ChatItemLeaderCMType | AdminEvangelistItem> = (item) => {
  return item.firstname || '';
};

const getCampaignItemName: GetNameFn<ChatItemCampaignType> = (item) => {
  return item.name || '';
};

const getBroadcastItemName: GetNameFn<BroadcastItemType> = (item) => {
  return item.name || '';
};

export const getEvangelistItemName: GetNameFn<EvangelistItemType> = (item) => {
  return item.firstname;
};

export const sortAlphabetically = <T = any>(list: T[], asc: boolean, getNameFn: GetNameFn<T>) => {
  return list.sort((prev, next) => {
    const res = getNameFn(prev).localeCompare(getNameFn(next));

    if (res === 0) {
      return 0;
    }

    if (asc) {
      return res === 1 ? 1 : -1;
    }

    return res === 1 ? -1 : 1;
  });
};

const makeChatsSortedDefault = <T>(
  items: T[],
  getIsHasUnreadMessages: (item: T) => boolean,
  getIsHasMessages: (item: T) => boolean,
  getItemDate: GetDateFn<T>,
  getItemName: GetNameFn<T>,
  asc = true,
) => {
  const unreadItemsList: T[] = [];
  const hasMessagesItemsList: T[] = [];
  const restItemsList: T[] = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (getIsHasUnreadMessages(item)) {
      unreadItemsList.push(item);
    } else if (getIsHasMessages(item)) {
      hasMessagesItemsList.push(item);
    } else {
      restItemsList.push(item);
    }
  }

  sortByDate(unreadItemsList, asc, getItemDate);
  sortByDate(hasMessagesItemsList, asc, getItemDate);
  sortAlphabetically(restItemsList, true, getItemName);

  if (asc) {
    return [
      ...unreadItemsList,
      ...hasMessagesItemsList,
      ...restItemsList,
    ];
  }

  return [
    ...restItemsList,
    ...hasMessagesItemsList,
    ...unreadItemsList,
  ];
};

export const makeSeekerChatsSorted: MakeSortedFn<SeekerCommonType, SortByDateValues> = (
  items,
  sortBy,
  // FIXME: Very crunched. Need to discuss another approach!
  // Is used to prevent chat item go to bottom after it became "read" from "unread" after opening.
  fakeBadgeCounts: Record<string, number | undefined>,
  // Leader uses 'ungraded' messages count instead of 'unread'.
  isUiRoleNotDM: boolean,
) => {
  if (sortBy === 'newestFirst' || sortBy === 'oldestFirst') {
    const itemsSpread = [...items];
    sortByDate(itemsSpread, sortBy === 'newestFirst', getSeekerItemDate);
    return itemsSpread;
  }

  const messagesCountKey = isUiRoleNotDM ? 'ungraded' : 'unread';

  return makeChatsSortedDefault(
    items,
    (item) => !!fakeBadgeCounts[item.id] || !!item[messagesCountKey],
    (item) => !!getSeekerItemDate(item),
    getSeekerItemDate,
    (item) => item.firstname || '',
  );
};

export const makeBroadcastChatsSorted: MakeSortedFn<BroadcastItemType> = (
  items,
) => {
  return makeChatsSortedDefault(
    items,
    () => false, // as broadcast chats don't have incoming messages.
    (item) => !!getBroadcastItemDate(item),
    getBroadcastItemDate,
    getBroadcastItemName,
  );
};

export const makeCampaignChatsSorted: MakeSortedFn<ChatItemCampaignType, SortByDateValues> = (
  items,
  sortsBy,
) => {
  if (sortsBy === 'newestFirst') {
    const itemsSpread = [...items];
    sortByDate(itemsSpread, true, getCampaignItemDate);
    return itemsSpread;
  }

  if (sortsBy === 'oldestFirst') {
    const itemsSpread = [...items];
    sortByDate(itemsSpread, false, getCampaignItemDate);
    return itemsSpread;
  }

  return makeChatsSortedDefault(
    items,
    (item) => item.unread !== 0,
    (item) => !!getCampaignItemDate(item),
    getCampaignItemDate,
    getCampaignItemName,
  );
};

export const makeRequestsSorted: MakeSortedFn<RequestItemType, SortByDateValues> = (
  items,
  sortsBy,
) => {
  const itemsSpread = [...items];

  if (sortsBy === 'oldestFirst') {
    sortByDate(itemsSpread, false, getRequestItemDate);
    return itemsSpread;
  }

  sortByDate(itemsSpread, true, getRequestItemDate);
  return itemsSpread;
};

export const makePrayersSorted: MakeSortedFn<Prayer, SortByDateValues> = (
  items,
  sortBy,
) => {
  const prayedOrder = !sortBy || sortBy === 'oldestFirst';
  const nonPrayedOrder = sortBy === 'oldestFirst';

  const nonPrayedList = [];
  const prayedList = [];

  for (let i = 0; i < items.length; i++) {
    const prayer = items[i];

    if (prayer.lastPrayed) {
      prayedList.push(prayer);
    } else {
      nonPrayedList.push(prayer);
    }
  }

  sortByDate(nonPrayedList, !nonPrayedOrder, getPrayerItemDate);
  sortByDate(prayedList, !prayedOrder, getPrayerItemDate);

  return [
    ...(prayedOrder ? nonPrayedList : prayedList),
    ...(prayedOrder ? prayedList : nonPrayedList),
  ];
};

export const makeLeaderChatsSorted: MakeSortedFn<ChatItemLeaderCMType> = (
  items,
) => {
  return makeChatsSortedDefault(
    items,
    (item) => item.unread !== 0,
    (item) => !!getLeaderItemDate(item),
    getLeaderItemDate,
    getLeaderItemName,
  );
};

export const makeEvangelistChatsSorted: MakeSortedFn<
  EvangelistItemType, SortByEvangelistValues
> = (
  items,
  sortBy,
  getLeaderEvangelistById: GetByIdWithCacheFn<EvangelistItemType>,
) => {
  if (sortBy === 'filterSeekers') {
    const itemsSpread = [...items];
    itemsSpread.sort((a, b) => {
      return Number(getLeaderEvangelistById(a.id.toString())?.totalSeekers)
        - Number(getLeaderEvangelistById(b.id.toString())?.totalSeekers);
    });
    return itemsSpread;
  }

  if (sortBy === 'filterResponseTime') {
    const itemsSpread = [...items];
    itemsSpread.sort((a, b) => {
      return Number(getLeaderEvangelistById(b.id.toString())?.responseTime)
        - Number(getLeaderEvangelistById(a.id.toString())?.responseTime);
    });
    return itemsSpread;
  }

  if (sortBy === 'filterChatScore') {
    const itemsSpread = [...items];
    itemsSpread.sort((a, b) => {
      return Number(getLeaderEvangelistById(a.id.toString())?.msgScore)
        - Number(getLeaderEvangelistById(b.id.toString())?.msgScore);
    });
    return itemsSpread;
  }

  if (sortBy === 'filterDmScore') {
    const itemsSpread = [...items];
    itemsSpread.sort((a, b) => {
      return Number(getLeaderEvangelistById(a.id.toString())?.score)
        - Number(getLeaderEvangelistById(b.id.toString())?.score);
    });
    return itemsSpread;
  }

  if (sortBy === 'filterLeader') {
    return makeChatsSortedDefault(
      items,
      () => false,
      (item) => !!item.lastLeaderContact,
      (item) => item.lastLeaderContact || '',
      getLeaderItemName,
      false,
    );
  }

  return makeChatsSortedDefault(
    items,
    (item) => item.unread !== 0,
    (item) => !!getEvangelistItemDate(item),
    getEvangelistItemDate,
    getLeaderItemName,
  );
};

export const makeAdminEvangelistChatsSorted: MakeSortedFn<AdminEvangelistItem> = (
  items, _sortBy, fakeBadgeCounts: Record<string, number | undefined> | null,
): AdminEvangelistItem[] => {
  return makeChatsSortedDefault(
    items,
    (item) => !!fakeBadgeCounts?.[item.id] || !!item.unread,
    (item) => !!getEvangelistItemDate(item),
    getEvangelistItemDate,
    getLeaderItemName,
  );
};

export const makeApiCampaignsSortedByStartDate: MakeSortedFn<ListCampaignsItemConcise> = (
  items
) => {
  sortByDate(items, false, getApiCampaignItemStartDate);
  return items.filter((item) => item?.startDate !== null);  
};

export const makeStudentsSorted: MakeSortedFn<StudentItem, SortByStudentValues> = (
  items,
  sortBy
) => {
  if (sortBy === 'oldestContact') {
    sortByDate(items, false, (item) => item.createDate);
  } else if (sortBy === 'recentContact') {
    sortByDate(items, true, (item) => item.createDate);
  } else if (sortBy === 'oldestChat') {
    sortByDate(items, false, (item) => item?.lastContact || '');
  } else if (sortBy === 'recentChat') {
    sortByDate(items, true, (item) => item?.lastContact || '');
  } else if (sortBy === 'highestLesson') {
    items.sort((prev, next) => {
      return next.lessonsSent - prev.lessonsSent;
    });
  } else if (sortBy === 'lowestLesson') {
    items.sort((prev, next) => {
      return prev.lessonsSent - next.lessonsSent;
    });
  } else {
    sortByDate(items, false, (item) => item.lessonDate);
  }
  return items;
};
