export type GetRequestCategoriesCategory = {
  id: string,
  name: string,
  waiting: string
}

export type RequestCategoryItemType = {
  id: string,
  name: string,
  waiting: string
}

export enum RequestCategoriesType {
  none ,
  prayer = 1,
  question,
  study,
}

export type RequestDenyReason = 'unqualified' | 'inappropriate' | 'too busy' | 'other';

export type RequestStatus = 'open' | 'closed' | 'waiting';

export type RequestStatusStatus = 'assigned';

export type RequestSource = 'tel';

export type RequestItemType = {
  request: {
    campaignId: string | null
    categoryId: string
    date: string
    error: null
    evangelistId: string
    id: string
    message: string
    messageId: string
    rowIndex: number
    seekerId: string
    source: RequestSource
    sourceId: string
    status: RequestStatus
  }
  status: {
    assignDate: string
    closeDate: null
    firstResponseDate: null
    id: string
    numFollowups: string
    requestId: string
    status: RequestStatusStatus
  }
}

export type GetRequestsRequestStatus = 'active' | 'inactive';
