import React, {
  ChangeEvent, CSSProperties, forwardRef, ReactNode, useCallback,
  useEffect,
  useRef
} from 'react';
import Scroll from '../scroll/Scroll';

import { EventKey } from '../../utils/dom';
import InputWrapper, { InputWrapperProps } from '../input/InputWrapper';
import { Button } from '../index';
import AeFontIcons from '../../assets/Icons';

import './TextArea.scss';

type TextAreaHeight = 'extra-sm' | 'sm' | 'md' | 'lg' | 'extra-lg';

const TextAreaHeightToPxMap: Record<TextAreaHeight, number> = {
  'extra-sm': 128,
  'sm': 167,
  'md': 248,
  'lg': 304,
  'extra-lg': 343,
};

export type TextAreaProps = {
  placeholder?: string,
  defaultValue?: string,
  value?: string,
  name?: string,
  style?: CSSProperties,
  invalid?: boolean,
  clearButton?: boolean,
  slotBefore?: ReactNode,
  textareaHeight?: TextAreaHeight,
  readOnly?: boolean,

  disabled?: boolean, // Universal property for textarea and wrapper.

  onChange?: (e: ChangeEvent<HTMLTextAreaElement>, value: string) => void,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
  onPaste?: (e: any) => void;

  submitOnEnter?: boolean,
  onSubmit?: () => void,

  wrapperProps?: InputWrapperProps,
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const dispatchChangeEvent = (newValue: string) => {
    if (textareaRef.current) {
      const nativeInputValueSetter =
        Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value')?.set;

      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(textareaRef.current, newValue);

        textareaRef.current.dispatchEvent(
          new Event('input', { bubbles: true})
        );
      }
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (props.submitOnEnter) {
      const isEnterKeyWasPressed = event.key === EventKey.enter;
      const isNewLineCombination = isEnterKeyWasPressed && (
        event.shiftKey || event.altKey || event.ctrlKey
      );

      if (isNewLineCombination) {
        event.preventDefault();

        dispatchChangeEvent(props.value + '\n');
      } else if (isEnterKeyWasPressed) {
        event.preventDefault();

        if (props.onSubmit) {
          props.onSubmit();
        }
      }
    }
  };

  // @see Auto grow/shrink textarea on value change https://stackoverflow.com/a/36958094
  useEffect(() => {
    const rootElement = textareaRef.current;

    if (rootElement) {
      const elementHeight = parseFloat(
        getComputedStyle(rootElement, null).height.replace('px', '')
      );
      const borderHeight = rootElement.clientHeight - elementHeight;

      if (rootElement.clientHeight < rootElement.scrollHeight) {
        rootElement.style.height = rootElement.scrollHeight - borderHeight + 'px';
      } else {
        rootElement.style.height = 1 + 'px';
        rootElement.style.height = rootElement.scrollHeight - borderHeight + 'px';
      }
    }
  }, [props.value]);

  return (
    <InputWrapper
      {...props.wrapperProps}
      invalid={props.invalid}
      disabled={props.disabled}
      style={{
        flexDirection: 'row',
        height: props.textareaHeight ? TextAreaHeightToPxMap[props.textareaHeight] : undefined,
        ...props.wrapperProps?.style,
      }}
    >
      {props.slotBefore}
      <Scroll staticScrollView flexColumn >
        <textarea
          ref={(el) => {
            if (el) {
              if (typeof ref === 'function') {
                ref(el);
              } else if (ref?.current) {
                ref.current = el;
              }
            }

            textareaRef.current = el;
          }}
          defaultValue={props.defaultValue}
          className='textarea'
          style={props.style}
          disabled={props.disabled || props.wrapperProps?.disabled}
          name={props.name}
          value={props.value}
          readOnly={props.readOnly}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e, e.currentTarget.value);
            }
          }}
          onPaste={props.onPaste}
        />
      </Scroll>
      {
        props.clearButton && props.value && (
          <Button
            onClick={() => {
              dispatchChangeEvent('');
            }}
            size='sm'
            color='gray-light'
            variant='transparent'
            iconBefore={AeFontIcons.close}
          />
        )
      }
    </InputWrapper>
  );
});

export default TextArea;
