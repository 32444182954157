import React from 'react';
import classnames from 'classnames';

import { ColorScheme } from '../../types';
import { COMPONENT_COLOR_DEFAULT } from '../../utils/constants';

import './ButtonBookmark.scss';

export interface ButtonBookmarkProps {
  className?: string;
  color?: ColorScheme;
  onClick: () => void;
}

const ButtonBookmark: React.FC<ButtonBookmarkProps> = ({ className, color, ...restProps }) => {
  return (
    <button className={classnames('btn-bookmark ', className, color)} {...restProps} />
  );
};

ButtonBookmark.defaultProps = {
  color: COMPONENT_COLOR_DEFAULT,
};

export default ButtonBookmark;
