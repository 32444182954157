import { CampaignMember } from '../api/account';
import { useMemo } from 'react';
import { Role } from '../utils/enums';

export const useParseCampaignMembersByRole = (campaignMembers: CampaignMember[]) => {
  const [membersEvangelists, membersLeaders, memberManager] = useMemo(() => {
    const membersEvangelists = [];
    const membersLeaders = [];
    let memberManager: CampaignMember | undefined = undefined;

    for (let i = 0; i < campaignMembers.length; i++) {
      const member = campaignMembers[i];
      const nRole = +member.role;

      const memberLeader = { ...member, role: Role.LEADER };
      const memberDM = { ...member, role: Role.DM };
      const memberCM = { ...member, role: Role.CM };

      switch (nRole) {
        case Role.LEADER:
          membersLeaders.push(memberLeader);
          break;
        case Role.DM_LEADER:
          membersLeaders.push(memberLeader);
          membersEvangelists.push(memberDM);
          break;
        case Role.CM:
          memberManager = memberCM;
          // doubtful logic
          // for now - add CM to leader list for showing a '*', maybe will be removed later
          membersLeaders.push(memberCM);
          break;
        case Role.CM_DM:
          memberManager = memberCM;
          membersLeaders.push(memberCM);
          membersEvangelists.push(memberDM);
          break;
        case Role.CM_LEADER:
          memberManager = memberCM;
          membersLeaders.push(memberCM);
          break;
        case Role.CM_DM_LEADER:
          memberManager = memberCM;
          membersLeaders.push(memberCM);
          membersEvangelists.push(memberDM);
          break;
        default:
          membersEvangelists.push(memberDM);
      }
    }

    return [membersEvangelists, membersLeaders, memberManager];
  }, [campaignMembers]);

  return {
    membersEvangelists,
    membersLeaders,
    memberManager,
  };
};
