import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FontIconView, Row, Typography } from '../../components';
import { FontIconViewIcon } from '../../assets/FontIconView';

import './TemplateMailSent.scss';

type TemplateMailSentProps = {
  title: string,
  icon: FontIconViewIcon,
  slotContent: ReactNode,
  slotActions: ReactNode,
  onResendEmail: () => void | Promise<void>,
  size: 'sm' | 'lg',
}

const TemplateMailSent: React.FC<TemplateMailSentProps> = (
  { icon, title, slotContent, slotActions, onResendEmail, size }
) => {
  const { t } = useTranslation();
  const [isResendFetching, setIsResendFetching] = useState(false);

  return (
    <Row gap='huge'>
      <Row gap={size === 'sm' ? 'tiny' : 'extra-lg'} className='screen-mail-sent'>
        <FontIconView
          icon={icon}
          style={{ height: size === 'sm' ? '6rem' : '' }}
          className='screen-mail-sent__image'
        />

        <Row gap={size === 'sm' ? 'tiny' : 'lg'}>
          <Typography
            component={size === 'sm' ? 'h3' : 'h2'}
            variant='poppins'
            color='text-title-light'
          >{title}</Typography>

          <Typography
            component={size === 'sm' ? 'body2' : 'body1'}
            color='text-title-light'
            tagName='span'
          >
            <Row gap={size === 'sm' ? 'tiny' : 'extra-lg'}>
              {slotContent}
            </Row>
          </Typography>

          <Typography component={size === 'sm' ? 'body2' : 'body1'} color='text-title-light'>
            {t('emailNotCome')}&nbsp;
            <Button
              disabled={isResendFetching}
              onClick={async () => {
                setIsResendFetching(true);
                await onResendEmail();
                setIsResendFetching(false);
              }}
              variant='link'
              color='blue'
            >{t('resendEmail')}</Button>
          </Typography>
        </Row>
      </Row>

      {slotActions}
    </Row>
  );
};

export default TemplateMailSent;
