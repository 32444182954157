import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useTranslation} from 'react-i18next';

import {useMainContext} from '../MainProvider';
import {useGetByIdWithCache} from '../hooks/useCache';
import {
  getBroadcastListsRequest,
  modifyBroadcastList,
  ModifyBroadcastListAction
} from '../api/broadcasts';
import {useToastEnhanced} from '../enhanced-components/toaster/ToasterEnhanced';
import { PromiseResolveValue, SetQueryDataFnTyped, UpdateQueryDataFn } from '../types';
import { BroadcastItemType } from '../api/broadcasts.types';

export const QUERY_KEY_BROADCAST_LISTS = 'broadcastLists';

const broadcastListsInitialData: [] = [];

const setQueryDataBroadcastLists: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getBroadcastListsRequest>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_BROADCAST_LISTS, cb);
};

export const updateBroadcastLists: UpdateQueryDataFn<{
  broadcastId: string,
  newProperties?: Partial<BroadcastItemType>,
  updateBroadcastItemSeekers?:
    (oldSeekers: BroadcastItemType['seekers']) => BroadcastItemType['seekers'],
}> = (queryClient, options) => {
  setQueryDataBroadcastLists(queryClient, (oldData) => {
    if (oldData?.length) {
      const {broadcastId, newProperties} = options;
      const oldDataSpread = [...oldData];

      let item;
      for (let i = 0; i < oldDataSpread.length; i++) {
        if (broadcastId === oldDataSpread[i].broadcastId) {
          item = oldDataSpread[i];

          oldDataSpread[i] = {
            ...item,
            ...newProperties,
            date: (newProperties || item)?.date || '',
            status: (newProperties || item)?.status || 'sent',
            text: (newProperties || item)?.text || '',
            seekers: options.updateBroadcastItemSeekers?.(item.seekers) || item.seekers,
          };

          break;
        }
      }

      return oldDataSpread;
    }

    return oldData;
  }, undefined);
};

export const useModifyBroadcastList = () => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const queryClient = useQueryClient();
  const { userData } = useMainContext();

  const modifyMutation = useMutation((params: {
    actions: ModifyBroadcastListAction[], broadcastId?: string
  }) => modifyBroadcastList(userData, params), {
    onSuccess: (response, mutationPayload) => {
      const {broadcastId} = mutationPayload;

      let action;
      for (let i = 0; i < mutationPayload.actions.length; i++) {
        action = mutationPayload.actions[i];

        if (action.action === 'rename' && broadcastId) {
          updateBroadcastLists(queryClient, {
            broadcastId,
            newProperties: {
              name: action.values?.toString(),
            },
          });
        } else {
          // TODO: Invalidating can be replaced with {setQueryData}.
          queryClient.invalidateQueries(QUERY_KEY_BROADCAST_LISTS);
        }
      }

      showToast({
        title: t('success')
      });
    }
  });

  return {
    modifyBroadcastList: modifyMutation
  };
};

export const getBroadcastItemId = (item: BroadcastItemType) => item.broadcastId;

export const useGetBroadcastLists = (options?: {
  staleTime?: number
}) => {
  const { userData } = useMainContext();

  const {
    isError,
    isLoading,
    refetch,
    data = broadcastListsInitialData,
  } = useQuery(QUERY_KEY_BROADCAST_LISTS, () =>
    getBroadcastListsRequest(userData), {
    staleTime: 0, // Refetch each time hook is called.
    ...options,
  });
  const get = useGetByIdWithCache(QUERY_KEY_BROADCAST_LISTS, data, getBroadcastItemId);

  return {
    isBroadcastListsLoading: isLoading,
    isBroadcastListsError: isError,
    refetchBroadcastLists: refetch,
    broadcastLists: data,
    getBroadcastListById: isLoading ? () => undefined : get,
  };
};
