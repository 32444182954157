import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { URLS } from '../../../utils/constants';

import FormForgotPassword from './FormForgotPassword';
import ViewRestoreMailSent from './ViewRestoreMailSent';
import FontIconView from '../../../assets/FontIconView';
import AeFontIcons from '../../../assets/Icons';
import { Row, Typography } from '../../../components';
import AuthContainer from '../AuthContainer';
import {useUiLanguage} from '../../../hooks/useLanguage';
import { useMainContext } from '../../../MainProvider';
import { useResetPassword } from '../../../hooks/useResetPassword';
import { useGetProfile } from '../../../query/profile';

import './PageRestore.scss';

type Views = 'form' | 'success';

const PageRestore: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const {language} = useUiLanguage();
  const { userData } = useMainContext();
  const { profileData } = useGetProfile(userData.id);
  const [view, setView] = useState<Views>('form');
  const [email, setEmail] = useState(profileData.profile.email || '');
  const { resetPasswordForEmail } = useResetPassword();

  let content;

  if (view === 'form') {
    content = (
      <Row gap='extra-lg'>
        <Row gap='extra-lg' horizontal='center'>
          <FontIconView icon={AeFontIcons.lock} className='page-restore__icon-lock' />

          <Typography component='h2' variant='poppins'>{t('passwordRecovery')}</Typography>

          <div>{t('enterEmailToReset')}</div>
        </Row>

        <FormForgotPassword
          initialValues={{ email }}
          onBack={() => {
            history.goBack();
          }}
          onSubmit={async (data) => {
            if (data.isValid) {
              const { email } = data.values;
              setEmail(email);
              await resetPasswordForEmail(email, language, false)
                .then(() => {
                  setView('success');
                });
            }
          }}
        />
      </Row>
    );
  } else if (view === 'success') {
    content = (
      <ViewRestoreMailSent
        size='lg'
        btnOkText={t('goToSignIn')}
        email={email}
        resendFn={() => {
          setView('form');
        }}
        onClickSuccess={() => {
          history.push(URLS.login);
        }}
      />
    );
  }

  return (
    <AuthContainer>{content}</AuthContainer>
  );
};

export default PageRestore;
