import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '../../../components';
import Row from '../../../components/row/Row';
import { useQuery } from 'react-query';
import { fetchDocument } from '../../../api/account';
import ScrollBar from 'react-custom-scrollbars-2';
import { formatDateForApi } from '../../../utils/time';
import AuthContainer from '../AuthContainer';
import {useUiLanguage} from '../../../hooks/useLanguage';

type FormPastorProps = {
  onAcceptAll: (acceptTimes: AcceptTimes) => Promise<any>,
  onBack: () => void,
};

type AcceptTimes = Record<Agreements, string>;

type Agreements = 'dpa' | 'aup';

const agreements: Agreements[] = ['dpa', 'aup'];

const ViewAgreement: React.FC<FormPastorProps> = ({ onAcceptAll, onBack }) => {
  const { t } = useTranslation();
  const {language} = useUiLanguage();
  const [agreementIndex, setAgreementIndex] = useState(0);
  const acceptTimesRef = useRef<AcceptTimes>({
    dpa: '',
    aup: '',
  });
  const currentAgreement = agreements[agreementIndex];

  const [isAcceptAllFetching, setIsAcceptAllFetching] = useState(false);

  const { data, isLoading } = useQuery(['agreement', currentAgreement], () => {
    return fetchDocument(currentAgreement, language);
  });

  const handleAgree = async () => {
    acceptTimesRef.current[currentAgreement] = formatDateForApi(new Date);

    if (agreementIndex === agreements.length - 1) {
      setIsAcceptAllFetching(true);
      await onAcceptAll(acceptTimesRef.current);
      setIsAcceptAllFetching(false);
    } else {
      setAgreementIndex(agreementIndex + 1);
    }
  };

  const handleBack = () => {
    if (agreementIndex === 0) {
      onBack();
    } else {
      setAgreementIndex(agreementIndex - 1);
    }
  };

  return (
    <Row gap='extra-lg'>
      <Typography component='h2'>{t(currentAgreement)}</Typography>
      <ScrollBar autoHeight autoHeightMax='54vh'>
        <Typography
          component='body1'
          style={{
            whiteSpace: 'break-spaces',
          }}
        >{isLoading ? t('loading') : data?.text}</Typography>
      </ScrollBar>

      <AuthContainer>
        <Row gap='lg' directionHorizontal>
          <Button
            color='gray'
            variant='opacity'
            disabled={isAcceptAllFetching}
            onClick={handleBack}
          >{t('back')}</Button>
          <Button
            disabled={isLoading || isAcceptAllFetching}
            onClick={handleAgree}>{t('agree')}</Button>
        </Row>
      </AuthContainer>
    </Row>
  );
};

export default ViewAgreement;
