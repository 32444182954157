import { useQuery } from 'react-query';

import { getChannelListRequest } from '../api/channels';
import { useMainContext } from '../MainProvider';
import { useGetByIdWithCache } from '../hooks/useCache';
import { ChannelListItem } from '../api/channels.types';

const QUERY_KEY_CHANNELS = 'channels';

const channelsDefault: [] = [];

const getChannelListItemId = (item: ChannelListItem) => item.id;

export const useGetChannels = (options?: {enabled: boolean}) => {
  const { userData } = useMainContext();

  const {
    data = channelsDefault,
    isLoading,
    isError,
    refetch,
  } = useQuery(QUERY_KEY_CHANNELS, () => getChannelListRequest(userData), options);

  const get = useGetByIdWithCache(QUERY_KEY_CHANNELS, data, getChannelListItemId);

  return {
    channelList: data,
    isChannelListLoading: isLoading,
    isChannelListError: isError,
    refetchChannelList: refetch,
    getChannelListItemById: get,
  };
};
