import { TFunction } from 'i18next';

import { MessageItemType, SourceDestType, SourceDestTypes } from '../api/chat.types';
import { clutch } from './common';
import { AWRUserId } from './constants';
import { urlify } from './string';

export const REG_EXP_MESSAGE_VOICE = /{voice:(.*)}/m;
export const REG_EXP_MESSAGE_ATTACHMENT = /{(audio|image|video):(.+?)}/gm;

export type MessageAttachment = {
  type: 'audio' | 'video' | 'image',
  data: string
};

export const getMessageAttachments = (text: MessageItemType['text']) => {
  const attachments: MessageAttachment[] = [];

  let match;

  while ((match = REG_EXP_MESSAGE_ATTACHMENT.exec(text)) !== null) {
    if (match.index === REG_EXP_MESSAGE_ATTACHMENT.lastIndex) {
      REG_EXP_MESSAGE_ATTACHMENT.lastIndex++;
    }
    attachments.push({
      type: match[1] as MessageAttachment['type'],
      data: match[2].trim(),
    });
  }

  return attachments;
};

export const getMessageTextClear = (text: MessageItemType['text']) => {
  return text?.replace(REG_EXP_MESSAGE_ATTACHMENT, '')
    .replace(REG_EXP_MESSAGE_VOICE, '').trim();
};

export const getTextWithBreaklines = (text: string): string => {
  return text.replaceAll('\n', '<br>');
};

export const getIsMessageReminder = (message: Pick<MessageItemType, 'msgType'>) => {
  return message.msgType === 'reminder';
};

export const removeReminderPrefix = (text: string) => {
  if (!text) {
    return text;
  }

  return text.replace('Reminder -> ', '');
};

export const getIsMessageNotification = (message: Pick<MessageItemType, 'msgType'>) => {
  return message.msgType === 'notification';
};

export const getIsMessageIncoming = (
  message: Pick<MessageItemType, 'sourceId' | 'direction'>,
  userId: string,
  recipientType: SourceDestType,
  isRoleAdmin: boolean,
) => {
  //When being in admin role, you send messages as AWR
  if (isRoleAdmin && recipientType === SourceDestTypes.AWR) {
    return message.sourceId !== AWRUserId;
  }
  if (
    // Directions aren't actual in Campaign chat (some are "to", but should "from").
    recipientType === SourceDestTypes.CAMPAIGN
    // Directions are broken in Leader-DM chat (always have value "from").
    || recipientType === SourceDestTypes.EVANGELIST
  ) {
    return message.sourceId !== userId;
  }

  return !message.direction || message.direction === 'from';
};

export type GetMessageCanBeReadMessage = Pick<MessageItemType, 'sourceId' | 'status' | 'direction'>;

export const getIsMessageCanBeRead = (
  message: GetMessageCanBeReadMessage,
  userId: string,
  recipientType: SourceDestType,
  isRoleAdmin: boolean,
) => {
  if (message) {
    return getIsMessageIncoming(message, userId, recipientType, isRoleAdmin)
      && message.status !== 'viewed';
  }

  return false;
};

export const getIsMessageBelongsToChat = (
  message: MessageItemType, chatId: string
) => {
  return message.sendId === chatId || message.destId === chatId;
};

export const getIsTextWithAttachments = (text: string) => {
  return text.match(REG_EXP_MESSAGE_ATTACHMENT);
};

export const getIsTextWithVoice = (text: string) => {
  return text.match(REG_EXP_MESSAGE_VOICE);
};

export type MessageAppearanceType = 'request' | 'request-closed'
  | 'reminder' | 'notification' | 'default' | 'violet' | 'blue';

export const getMessageAppearanceIfSpecificMessage = (
  isIncoming: boolean, isReminder: boolean, isNotification: boolean,
  isMessageRequestClosed?: boolean,
): MessageAppearanceType | undefined => {
  let messageType: MessageAppearanceType | undefined;

  if (isReminder) {
    messageType = 'reminder';
  } else if (isNotification) {
    messageType = 'notification';
  } else if (isMessageRequestClosed !== undefined) {
    messageType = isMessageRequestClosed ? 'request-closed' : 'request';
  }

  return messageType;
};

export const makeMessageTextPlain = (text: string, t: TFunction) => {
  if (getIsTextWithAttachments(text)) {
    const textClear = getMessageTextClear(text);

    return (textClear ? (textClear + ' ') : '') + ('(' + t('withAttachments') + ')');
  }

  return getMessageTextClear(text);
};

export const getMessageTextClearUrlified = (text: string) => getTextWithBreaklines(urlify(getMessageTextClear(text)));

export const getMessageCountUpdated = (
  current: number, factor: number | undefined, newCount: number | undefined
): number => {
  if (factor !== undefined) {
    return clutch(current + factor, 0, Infinity);
  }

  return newCount ?? current;
};

export const makePinnedItemsFirst = <T>(
  pinnedIds: string[],
  items: T[],
  getIdFn: (item: T) => string,
): T[] => {
  const pinnedList = [];
  const notPinnedList = [];

  let item;
  for (let i = 0; i < items.length; i++) {
    item = items[i];

    if (pinnedIds.includes(getIdFn(item))) {
      pinnedList.push(item);
    } else {
      notPinnedList.push(item);
    }
  }

  return [
    ...pinnedList,
    ...notPinnedList,
  ];
};

export const makeDateFormatApi = () => {
  return new Date().toISOString();
};

export interface MessageFactors {
  ungraded: number,
  outgoing: number,
  unread: number,
  incoming: number,
}

export const createChatItemUpdated = <T extends MessageFactors>(
  item: T,
  newProperties: Partial<T>,
  messagesCountsFactors?: Partial<MessageFactors>
) => {
  return {
    ...item,
    ...newProperties,
    outgoing: getMessageCountUpdated(
      item.outgoing,
      messagesCountsFactors?.outgoing,
      newProperties?.outgoing
    ),
    unread: getMessageCountUpdated(
      item.unread,
      messagesCountsFactors?.unread,
      newProperties?.unread
    ),
    incoming: getMessageCountUpdated(
      item.incoming,
      messagesCountsFactors?.incoming,
      newProperties?.incoming
    ),
    ungraded: getMessageCountUpdated(
      item.ungraded,
      messagesCountsFactors?.ungraded,
      newProperties?.ungraded
    )
  };
};
