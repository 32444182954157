import React, { CSSProperties, forwardRef, SyntheticEvent } from 'react';
import classnames from 'classnames';

import {ColorScheme, TypographyVariant} from '../../types';
import { COMPONENT_COLOR_DEFAULT } from '../../utils/constants';
import { FontIconViewIcon } from '../../assets/FontIconView';
import { FontIconView } from '../index';
import { fixMePlease } from '../../utils/common';

import './Button.scss';

export type ButtonVariant =
  'default'
  | 'outline'
  | 'bg-white'
  | 'opacity'
  | 'transparent'
  | 'circle'
  | 'circle-transparent'
  | 'link';

export type ButtonMinWidth = 'sm' | 'md' | 'lg';
export type ButtonBorderRadiusSize = 'sm' | 'extra-lg';

export type ButtonSize = 'extra-sm' | 'sm' | 'md' | 'lg' | 'biggest';

export const ButtonBorderRadiusSizeToClassNameMap: Record<ButtonBorderRadiusSize, string> = {
  sm: 'br-s-sm',
  'extra-lg': 'br-s-extra-lg',
};

type ButtonIcon = React.ReactNode | React.FC | FontIconViewIcon;

export type ButtonProps = {
  className?: string,
  isActive?: boolean,
  disabled?: boolean,
  type?: 'button' | 'submit' | 'reset',
  borderRadiusSize?: ButtonBorderRadiusSize,
  variant?: ButtonVariant,
  typographyVariant?: TypographyVariant,
  color?: ColorScheme,
  minWidth?: ButtonMinWidth,
  size?: ButtonSize,
  iconBefore?: ButtonIcon,
  iconAfter?: ButtonIcon,
  style?: CSSProperties,
  onClick?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void,
  onContextMenu?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void,
  children?: React.ReactNode,
};

const renderButtonIcon = (Icon: ButtonIcon) => {
  if (typeof Icon === 'number') {
    // @ts-ignore
    return <FontIconView icon={Icon} />;
  }

  if (typeof Icon === 'function') {
    return <Icon />;
  }

  return Icon;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  size,
  variant,
  borderRadiusSize,
  typographyVariant,
  color,
  minWidth,
  iconBefore: IconBefore,
  iconAfter: IconAfter,
  isActive,
  disabled,
  onClick,
  onContextMenu,
  style,
  children,
  type,
}, ref) => {
  if (typeof IconAfter === 'function' || typeof IconBefore === 'function') {
    fixMePlease(
      'button',
      '%cOnly {AeFontIcons} or {FontIconView} are allowed as {IconBefore|IconAfter} for {Button}',
      'background: orange; color: white;',
      {IconAfter, IconBefore, children}
    );
  }

  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      className={classnames('btn', className, variant, typographyVariant, size, color,
        borderRadiusSize ? ButtonBorderRadiusSizeToClassNameMap[borderRadiusSize] : '',
        {
          'disabled': disabled,
          'with-icon': !!(IconBefore || IconAfter),
          'is-active': isActive,
        }, minWidth ? ('min-width-' + minWidth) : '')}
      style={style}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      {
        IconBefore || IconAfter
          ? (
            <>
              {renderButtonIcon(IconBefore)}
              <span>{(IconBefore && children) ? <>&nbsp;</> : ''}
                {children}
              </span>{(IconAfter && children) ? <>&nbsp;</> : ''}
              {renderButtonIcon(IconAfter)}
            </>
          )
          : children
      }
    </button>
  );
});

Button.defaultProps = {
  color: COMPONENT_COLOR_DEFAULT,
  variant: 'default',
  size: 'lg',
};

export default Button;
