import { useEffect, useState } from 'react';

import { useMainContext } from '../MainProvider';
import { useCache, useGetByIdWithCache } from './useCache';
import { MessageItemType } from '../api/chat.types';
import { getMessageUpdatesRequest } from '../api/chat';

const getMessageId = (item: MessageItemType) => item.id;

export const useReplyQuoteMessages = () => {
  const { userData } = useMainContext();

  // Cache is needed to not loose fetched messages when changing chat.
  const cache = useCache();

  const cacheKey = ['replyQuoteMessage'];

  const getCachedValue = () => cache.get<MessageItemType[]>(cacheKey, []);
  const setCachedValue = (newValue: MessageItemType[]) => cache.update<MessageItemType[]>(cacheKey, newValue);

  // {useState} to update {get} function via updating {replyQuoteMessages}.
  const [replyQuoteMessages, setReplyQuoteMessages] = useState(getCachedValue() || []);

  // Update cache after set new {replyQuoteMessages}.
  useEffect(() => {
    const cachedValue = getCachedValue();
    setCachedValue(cachedValue ? [...cachedValue, ...replyQuoteMessages] : replyQuoteMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyQuoteMessages]);

  const get = useGetByIdWithCache('replyQuoteMessageById', replyQuoteMessages, getMessageId);

  const fetchIfNeededReplyQuoteMessagesByIds = async (
    messagesIds: string[],
    evangelistId?: string,
  ) => {
    // Filter to fetch only new id(s).
    const messageIdsToFetch = messagesIds.filter((item) => !get(item));

    if (messageIdsToFetch.length) {
      const {messages} = await getMessageUpdatesRequest(userData, {
        messageIds: messageIdsToFetch,
      }, evangelistId);

      setReplyQuoteMessages((replyQuoteMessagesOld) => {
        return [...replyQuoteMessagesOld, ...messages];
      });
    }
  };

  return {
    fetchIfNeededReplyQuoteMessagesByIds,
    getReplyQuoteMessageById: get
  };
};
