import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { resetPassword } from '../api/account';

export const useResetPassword = () => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();

  const resetPasswordForEmail = useCallback(async (email, language, isShowToast?: boolean) => {
    await resetPassword(email, language);

    if (isShowToast) {
      showToast({
        title: t('resendEmailSuccess')
      });
    }
  }, [t, showToast]);

  return {
    resetPasswordForEmail,
  };
};
