import React from 'react';

import { Row } from '../index';

export type NoteProps = {
  head: React.ReactNode,
  body: React.ReactNode,
};

const Note: React.FC<NoteProps> = (props) => {
  return (
    <div>
      <Row
        gap='lg'
        directionHorizontal
        paddingHorizontal='lg'
        paddingVertical='sm'
        bgColor='chat-reminder'
        borderRadius='top'
      >
        {props.head}
      </Row>
      <Row border='all' borderRadius='bottom' style={{ overflow: 'hidden' }}>
        {props.body}
      </Row>
    </div>
  );
};

export default Note;
