import React, {ChangeEvent, SyntheticEvent} from 'react';
import classnames from 'classnames';

import { ColorScheme } from '../../types';

import './Checkbox.scss';

export type CheckboxProps = {
  readOnly?: boolean;
  value?: boolean;
  name?: string;
  className?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
  slotBefore?: React.ReactNode | React.FC,
  slotAfter?: React.ReactNode | React.FC,
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  onClick?: (e: SyntheticEvent<HTMLLabelElement, MouseEvent>) => void;
  color?: ColorScheme;
};

const Checkbox: React.FC<CheckboxProps> = ({
  wrapperClassName,
  className,
  slotAfter: SlotAfter,
  slotBefore: SlotBefore,
  onChange,
  onClick,
  disabled,
  value,
  readOnly,
  color,
  name,
}) => {
  return (
    <label className={classnames('checkbox', wrapperClassName, color, {
      'disabled': disabled,
    })} onClick={onClick}>
      {typeof SlotBefore === 'function' ? <SlotBefore /> : SlotBefore}
      {SlotBefore ? <>&nbsp;</> : ''}

      <input
        readOnly={readOnly}
        checked={value}
        name={name}
        type="checkbox"
        disabled={disabled}
        className={classnames('checkbox__input', className)}
        onChange={(e) => {
          if (onChange) {
            onChange(e, !value);
          }
        }}
      />
      <div className='checkbox__checkmark' />

      {SlotAfter ? <>&nbsp;</> : ''}
      {typeof SlotAfter === 'function' ? <SlotAfter /> : SlotAfter}
    </label>
  );
};

Checkbox.defaultProps = {
  color: 'blue'
};

export default Checkbox;
