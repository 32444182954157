import { EnumLiteralsOf } from '../types';

export enum Role {
  INTEREST = -1,
  DM = 1,
  LEADER,
  DM_LEADER,
  CM,
  CM_DM,
  CM_LEADER,
  CM_DM_LEADER,
  ADMIN,
}

export const RoleStatuses = {
  active: 'active',
  pending: 'pending',
  denied: 'denied',
  deactivate: 'deactivated', // not a real role status, need just for filter
  null: null,
} as const;

export type RoleStatus = EnumLiteralsOf<typeof RoleStatuses>;

export type UserRoleStatus = {
  evangelist: RoleStatus;
  leader: RoleStatus;
  manager: RoleStatus;
};

export type UserRoleStatusValues = keyof UserRoleStatus;

export const RoleStatusMap: Record<string, UserRoleStatusValues> = {
  DM: 'evangelist',
  LEADER: 'leader',
  CM: 'manager',
} as const;
