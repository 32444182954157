import React, { ReactNode } from 'react';

import { Row, Typography, UserIcon } from '../index';
import { makeLabelFullName } from '../../utils/common';
import { RowProps } from '../row/Row';
import {UserIconProps} from '../user-icon/UserIcon';

export type UserItemType = {
  firstname: string | null;
  lastname: string | null;
  id: string;
};

export type UserItemProps = {
  userIconProps?: Partial<UserIconProps>;
  rootProps?: RowProps;
  item: UserItemType;
  slotAfter?: ReactNode;
  onClick?: (item: UserItemType) => void;
};

const UserItem: React.FC<UserItemProps> = React.memo(({
  item, onClick, slotAfter, userIconProps, rootProps
}) => {
  return (
    <Row
      color='blue'
      onClick={() => {
        if (onClick) {
          onClick(item);
        }
      }}
      border='bottom'
      directionHorizontal
      {...rootProps}
    >
      <Row
        directionHorizontal
        style={{ gridTemplateColumns: '1fr auto' }}
        gap='md'
        horizontal='space-between'
        vertical='center'
        paddingVertical='extra-sm'
      >
        <Row
          directionHorizontal
          autoColumns
          gap='md'
          horizontal='start'
          vertical='center'
          style={{ flexGrow: 1 }}
        >
          <UserIcon size='sm' item={item} {...userIconProps} />
          <Typography variant='poppins' component='h4' color='text-body' threeDots>
            {makeLabelFullName(item)}
          </Typography>
        </Row>
        {slotAfter}
      </Row>
    </Row>
  );
});

export default UserItem;
