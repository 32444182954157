import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { isTablet } from 'react-device-detect';

import { Role, RoleStatus, RoleStatusMap, UserRoleStatus } from './utils/enums';
import { setUiRoleCssVariables } from './utils/theme';
import { AppVersion } from './utils/constants';
import { getProfileRequest } from './api/account';
import { createCookieStorageManager, createLocalStorageManager } from './utils/storage';
import { fetchRefreshAgentToken } from './api/api';
import { QUERY_KEY_REQUESTS } from './query/request';
import { useStorageManager } from './hooks/useStorageManager';
import { QUERY_KEY_SEEKERS_LIST } from './query/seekers';
import { QUERY_KEY_MESSAGE_HISTORY } from './query/chat';
import { QUERY_KEY_LIST_CAMPAIGNS, QUERY_KEY_MY_CAMPAIGNS } from './query/campaigns';
import { QUERY_KEY_CHAT_LIST_LEADERS } from './query/leaders';
import { QUERY_KEY_COURSES } from './query/courses';
import { QUERY_KEY_ACHIEVEMENTS } from './query/achievements';
import { useSetI18Language } from './hooks/useLanguage';
import { desktopWidthBreakPoint } from './components/side-menu/SideMenu.constants';
import { fetchUserLanguages, getAvailableLanguagesRequest } from './api/languages';
import { getAppVersionRequest } from './api/settings';
import { QUERY_KEY_PROFILE, updateProfileById } from './query/profile';
import { QUERY_KEY_AVAILABLE_LANGUAGES, QUERY_KEY_USER_LANGUAGES, setQueryDataUserLanguages } from './query/languages';
import { toastEnhanced } from './enhanced-components/toaster/ToasterEnhanced';
import { ApiRequestError } from './api/api.utils';
import {
  isRoleAdmin,
  isRoleInlcudesCM,
  isRoleIncludesLeader,
  isRoleStatusHasCmAndNotLeader,
  isRoleStatusHasCmAndDM,
  isRoleStatusHasLeaderAndNotCM,
  isRoleStatusHasLeaderAndCM,
  isRoleStatusHasLeaderAndDM,
  getInitialUiRoleByUserRoleStatus, isRoleStatusHasDM, isRoleStatusOnlyDM
} from './utils/profile';
import { QUERY_KEY_EVANGELIST_STATS } from './pages/profile/ProfilePage';
import { lastChosenCampaignLS } from './pages/dashboard/DashboardLeader.utils';
import { QUERY_KEY_PRAYER } from './query/prayer';
import { QUERY_KEY_REMINDERS } from './query/reminder';
import { QUERY_KEY_CHAT_LIST_EVANGELISTS } from './query/evangelists';
import {
  QUERY_KEY_STUDENT_COMMAND,
  QUERY_KEY_STUDENT_HISTORY,
  QUERY_KEY_STUDENTS,
  QUERY_KEY_STUDIES,
  QUERY_KEY_STUDY_LIMITS
} from './query/students';
import { devDataStorage } from './components/change-server/ChangeServerPopup.utils';

type UserData = {
  role: Role;
  roleStatus: UserRoleStatus;
  agentToken: string;
  id: string;
};

interface MainContextProps {
  uiRole: Role;
  isInitDataLoaded: boolean;
  isAuth: boolean;
  isAppVersionDiffers: boolean;
  isRoleLeader: boolean;
  isUiRoleLeader: boolean;
  isUiRoleCM: boolean;
  isUiRoleDM: boolean;
  isRoleLeaderCM: boolean;
  isRoleCM: boolean;
  isRoleAdmin: boolean;
  isRoleLeaderDM: boolean;
  isRoleContainsDMRole: boolean;
  isRoleCmDm: boolean;
  isOnlyDM: boolean;
  isUiRoleNotDM: boolean;
  isSideMenuSliding: boolean;
  isMenuOpen: boolean;
  currentRoleStatus: RoleStatus;
  handleAuthWithFetchedUserData: (userData: UserData, rememberMe: boolean) => Promise<void>;
  logout: () => void;
  setRole: (role: Role) => void;
  toggleMenu: () => void;
  userData: UserData;
}

const userDataEmpty = {
  id: '',
  agentToken: '',
  role: Role.DM,
  roleStatus: { evangelist: null, leader: null, manager: null },
};

const MainContext = createContext<MainContextProps>({} as MainContextProps);

type UserDataStorage = UserData & {
  username: string;
  rememberMe: boolean;
};

const userDataCS = createCookieStorageManager<UserDataStorage>('userData');
const userUiRoleLS = createLocalStorageManager<Role>('userSettings');
const checkedVersionsLS = createLocalStorageManager<string[]>('checkedVersions');

const loadUserDataStored = () => {
  return userDataCS.load();
};

const isSideMenuSliding = () => window.innerWidth < desktopWidthBreakPoint || isTablet;

const MainProvider: React.FC = ({ children }) => {
  const isTokenRefetching = useRef(false);
  const queryClient = useQueryClient();
  const { setI18Language } = useSetI18Language();

  const {
    value: uiRoleStored,
    updateValue: setUiRoleStored,
    removeValue: removeUiRoleStored,
  } = useStorageManager(userUiRoleLS);

  const [isAppVersionDiffers, setIsAppVersionDiffers] = useState(false);
  const [isInitDataLoaded, setIsInitDataLoaded] = useState(false);
  const [sideMenuSliding, setSideMenuSliding] = useState(isSideMenuSliding());
  const [menuOpen, setMenuOpen] = useState(!sideMenuSliding);

  const [userData, setUserData] = useState<UserData>(userDataEmpty);

  const isAuth = !!userData.id;

  const uiRole = useMemo(() => {
    if (uiRoleStored) {
      return uiRoleStored;
    }

    const roleSuitable = getInitialUiRoleByUserRoleStatus(userData.roleStatus);

    if (isAuth) {
      setUiRoleStored(roleSuitable);
    }

    return roleSuitable;
  }, [isAuth, userData, uiRoleStored, setUiRoleStored]);

  const isRoleAdminValue = isRoleAdmin(uiRole);

  const isLeaderAndCM = isRoleStatusHasLeaderAndCM(userData.roleStatus, isRoleAdminValue);
  const isLeaderAndDM = isRoleStatusHasLeaderAndDM(userData.roleStatus, isRoleAdminValue);

  const isCmAndDM = isRoleStatusHasCmAndDM(userData.roleStatus, isRoleAdminValue);

  const isCmAndNotLeader = isRoleStatusHasCmAndNotLeader(userData.roleStatus, isRoleAdminValue);
  const isLeaderAndNotCM = isRoleStatusHasLeaderAndNotCM(userData.roleStatus, isRoleAdminValue);

  const isRoleContainsDMRole = isRoleStatusHasDM(userData.roleStatus, isRoleAdminValue);
  const isOnlyDM = isRoleStatusOnlyDM(userData.roleStatus, isRoleAdminValue);

  const currentRoleStatus = isRoleAdminValue ? null : userData.roleStatus[RoleStatusMap[Role[uiRole]]];

  const logout = useCallback(() => {
    let id;
    setUserData((userDataOld) => {
      id = userDataOld.id;

      return userDataEmpty;
    });

    removeUiRoleStored();
    lastChosenCampaignLS.remove();
    userDataCS.remove();

    // Remove queries cache on logout. Possibly, not all necessary keys are listed here – pls fix.
    queryClient.removeQueries([QUERY_KEY_PROFILE, id]);

    queryClient.removeQueries(QUERY_KEY_REQUESTS);
    queryClient.removeQueries(QUERY_KEY_REMINDERS);
    queryClient.removeQueries(QUERY_KEY_PRAYER);
    queryClient.removeQueries(QUERY_KEY_SEEKERS_LIST);

    queryClient.removeQueries(QUERY_KEY_EVANGELIST_STATS);
    queryClient.removeQueries(QUERY_KEY_USER_LANGUAGES);
    queryClient.removeQueries(QUERY_KEY_ACHIEVEMENTS);
    queryClient.removeQueries(QUERY_KEY_COURSES);

    queryClient.removeQueries(QUERY_KEY_MY_CAMPAIGNS); // Fixes prev Leader's dashb. on usr change.
    queryClient.removeQueries(QUERY_KEY_LIST_CAMPAIGNS);

    queryClient.removeQueries(QUERY_KEY_MESSAGE_HISTORY);

    queryClient.removeQueries(QUERY_KEY_CHAT_LIST_EVANGELISTS);
    queryClient.removeQueries(QUERY_KEY_CHAT_LIST_LEADERS);

    queryClient.removeQueries(QUERY_KEY_STUDENTS);
    queryClient.removeQueries(QUERY_KEY_STUDY_LIMITS);
    queryClient.removeQueries(QUERY_KEY_STUDIES);
    queryClient.removeQueries(QUERY_KEY_STUDENT_HISTORY);
    queryClient.removeQueries(QUERY_KEY_STUDENT_COMMAND);
  }, [removeUiRoleStored, queryClient]);

  const saveAdmin = useCallback(async (userData: UserData, rememberMe: boolean) => {
    setUserData(userData);

    const userLanguageData = { languages: ['eng'], primaryLanguage: 'eng' } as
      ApiRequestError & { languages: string[], primaryLanguage: string; };

    setQueryDataUserLanguages(queryClient, userLanguageData, undefined);
    await setI18Language('fr');

    userDataCS.save({
      ...userData,
      username: 'admin',
      rememberMe,
    });
  }, [queryClient, setI18Language]);

  const handleAuthWithFetchedUserData = useCallback(async (
    userData: UserData, rememberMe: boolean
  ) => {
    if (userData.role === Role.ADMIN) {
      await saveAdmin(userData, rememberMe);
      return;
    }

    const profileData = await getProfileRequest(userData);
    const userLanguageData = await fetchUserLanguages(userData);

    if (profileData) {
      setUserData({
        ...userData,
        // overriding to keep actual roleStatus from {profile}
        // because it is loaded every single time unlike {userData}
        roleStatus: {
          evangelist: profileData.profile.status,
          leader: profileData.profile.leaderStatus,
          manager: profileData.profile.managerStatus,
        }
      });
      // set profile to prevent redirect to register page
      queryClient.setQueryData([QUERY_KEY_PROFILE, userData.id], profileData);
      setQueryDataUserLanguages(queryClient, userLanguageData, undefined);
      updateProfileById(queryClient, { id: userData.id, newValues: profileData });

      if (userLanguageData.primaryLanguage) {
        await setI18Language(userLanguageData.primaryLanguage);
      }

      userDataCS.save({
        ...userData,
        username: profileData.profile.email || '',
        rememberMe,
      });
    }
  }, [saveAdmin, queryClient, setI18Language]);

  const handleAuthorizeError = useCallback((e: ApiRequestError) => {
    logout();

    toastEnhanced({
      title: e.message,
    }, {
      type: 'error',
    });
  }, [logout]);

  const tryRefreshToken = useCallback(async (
    userData: UserData, rememberMe: boolean, username: string
  ) => {
    if (!isTokenRefetching.current) {
      isTokenRefetching.current = true;

      await fetchRefreshAgentToken({
        id: userData.id,
        agentToken: userData.agentToken,
        username,
      }).then(async (userDataNew) => {
        await handleAuthWithFetchedUserData({
          ...userData,
          agentToken: userDataNew.token,
        }, rememberMe);
        window.apiRequestsToRefetch.forEach(async (request) => {
          // @ts-ignore
          request.observers.pop()?.refetch();
        });
        window.apiRequestsToRefetch = [];
      }).catch(handleAuthorizeError).finally(() => {
        isTokenRefetching.current = false;
      });
    }
  }, [handleAuthWithFetchedUserData, handleAuthorizeError]);

  const handleAuthWithStoredUserData = useCallback(async (
    userData: UserData, rememberMe: boolean, username: string
  ) => {
    try {
      await handleAuthWithFetchedUserData(userData, rememberMe);
    } catch (e: any) {
      if (rememberMe && username) {
        await tryRefreshToken(userData, rememberMe, username);
      } else {
        handleAuthorizeError(e);
      }
    }
  }, [handleAuthWithFetchedUserData, tryRefreshToken, handleAuthorizeError]);

  const toggleMenu = useCallback(() => {
    setMenuOpen((oldValue) => !oldValue);
  }, []);

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isSideMenuSliding()) {
        closeMenu();
        setSideMenuSliding(true);
      } else {
        openMenu();
        setSideMenuSliding(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [closeMenu, openMenu]);

  const setRole = useCallback((role: Role) => {
    setUiRoleStored(role);
  }, [setUiRoleStored]);

  useEffect(() => {
    setUiRoleCssVariables(uiRole);
  }, [uiRole]);

  useEffect(() => {
    // Prefetch available languages on app start.
    const languagePromise = getAvailableLanguagesRequest().then((response) => {
      queryClient.setQueryData(QUERY_KEY_AVAILABLE_LANGUAGES, response);
    }).catch(() => {});
    // =========================
    const appVersionPromise = getAppVersionRequest();

    let changeUserDataPromise;

    const cookieStorageUserData = loadUserDataStored();

    if (cookieStorageUserData) {
      changeUserDataPromise = handleAuthWithStoredUserData({
        id: cookieStorageUserData.id,
        agentToken: cookieStorageUserData.agentToken,
        role: cookieStorageUserData.role,
        roleStatus: cookieStorageUserData.roleStatus,
      }, cookieStorageUserData.rememberMe, cookieStorageUserData.username);
    }

    Promise.all([appVersionPromise, changeUserDataPromise, languagePromise])
      .then(([appVersionServer]) => {
        const appVersionsChecked = checkedVersionsLS.load() || [];
        const isAppVersionChecked = appVersionsChecked.includes(AppVersion);

        if (!isAppVersionChecked) {
          if (AppVersion !== appVersionServer) {
            setIsAppVersionDiffers(true);
          }

          // Prevent infinity checks if some unintentionally problems with versions.
          checkedVersionsLS.save([...appVersionsChecked, AppVersion]);
        }
      })
      .finally(() => {
        setIsInitDataLoaded(true);
      });
  }, [queryClient, handleAuthWithStoredUserData]);

  // Try refresh the token when got 401 while user use app as authorized.
  useEffect(() => {
    const handleError401 = (e: CustomEvent<ApiRequestError>) => {
      const cookieStorageUserData = loadUserDataStored();

      if (cookieStorageUserData) {
        if (cookieStorageUserData.rememberMe) {
          tryRefreshToken({
            id: cookieStorageUserData.id,
            agentToken: cookieStorageUserData.agentToken,
            role: cookieStorageUserData.role,
            roleStatus: cookieStorageUserData.roleStatus,
          }, cookieStorageUserData.rememberMe, cookieStorageUserData.username);
        } else {
          handleAuthorizeError(e.detail);
        }
      }
    };

    if (isAuth) {
      window.addEventListener('apiError401', handleError401);
    }

    return () => {
      if (isAuth) {
        window.removeEventListener('apiError401', handleError401);
      }
    };
  }, [isAuth, tryRefreshToken]);

  const value: MainContextProps = {
    isAuth,
    isAppVersionDiffers,
    isUiRoleLeader: isRoleIncludesLeader(uiRole),
    isUiRoleCM: isRoleInlcudesCM(uiRole),
    isUiRoleDM: uiRole === Role.DM,
    isUiRoleNotDM: uiRole !== Role.DM,
    isInitDataLoaded,
    isMenuOpen: menuOpen,
    isSideMenuSliding: sideMenuSliding,
    currentRoleStatus,
    toggleMenu,
    uiRole,
    isOnlyDM,
    isRoleAdmin: isRoleAdminValue,
    isRoleLeaderCM: isLeaderAndCM,
    isRoleCM: isCmAndNotLeader,
    isRoleLeader: isLeaderAndNotCM,
    isRoleContainsDMRole,
    isRoleLeaderDM: isLeaderAndDM,
    isRoleCmDm: isCmAndDM,
    setRole,
    handleAuthWithFetchedUserData,
    logout,
    userData,
  };
  
  return (
    <MainContext.Provider value={value}>
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext);

export default MainProvider;
