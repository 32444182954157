import React  from 'react';

import CriteriaItem from './criteria-item/CriteriaItem';

import './CriteriaList.scss';

export type CriteriaListItem = {
  completed: boolean,
  criteria: string,
};

type CriteriaListProps = React.HTMLProps<HTMLDivElement> & {
  items: CriteriaListItem[],
};

const CriteriaList: React.FC<CriteriaListProps> = ({ items }) => {
  const showIcon = items.length > 1;

  return (
    <div className='criteria-list'>
      {
        items.map(({ completed, criteria }) => (
          <CriteriaItem
            key={criteria}
            showIcon={showIcon}
            completed={completed}
          >{criteria}</CriteriaItem>
        ))
      }
    </div>
  );
};

export default CriteriaList;
