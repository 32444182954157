import React, {
  createContext,
  useState,
  useContext,
  SyntheticEvent
} from 'react';

import { Modals } from './Modals';
import { ButtonProps } from '../button/Button';

const DEFAULT_INDEX = 105;

type ModalContextValue = {
  openModal: (component: StackedModal['component'], props?: StackedModalProps) => void,
  updateModal:  (props?: Partial<StackedModalProps>) => void,
  closeModal: () => void,
  closeModalWithTitle: (title: string) => void,
  closeAmountOfModals: (amount: number) => void,
  closeAllModals: () => void,
}

const ModalContext = createContext<ModalContextValue>({} as ModalContextValue);

export interface ModalStackValue {
  closeModal: () => void;
  stack: StackedModal[];
}

export interface StackedModalProps {
  loading?: boolean
  title?: string;
  scrollbarsClassName?: string;
  closeButton?: boolean,
  cancelButton?: boolean,
  cancelButtonProps?: Partial<Omit<ButtonProps, 'onClick'>> & {
    // if {true} is returned - close the modal.
    // a bit crunch, but no choice for now.
    onClick?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>)
      => Promise<boolean | void> | boolean | void,
  },
  okButton?: boolean,
  okButtonProps?: Partial<Omit<ButtonProps, 'onClick'>> & {
    // if {true} is returned - close the modal.
    // a bit crunch, but no choice for now.
    onClick?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>)
      => Promise<boolean | void> | boolean | void,
  },
  widthSize?: 'sm' | 'md' | 'lg';
  titleAlign?: 'left' | 'center' | 'right';
  hideOnBackdropClick?: boolean;
  higherIndex?: boolean;
  zIndex?: number;
  onClose?: () => void;
}

export type StackedModal = {
  component: React.ReactNode;
  props: StackedModalProps;
}

export interface ModalStackProps {
  renderModals?: React.ComponentType<ModalStackValue>;
  children?: React.ReactNode;
}

const ModalProvider: React.FC<ModalStackProps> = (props) => {
  const [stack, setStack] = useState<StackedModal[]>([]);

  const openModal = (component: React.ReactNode, props?: StackedModalProps) => {
    setStack((oldStack) => {
      const defaultIndex =  DEFAULT_INDEX + oldStack.length;
      const zIndex = props?.higherIndex ? defaultIndex + 5 : defaultIndex;

      return [
        ...oldStack,
        { component, props: { ...props, zIndex } } as StackedModal,
      ];
    });
  };

  const updateModal = (props?: Partial<StackedModalProps>) => {
    setStack((oldStack) => {
      const newStack = [...oldStack];
      newStack[newStack.length - 1].props = {
        ...newStack[newStack.length - 1].props,
        ...props,
      };

      return [
        ...oldStack,

      ];
    });
  };

  function closeStack(stack: StackedModal) {
    if (stack.props?.onClose) {
      stack.props.onClose();
    }
  }

  function pop(amount = 1) {
    return setStack((prev) => {
      if (prev.length) {
        const copy = [...prev];
        copy.splice(-amount).forEach(closeStack);
        return copy;
      }

      return prev;
    });
  }

  const closeModal = () => pop(1);

  function dismissAll() {
    setStack((prevStack) => {
      if (prevStack.length) {
        prevStack.forEach(closeStack);
        return [];
      }

      return prevStack;
    });
  }

  function dismissByTitle(title: string) {
    setStack((oldStack) => {
      return oldStack.filter((stack) => {
        if (stack.props.title === title) {
          closeStack(stack);
          return false;
        }

        return true;
      });
    });
  }

  return (
    <ModalContext.Provider value={{
      openModal,
      updateModal,
      closeModal,
      closeModalWithTitle: (title: string) => dismissByTitle(title),
      closeAmountOfModals: pop,
      closeAllModals: dismissAll
    }}>
      {props.children}
      <Modals closeModal={closeModal} stack={stack} />
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

export default ModalProvider;
