import React from 'react';
import { Redirect, Route, Switch} from 'react-router-dom';

import PageLogin from './auth/login/PageLogin';
import PageRegister  from './auth/register/PageRegister';
import PageRestore from './auth/restore/PageRestore';
import { URLS } from '../utils/constants';
import { BannerWelcome } from '../components';
import PageEmailVerification from './auth/register/PageEmailVerification';
import PageSetupProfile from './auth/register/PageSetupProfile';

import './LoginLayout.scss';

const LoginLayout = React.memo(() => {
  return (
    <div className="layout-login">
      <BannerWelcome />
      <div className="layout-login__content">
        <Switch>
          <Route
            path={URLS.registerStepVerification}
            component={PageEmailVerification}
          />
          <Route
            path={URLS.registerStepSetup}
            component={PageSetupProfile}
          />
          <Route
            path={URLS.register}
            component={PageRegister}
          />

          <Route
            path={URLS.loginRestore}
            component={PageRestore}
          />
          <Route
            path={URLS.login}
            component={PageLogin}
          />

          <Route><Redirect to={URLS.login} /></Route>
        </Switch>
      </div>
    </div>
  );
});

export default LoginLayout;
