import { MessageItemType } from './chat.types';
import { getMessageTextClearUrlified } from '../utils/chat';
import {
  BroadcastFromApiType,
  BroadcastItemType,
  GetBroadcastMessagesRequestMessage
} from './broadcasts.types';
import { formatDateForApi } from '../utils/time';

const fixBroadcastDate = (dateString: string /* yyyy-MM-dd HH:mm:ss */) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() + 4);
  return formatDateForApi(date);
};

export const normalizeBroadcastMessageItem = (
  message: GetBroadcastMessagesRequestMessage
): MessageItemType => {
  const date = fixBroadcastDate(message.date);
  return {
    destId: '',
    sourceId: '',
    replyId: null,
    requestId: null,
    quoteId: null,
    sendId: '',
    msgNotes: null,
    msgScore: null,
    sent: null,
    rowIndex: 0,
    destType: 's',
    sourceType: 's',
    msgStatus: 'ignored',
    msgVetterId: null,
    direction: 'to',
    sendDate: date,
    msgType: message.audio ? 'both' : 'text',
    textClearUrlified: getMessageTextClearUrlified(message.text),
    ...message,
    date,
  };
};

/* Normalizes that '0' id broadcast has completely another structure. */
export const normalizeBroadcastItem = (item: BroadcastFromApiType): BroadcastItemType => {
  const messageData = (item.messages || item);
  const date = messageData.date ? fixBroadcastDate(messageData.date) : '';
  return {
    name: item.name,
    broadcastId: typeof item.broadcastId === 'number'
      ? item.broadcastId.toString()
      : item.broadcastId,
    seekers: item.seekers || [],
    date,
    text: messageData.text || '',
    status: 'sent',
  };
};
