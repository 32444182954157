import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CheckListWithInput, FormField, Input, InputPhoneNumber, Select } from '../';
import { InputPhoneNumberProps } from '../input/InputPhoneNumber';
import {
  checkPasswordLength,
  checkPasswordLettersCases,
  checkPasswordNumberExistence,
  createMaxLengthValidator,
  isEmail,
  isRequired,
} from './Form.utils';
import {
  FilterActiveSeekersValues,
  FilterByDateValues,
  FilterCampaignStatusValues,
  FilterMessageStatusValues,
  FilterProfileStatusValues,
  FilterRequestStatusValues
} from '../../utils/filters';
import { SortByDateValues, SortByEvangelistValues, SortByStudentValues } from '../../utils/sorts';
import {FormFieldProps} from './field/FormField';
import { SelectOption } from '../select/Select.types';
import { renderField } from '../custom-field/customField';
import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';
import { useGetChannels } from '../../query/channels';
import { ChannelPlatform } from '../../api/channels.types';
import { useGetAvailableLanguages } from '../../query/languages';

export const FieldEmail: React.FC<Partial<FormFieldProps>> = (props) => {
  const { t } = useTranslation();

  return (
    <FormField
      showMarkRequired
      component={Input}
      componentProps={{
        placeholder: t('enterEmail'),
        type: 'email',
        autoComplete: 'username',
      }}
      validators={[isRequired, isEmail]}
      name="email"
      {...props}
    />
  );
};

// TODO rename when will be added to sign up
export const FieldPasswordLogin: React.FC<any> = ({ register, errors, componentProps }) => {
  const { t } = useTranslation();
  const [showValue, setShowValue] = useState(false);

  return (
    renderField(Input, {
      ...register('password'),
      errorText: t(errors.password?.message || ''),
      placeholder: t('enterPassword'),
      autoComplete: 'new-password',
      type: showValue ? 'text' : 'password',
      labelText: t('labelPass'),
      size: 'lg',
      showMarkRequired: true,
      slotAfter: (
        <Button
          style={{
            paddingLeft: '0.3rem',
            paddingRight: '0.1rem',
            fontSize: '1.2rem',
          }}
          type='button'
          color='text-title-light'
          variant='transparent'
          onClick={() => setShowValue(!showValue)}
          iconAfter={showValue ? AeFontIcons.eye : AeFontIcons.eyeCrossed}
        />
      ),
      ...componentProps,
    })
  );
};

export const FieldPassword: React.FC<Partial<FormFieldProps>> = ({
  componentProps, ...fieldProps
}) => {
  const { t } = useTranslation();
  const [showValue, setShowValue] = useState(false);

  const iconTogglePassword = showValue ? AeFontIcons.eye : AeFontIcons.eyeCrossed;
  const ButtonTogglePassword = <Button
    style={{
      paddingLeft: '0.3rem',
      paddingRight: '0.1rem',
      fontSize: '1.2rem',
    }}
    type='button'
    color='text-title-light'
    variant='transparent'
    onClick={() => {
      setShowValue(!showValue);
    }}
  >
    <FontIconView icon={iconTogglePassword} style={{ display: 'block', height: '1rem', }}/>
  </Button>;

  return (
    <FormField
      component={Input}
      showMarkRequired
      validators={[
        isRequired, checkPasswordLettersCases,
        checkPasswordNumberExistence, checkPasswordLength
      ]}
      componentProps={{
        placeholder: t('enterPassword'),
        type: showValue ? 'text' : 'password',
        autoComplete: 'new-password',
        slotAfter: ButtonTogglePassword,
        ...componentProps,
      }}
      name="password"
      {...fieldProps}
    />
  );
};

export const FieldFirstName: React.FC<Partial<FormFieldProps>> = (props) => {
  const { t } = useTranslation();

  return (
    <FormField
      showMarkRequired
      validators={[isRequired, createMaxLengthValidator(40)]}
      name="firstName"
      component={Input}
      componentProps={{
        placeholder: t('enterFirstName'),
        type: 'text',
        autoComplete: 'off',
      }}
      {...props}
    />
  );
};

export const FieldLastName: React.FC<Partial<FormFieldProps>> = (props) => {
  const { t } = useTranslation();

  return (
    <FormField
      component={Input}
      componentProps={{
        placeholder: t('enterLastName'),
        type: 'text',
        autoComplete: 'off',
      }}
      showMarkRequired
      validators={[isRequired, createMaxLengthValidator(40)]}
      name="lastName"
      {...props}
    />
  );
};

export const FieldPhoneNumber: React.FC<Partial<FormFieldProps & InputPhoneNumberProps>> = ({
  code, componentProps, ...fieldProps
}) => {
  const { t } = useTranslation();

  return (
    <FormField
      component={InputPhoneNumber}
      componentProps={{
        placeholder: t('enterPhone'),
        type: 'text',
        autoComplete: 'off',
        code,
        ...componentProps,
      }}
      showMarkRequired
      validators={[isRequired]}
      name='phoneNumber'
      {...fieldProps}
    />
  );
};

const filterSortDateOptions: SelectOption<SortByDateValues>[] = [
  {
    key: 'newestFirst',
    label: 'newestFirst',
  },
  {
    key: 'oldestFirst',
    label: 'oldestFirst',
  }
];

export const FormFieldSortDate = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('sortBy')}
      name='date'
      component={Select}
      size='sm'
      componentProps={{
        placeholder: t('all'),
        options: filterSortDateOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        })),
      }}
    />
  );
};

const filterSortEvangelistOptions: SelectOption<SortByEvangelistValues>[] = [
  {
    key: 'filterResponseTime',
    label: 'filterResponseTime',
  },
  {
    key: 'filterChatScore',
    label: 'filterChatScore',
  },
  {
    key: 'filterDmScore',
    label: 'filterDmScore',
  },
  {
    key: 'filterSeekers',
    label: 'filterSeekers',
  },
  {
    key: 'filterLeader',
    label: 'filterLeader',
  },
];

export const FormFieldSortEvangelist = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('sortBy')}
      name='evangelist'
      component={Select}
      size='sm'
      componentProps={{
        placeholder: t('all'),
        options: filterSortEvangelistOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        })),
      }}
    />
  );
};

const filterSortStudentOptions: SelectOption<SortByStudentValues>[] = [
  {
    key: 'oldestContact',
    label: 'oldestContact',
  },
  {
    key: 'recentContact',
    label: 'recentContact',
  },
  {
    key: 'oldestChat',
    label: 'oldestChat',
  },
  {
    key: 'recentChat',
    label: 'recentChat',
  },
  {
    key: 'highestLesson',
    label: 'highestLesson',
  },
  {
    key: 'lowestLesson',
    label: 'lowestLesson',
  },
];

export const FormFieldSortStudent = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('sortBy')}
      name='student'
      component={Select}
      size='sm'
      componentProps={{
        placeholder: t('all'),
        options: filterSortStudentOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        })),
      }}
    />
  );
};


export const FormFieldChannelPlatforms = () => {
  const { t } = useTranslation();

  const { channelList, isChannelListLoading } = useGetChannels();

  const options = useMemo(() => {
    const platforms: ChannelPlatform[] = [];
    const channelsUnique = channelList.filter(({platform}) => {
      if (platforms.includes(platform)) {
        return false;
      }
      platforms.push(platform);
      return true;
    });

    const channelOptions = channelsUnique.map(({ platform, friendlyName }) => ({
      key: platform,
      label: friendlyName,
    }));

    return [
      { key: '', label: t('all'), },
      ...channelOptions,
    ];
  }, [t, channelList]);

  return (
    <FormField
      labelText={t('channelPlatform')}
      name='channelPlatforms'
      component={CheckListWithInput}
      size='sm'
      componentProps={{
        isLoading: isChannelListLoading,
        placeholder: t('all'),
        options,
      }}
    />
  );
};

export const FormFieldLanguage = () => {
  const { t } = useTranslation();

  const { availableLanguages, isAvailableLanguagesLoading } = useGetAvailableLanguages();

  const options: SelectOption[] = useMemo(() => {
    return [
      { key: '', label: t('all'), },
      ...availableLanguages.map((l) => ({
        key: l.language,
        label: l.name,
      })),
    ];
  }, [t, availableLanguages]);

  return (
    <FormField
      labelText={t('language')}
      name="language"
      component={Select}
      size="sm"
      componentProps={{
        disabled: isAvailableLanguagesLoading,
        placeholder: t('all'),
        options: options
      }}
    />
  );
};

const filterRequestStatusOptions: SelectOption<FilterRequestStatusValues>[] = [
  {
    key: 'open',
    label: 'openRequest',
  },
  {
    key: 'closed',
    label: 'closedRequest',
  },
  {
    key: 'waiting',
    label: 'waitingRequest',
  }
];

export const FieldRequestStatus = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('requestStatus')}
      name='requestStatus'
      size='sm'
      component={Select}
      componentProps={{
        placeholder: t('all'),
        options: filterRequestStatusOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        }))
      }}
    />
  );
};

const filterCampaignStatusOptions: SelectOption<FilterCampaignStatusValues>[] = [
  {
    key: 'ready',
    label: 'ready',
  },
  {
    key: 'running',
    label: 'running',
  },
  {
    key: 'finished',
    label: 'finished',
  },
];

export const FieldCampaignStatus = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('campaignStatus')}
      name='campaignStatus'
      size='sm'
      component={Select}
      componentProps={{
        placeholder: t('all'),
        options: filterCampaignStatusOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        }))
      }}
    />
  );
};

const filterActiveSeekersOptions: SelectOption<FilterActiveSeekersValues>[] = [
  {
    key: '',
    label: 'all',
  },
  {
    key: 'has-interests',
    label: 'withActiveSeekers',
    icon: AeFontIcons.checkmarkRound,
  },
  {
    key: 'no-interests',
    label: 'withoutActiveSeekers',
    icon: AeFontIcons.closeRound,
  },
];

export const FieldActiveSeekers = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('activeSeekers')}
      name='activeSeekers'
      size='sm'
      component={Select}
      componentProps={{
        placeholder: t('all'),
        options: filterActiveSeekersOptions.map((item) => ({
          ...item,
          label: t(item.label),
        }))
      }}
    />
  );
};


const filterByDateOptions: SelectOption<FilterByDateValues>[] = [
  {
    key: 'today',
    label: 'today',
  },
  {
    key: 'yesterday',
    label: 'yesterday',
  },
  {
    key: 'pastWeek',
    label: 'pastWeek',
  },
  {
    key: 'pastMonth',
    label: 'pastMonth',
  },
  {
    key: 'customRange',
    label: 'customRange',
  }
];

export const FieldFilterByDate = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('filterByDate')}
      name={'filterByDate'}
      size='sm'
      component={Select}
      componentProps={{
        placeholder: t('all'),
        options: filterByDateOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        }))
      }}
    />
  );
};

const filterMessageStatusOptions: SelectOption<FilterMessageStatusValues>[] = [
  {
    key: 'newSeeker',
    label: 'newSeeker',
  },
  {
    key: 'unread',
    label: 'unread',
  }
];

export const FieldMessageStatus = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('messageStatus')}
      name='messageStatus'
      size='sm'
      component={Select}
      componentProps={{
        placeholder: t('all'),
        options: filterMessageStatusOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        }))
      }}
    />
  );
};

const filterProfileStatusOptions: SelectOption<FilterProfileStatusValues>[] = [
  {
    key: 'active',
    label: 'active',
  },
  {
    key: 'pending',
    label: 'pending',
  },
  {
    key: 'denied',
    label: 'denied',
  },
  {
    key: 'deactivated',
    label: 'deactivated',
  },
];

export const FieldProfileStatus = () => {
  const { t } = useTranslation();

  return (
    <FormField
      labelText={t('statuses')}
      name='profileStatus'
      size='sm'
      component={Select}
      componentProps={{
        placeholder: t('all'),
        options: filterProfileStatusOptions.map((item) => ({
          key: item.key,
          label: t(item.label)
        }))
      }}
    />
  );
};
