import React, { useState } from 'react';

import { Loader } from '../index';

type ImageProps = {
  src: string,
  title: string,
};

const Image: React.FC<ImageProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <img
        src={props.src}
        alt={props.title}
        style={{ display: isLoading ? 'none' : '', }}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
      {isLoading && <Loader style={{ margin: 'auto', }} />}
    </>
  );
};

export default Image;
