import React, { forwardRef, useRef, useState } from 'react';

import { Badge, Button, FontIconView, Row } from '../../index';
import Scroll from '../../scroll/Scroll';
import {getMessageListElements} from './MessageList.utils';
import {MESSAGE_LIST_MESSAGES_ELEMENTS_WRAPPER_ID} from './MessageList.constants';
import AeFontIcons from '../../../assets/Icons';

import './MessageList.scss';

type MessageListProps = {
  onScrollStop?: () => void,
  unreadCount?: number,
  isVetterWatchesChat?: boolean,
  children: React.ReactNode,
  onScrollFrame?: (e: number) => void, 
};

const MessageList = forwardRef<any, MessageListProps>((props, ref) => {
  const scrollRef = useRef<any | null>(null);
  const [showToBottom, setShowToBottom] = useState(false);

  const scrollToBottom = () => {
    scrollRef.current?.scrollToBottom();
  };

  return (
    <Scroll
      autoHide
      ref={(el) => {
        if (el) {
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            ref.current = el;
          }

          scrollRef.current = el;
        }
      }}
      onScrollFrame={({ scrollTop, clientHeight, scrollHeight }) => {
        
        if (props.onScrollFrame) {
          props.onScrollFrame(scrollHeight);
        }
        
        // [AE-548] if the conversation is upper than the last message I can see a down-arrow icon
        const messagesChildren = getMessageListElements()?.messagesElements;

        if (messagesChildren) {
          const lastMessageElement = messagesChildren[messagesChildren.length - 1];

          if (lastMessageElement) {
            const newValue = scrollHeight > clientHeight
              && (
                scrollTop < (scrollHeight - clientHeight - lastMessageElement.clientHeight)
              );

            if (newValue !== showToBottom) {
              setShowToBottom(newValue);
            }
          }
        }
      }}
      onScrollStop={props.onScrollStop}
    >
      <Row
        gap='md'
        paddingHorizontal='md'
        paddingVertical='md'
        id={MESSAGE_LIST_MESSAGES_ELEMENTS_WRAPPER_ID}
      >
        {showToBottom && (
          <div className='message-list_scroll-down' key='sd'>
            <Button
              color='blue'
              variant='transparent'
              iconAfter={<FontIconView icon={AeFontIcons.arrowLongRight} />}
              onClick={scrollToBottom}
            >{(props.unreadCount && !props.isVetterWatchesChat) 
                ? <Badge className='message-list_badge-unread'>{props.unreadCount}</Badge>
                : ''}</Button>
          </div>
        )}
        {props.children}
      </Row>
    </Scroll>
  );
});

export default MessageList;
