import {apiRequest} from './api';
import {ReminderItemTypeApi} from './reminder.types';
import {normalizeReminders} from './reminder.utils';
import { ApiParamUserData } from './api.types';

// Add a new reminder for evangelist and seeker
export const addReminderRequest = (userData: ApiParamUserData, reminder: {
  title: string,
  schedule: string,
  requestId?: string | null,
  seekerId?: string,
}) => {
  return apiRequest<{
    reminderId: number
  }>('setReminder', {
    id: userData.id,
    agentToken: userData.agentToken,
    ...reminder
  });
};

// Add a new reminder for evangelist and seeker
export const getRemindersRequest = (userData: ApiParamUserData) => {
  return apiRequest<{
    reminders: ReminderItemTypeApi[]
  }>('getReminders', {
    id: userData.id,
    agentToken: userData.agentToken,
  }).then((response) => normalizeReminders(response.reminders));
};

export type ModifyReminderRequestReminder =
  Pick<ReminderItemTypeApi, 'reminderId'>
  & Partial<Pick<ReminderItemTypeApi, 'title' | 'schedule'>> & {
  action: 'edit' | 'delete'
};

// Edit or delete a reminder for evangelist and seeker
export const modifyRemindersRequest = (
  userData: ApiParamUserData, reminder: ModifyReminderRequestReminder
) => {
  return apiRequest('modifyReminder', {
    id: userData.id,
    agentToken: userData.agentToken,
    ...reminder
  });
};
