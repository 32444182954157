import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useMainContext } from '../MainProvider';
import {
  getAvailableLanguagesRequest,
  fetchUserLanguages,
  modifyUserLanguagesRequest,
  RequestModifyLanguagesActions,
} from '../api/languages';
import { Language } from '../api/languages.types';
import { useGetByIdWithCache } from '../hooks/useCache';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { PromiseResolveValue, SetQueryDataFnTyped } from '../types';
import { useSetI18Language } from '../hooks/useLanguage';
import { fetchTreeLanguagesRequest } from '../api/treeLanguages';
import { TreeLanguageItem } from '../api/treeLanguages.types';

export const QUERY_KEY_AVAILABLE_LANGUAGES = 'availableLanguages';
export const QUERY_KEY_USER_LANGUAGES = 'userLanguages';
export const QUERY_KEY_TREE_LANGUAGES = 'treeLanguages';

const allLanguagesDefault: {
  languages: Language[];
  requestLanguages: Language[];
} = {
  languages: [],
  requestLanguages: [],
};

const getAllLanguagesItemLanguageLabel = (item: Language) => item.language;

export const useGetAvailableLanguages = () => {
  const {
    data: {languages, requestLanguages} = allLanguagesDefault,
    isLoading
  } = useQuery(QUERY_KEY_AVAILABLE_LANGUAGES, getAvailableLanguagesRequest, {
    enabled: false // is loaded in {MainProvider} on the app start
  });

  const getAvailableLanguageByLanguage = useGetByIdWithCache(
    QUERY_KEY_AVAILABLE_LANGUAGES + 'languages',
    languages,
    getAllLanguagesItemLanguageLabel
  );

  const getAvailableRequestLanguageByLanguage = useGetByIdWithCache(
    QUERY_KEY_AVAILABLE_LANGUAGES + 'requestLanguages',
    requestLanguages,
    getAllLanguagesItemLanguageLabel
  );

  return {
    isAvailableLanguagesLoading: isLoading,
    availableLanguages: languages,
    availableRequestLanguages: requestLanguages,
    getAvailableRequestLanguageByLanguage,
    getAvailableLanguageByLanguage,
  };
};

export const setQueryDataUserLanguages: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof fetchUserLanguages>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_USER_LANGUAGES, cb);
};

const userLanguagesDefault: {
  languages: string[]
  primaryLanguage: string
} = {
  languages: [],
  primaryLanguage: ''
};

export const useGetUserLanguages = (options?: {
  staleTime: number,
}) => {
  const { userData } = useMainContext();

  const {
    data = userLanguagesDefault,
    isLoading
  } = useQuery(QUERY_KEY_USER_LANGUAGES, () => fetchUserLanguages(userData), {
    enabled: false, // is loaded in {MainProvider} the same time as the {profileData}
    ...options
  });

  return {
    isUserLanguagesLoading: isLoading,
    userLanguages: data
  };
};

export const useModifyUserLanguages = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();
  const { setI18Language } = useSetI18Language();

  const modifyUserLanguagesMutation = useMutation(
    (data: {
      action: RequestModifyLanguagesActions;
      language: string | string[];
    }) => {
      return modifyUserLanguagesRequest(userData, data.action, data.language);
    },
    {
      onSuccess: async (response, payloadMutation) => {
        setQueryDataUserLanguages(queryClient, (oldData) => {
          if (oldData) {
            const newData = { ...oldData };

            const payloadMutationLanguage = payloadMutation.language;
            const languagesParsed = typeof payloadMutationLanguage === 'string'
              ? [payloadMutationLanguage]
              : payloadMutationLanguage;

            if (payloadMutation.action === 'add') {
              newData.languages = [...oldData.languages, ...languagesParsed];
            } else if (payloadMutation.action === 'delete') {
              newData.languages = oldData.languages.filter(
                (language) => !languagesParsed.includes(language)
              );
            } else if (payloadMutation.action === 'primary') {
              newData.primaryLanguage = languagesParsed.pop() || oldData.primaryLanguage;
              setI18Language(newData.primaryLanguage);
            }

            return newData;
          }

          queryClient.invalidateQueries(QUERY_KEY_USER_LANGUAGES, {
            inactive: true
          });

          return oldData;
        }, undefined);

        showToast({ title: t('changesSuccess') }, {
          toastId: QUERY_KEY_USER_LANGUAGES
        });
      }
    }
  );

  return {
    modifyUserLanguagesMutation,
  };
};

const treeLanguagesDefault: TreeLanguageItem[] = [];

export const useGetTreeLanguages = () => {
  const { userData } = useMainContext();

  const {
    data = treeLanguagesDefault,
  } = useQuery(QUERY_KEY_TREE_LANGUAGES, () => fetchTreeLanguagesRequest(userData), {
    cacheTime: 1000 * 60 * 60,//1 hour
  });

  return { treeLanguages: data };
};
