import {v4 as uuidv4} from 'uuid';

export const getIsMatchesCssMediaHover = () => {
  return (
    window.matchMedia &&
    window.matchMedia('(hover: hover) and (pointer: fine)').matches
  );
};

export const getIsDocumentHidden = () => {
  // @ts-ignore
  return document.webkitHidden || document.msHidden || document.hidden;
};

export const canHover = getIsMatchesCssMediaHover();


const getAppId = (): string => {
  const appIdKey = 'appId';

  const appIdStored = localStorage.getItem(appIdKey);

  if (appIdStored) {
    return appIdStored;
  }

  const appIdNew = 'web-' + uuidv4();

  localStorage.setItem(appIdKey, appIdNew);

  return appIdNew;
};

export const appId = getAppId();

// "en-us" -> "en"
export const getLangPartUntilDash = (language: string) => {
  return language?.split('-')[0];
};
