import { useMainContext } from '../MainProvider';
import { useQuery } from 'react-query';
import { getAchievementsRequest } from '../api/account';
import { useGetByIdWithCache } from '../hooks/useCache';
import { Achievement } from '../components/achievements/types';
import { PromiseResolveValue, SetQueryDataFnTyped, UpdateQueryDataFn } from '../types';

export const QUERY_KEY_ACHIEVEMENTS = 'achievements';

const setQueryDataAchievements: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getAchievementsRequest>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_ACHIEVEMENTS, cb);
};

export const updateAchievementItemByKeyName: UpdateQueryDataFn<{
  keyName: string;
  newProperties: Partial<Achievement>;
}> = (queryClient, options) => {
  setQueryDataAchievements(queryClient, (oldData) => {
    if (oldData) {
      const { keyName } = options;
      return oldData.map((item) => {
        if (item.keyName === keyName) {
          return {
            ...item,
            ...options.newProperties,
          };
        }

        return item;
      });
    }

    return [];
  }, undefined);
};

const achievementsDefault: [] = [];

const getAchievementKeyName = (item: Achievement) => item.keyName;

export const useGetAchievements = () => {
  const { userData } = useMainContext();

  const {
    data = achievementsDefault,
    isLoading,
    isError,
    refetch,
  } = useQuery(QUERY_KEY_ACHIEVEMENTS, () => getAchievementsRequest(userData));

  const get = useGetByIdWithCache(QUERY_KEY_ACHIEVEMENTS, data, getAchievementKeyName);

  return {
    isAchievementsLoading: isLoading,
    isAchievementsError: isError,
    achievements: data,
    getAchievementByKeyName: get,
    refetchAchievements: refetch,
  };
};
