import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as queryString from 'querystring';
import { useTranslation } from 'react-i18next';

import FormRegister from './FormRegister';
import {
  AuthAccountsTypes,
  registerAccountTypeCM,
  registerAccountTypeDM,
  URLS,
} from '../../../utils/constants';
import { Typography } from '../../../components';
import Row from '../../../components/row/Row';
import AuthContainer from '../AuthContainer';

const PageRegister: React.FC<RouteComponentProps> = ({ location }) => {
  const { t } = useTranslation();
  const profileType = (
    queryString.parse(location.search.slice(1)).type as AuthAccountsTypes || registerAccountTypeDM
  );

  const isDM = profileType === registerAccountTypeDM;
  const isCM = profileType === registerAccountTypeCM;

  let signUpAs = '';

  if (isDM) {
    signUpAs = t('missionary');
  } else if (isCM) {
    signUpAs = t('campaignManager');
  }

  return (
    <AuthContainer>
      <Row gap='extra-lg'>
        <Typography component='h2' variant='poppins'>{t('signUpAs')} {signUpAs}</Typography>

        <div>
          <span>{t('haveAccount')}</span>&nbsp;
          <Link to={URLS.login}>{t('signIn')}</Link>
        </div>

        <FormRegister agentType={profileType} />
      </Row>
    </AuthContainer>
  );
};

export default PageRegister;
