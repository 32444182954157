import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../index';
import { RoleStatus } from '../../utils/enums';
import { useStatusAndStatusColor } from '../../hooks/useStatusAndStatusColor';

interface LabelProfileStatusProps {
  status: RoleStatus;
}

const LabelProfileStatus: React.FC<LabelProfileStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const getStatusAndStatusColor = useStatusAndStatusColor();

  const { statusText, statusTextColor } = getStatusAndStatusColor(status);

  return (
    <Typography component='body3' color='text-title'>
      {t('status')}:&nbsp;
      <Typography color={statusTextColor}><b>{t(statusText)}</b></Typography>
    </Typography>
  );
};

export default LabelProfileStatus;
