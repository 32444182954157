import {useMemo} from 'react';

type CacheKey = any[];

const cachesHolder = new WeakMap<CacheKey, any>();

function _getCache<T>(cacheKey: CacheKey, defaultValue: T): T {
  let out: T;

  if (cachesHolder.has(cacheKey)) {
    out = cachesHolder.get(cacheKey)!;
  } else {
    out = defaultValue;
    cachesHolder.set(cacheKey, out);
  }

  return out;
}

function _updateCache<T>(cacheKey: CacheKey, newData: Partial<T>) {
  cachesHolder.set(cacheKey, newData);
}

export const useCache = () => {
  return {
    get: _getCache,
    update: _updateCache,
  };
};

type ItemID = string

export type GetByIdWithCacheFn<T> = (id: ItemID) => T | undefined

type GetByIdWithCacheData<T> = Record<ItemID, T>;

type GetIdFunction<T extends { [key in string]: any } = {}> = (item: T) => ItemID

const INDEX_LAST_ITERATION_DEFAULT = -1;

// @ts-ignore
const getIdFunctionDefault: GetIdFunction = (item) => item.id || '';

type GetByIdCacheValue<T> = {
  itemsForMap: T[],
  mapIdToItem: GetByIdWithCacheData<T>,
  lastIterationIndex: number,
};

// Creates, fills, with optimization, and caches the map object { [item.id]: item }.
export function useGetByIdWithCache<T extends { [key in string]: any }>(
  _cacheKey: CacheKey | string, items: T[] | undefined, getIdFunction: GetIdFunction<T> = getIdFunctionDefault,
) {
  const cacheKey = typeof _cacheKey === 'string' ? [_cacheKey] : _cacheKey;

  type CacheValueType = GetByIdCacheValue<T>;
  const valueDefault = {
    itemsForMap: items || [],
    mapIdToItem: {},
    lastIterationIndex: INDEX_LAST_ITERATION_DEFAULT,
  };
  const cache = useCache();

  const getCacheValue = () => cache.get<CacheValueType>(cacheKey, valueDefault);

  const cacheItem = (id: ItemID, item: T, index: number) => {
    const cacheValue = getCacheValue();

    _updateCache<CacheValueType>(cacheKey, {
      ...cacheValue,
      mapIdToItem: {
        ...cacheValue.mapIdToItem,
        [id]: item,
      },
      lastIterationIndex: index,
    });
  };

  const findSaveReturnOrReturn: GetByIdWithCacheFn<T> = useMemo(() => {
    const cacheValue = getCacheValue();

    if (cacheValue.itemsForMap && cacheValue.itemsForMap !== items) {
      _updateCache(cacheKey, valueDefault);
    }

    return (id) => {
      const cachedValue = cacheValue.mapIdToItem[id];
      if (cachedValue) {
        return cachedValue;
      }

      let result;
      let item;


      let lastIterationIndexNew = cacheValue.lastIterationIndex + 1;

      if (items) {
        for (; lastIterationIndexNew < items.length; lastIterationIndexNew++) {
          item = items[lastIterationIndexNew];

          const itemId = getIdFunction(item);

          cacheItem(itemId, item, lastIterationIndexNew);

          if (itemId === id) {
            result = item;
            break;
          }
        }

        _updateCache<CacheValueType>(cacheKey, {
          ...cacheValue,
          lastIterationIndex: lastIterationIndexNew,
        });
      }

      return result;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return findSaveReturnOrReturn;
}
