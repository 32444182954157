import { GetRequestCategoriesCategory, RequestCategoryItemType } from './request.types';

export const normalizeRequestCategoryItem = (
  item: GetRequestCategoriesCategory
): RequestCategoryItemType => {
  return {
    ...item,
    name: item.name.toLowerCase(),
  };
};
