import {useEffect} from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import {v4 as uuidv4} from 'uuid';

import {
  pushToMessageHistory,
  updateChatItem, updateMessageHistoryWithIdsOrIndexes
} from '../query/chat';
import {useMainContext} from '../MainProvider';
import {
  MessageFromEvangelistDestType,
  MessageItemType,
  MessageReviewStatus,
  SourceDestTypes
} from '../api/chat.types';
import {QUERY_KEY_REQUESTS} from '../query/request';
import {QUERY_KEY_PRAYER} from '../query/prayer';
import {QUERY_KEY_REMINDERS} from '../query/reminder';
import {getWebNotificationKeyRequest, setNotificationTokenRequest} from '../api/account';
import { getMessageUpdatesRequest } from '../api/chat';
import { PushNotificationItemType, useNotificationContext } from '../PushNotificationsProvider';
import { AWRUserId, URLS } from '../utils/constants';
import {
  makeDateFormatApi,
  REG_EXP_MESSAGE_ATTACHMENT,
  REG_EXP_MESSAGE_VOICE,
} from '../utils/chat';
import {appId} from '../utils/user-agent';
import { toastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import i18n from 'i18next';
import AeFontIcons from '../assets/Icons';
import {
  deleteChatListCampaignItemsById,
  deleteMyCampaignsById,
  getQueryDataListCampaigns,
  QUERY_KEY_CAMPAIGN_MEMBERS,
  QUERY_KEY_CHAT_LIST_CAMPAIGNS,
  QUERY_KEY_MY_CAMPAIGNS,
  updateMyCampaignsItemById
} from '../query/campaigns';
import { QUERY_KEY_CHAT_LIST_LEADERS } from '../query/leaders';
import { QUERY_KEY_SEEKERS_LIST, removeSeekerListItemsByIds } from '../query/seekers';
import {
  getQueryDataEvangelists,
  QUERY_KEY_CHAT_LIST_EVANGELISTS,
  QUERY_KEY_QUICK_EVANGELISTS,
} from '../query/evangelists';
import {
  getQueryKeyStudentCommand,
  getQueryKeyStudentHistory,
  QUERY_KEY_STUDENTS
} from '../query/students';
import { ApiParamUserData } from '../api/api.types';
import { StorageManagerSetFn } from './useStorageManager';
import { updateAchievementItemByKeyName } from '../query/achievements';
import { updateCourseItemById } from '../query/courses';
import { makeLabelFullName } from '../utils/common';
import { Role } from '../utils/enums';

type NotificationNewMessageStatus = {
  event: 'sent' | 'viewed'
  messageId: string
  msgType: 'event'
  seekerId: string
  sourceType: MessageFromEvangelistDestType
}

type NotificationUpdateReview = {
  event: 'msgStatus'
  destId: string
  msgId: string
  msgNote: string
  msgScore: string
  msgStatus?: MessageReviewStatus
}

type NotificationAchievementUpdate = {
  event: 'achievement'
  keyName: string
  progress: number
}

type NotificationCourseUpdate = {
  event: 'training'
  trainingId: string
  totalProgress: string
}

type NotificationTransferAway = {
  event: 'transferAway'
  seekerId: string
}

type NotificationCampaignOfLeaderDm = {
  event: 'campaignChange'
  action: 'add' | 'delete'
  evangelistId: string
  campaignId?: string
}

type NotificationCreateUser = {
  event: 'leader' | 'manager'
  action: 'create'
  role?: Role
  evangelistId?: number
}

type NotificationNewMessage = {
  chatStatus: '' | 'muted'
  evangelistId: string
  msgId: string
  msgType: 'message' 
    | 'reminder' 
    | 'notification' 
    | 'request-prayer' 
    | 'request-study' 
    | 'request-question'
  seekerId: string
  requestId?: string // only for request messages
  sourceType: MessageFromEvangelistDestType
  text: string
  title: string
}

type NotificationLeaderUpdate = {
  msgType: 'leaderUpdate'
  updateId: string
}

type NotificationCampaign = {
  action: 'deactivateCampaign' | 'activateCampaign' | 'removeCampaign' | 'addCampaign'
  campaignId?: number
  event: 'campaign'
}

type NotificationStudent = {
  event: 'student',
  seekerId: string
}

type NotificationMessageData = NotificationNewMessageStatus
  | NotificationUpdateReview
  | NotificationNewMessage
  | NotificationLeaderUpdate
  | NotificationCampaign
  | NotificationAchievementUpdate
  | NotificationCourseUpdate
  | NotificationTransferAway
  | NotificationCreateUser
  | NotificationCampaignOfLeaderDm
  | NotificationStudent;

const postMessageOfConfigToSW = (isUiRoleDM: boolean, areNotificationsSilent: boolean) => {
  window.navigator.serviceWorker.controller?.postMessage({
    config: {
      isUserLeader: !isUiRoleDM,
      areNotificationsSilent,

      regExpVoice: REG_EXP_MESSAGE_VOICE,
      regExpAttachments: REG_EXP_MESSAGE_ATTACHMENT,

      routeChatSeekers: URLS.seekers,
      routeChatInactiveSeekers: URLS.seekersInactive,
      routeChatCampaigns: URLS.campaignChats,
      routeChatLeaders: URLS.leaderChats,
      routeChatMissionaries: URLS.evangelistChats,

      EVANGELIST: SourceDestTypes.EVANGELIST,
      SEEKER: SourceDestTypes.SEEKER,
      CAMPAIGN: SourceDestTypes.CAMPAIGN,
      TRANSFER: SourceDestTypes.TRANSFER,
      LEADER: SourceDestTypes.LEADER,
    },
  });
};

const createHandlerSWMessage = (
  queryClient: QueryClient,
  setNotificationsList: StorageManagerSetFn<PushNotificationItemType[]>,
  userData: ApiParamUserData,
  isUiRoleDM: boolean,
  uiRole: Role,
  logout: () => void,
) => {
  return async (event: MessageEvent<NotificationMessageData>) => {
    if ('msgType' in event.data) {
      const msgType = event.data.msgType;

      if (
        msgType === 'reminder'
        || msgType === 'notification'
        || msgType === 'message'
        || msgType === 'request-study'
        || msgType === 'request-prayer'
        || msgType === 'request-question'
      ) {
        const notificationMessage = event.data;
        const date = makeDateFormatApi();

        setNotificationsList((oldData) => {
          const newItem = {
            id: uuidv4(),
            messageId: notificationMessage.msgId,
            title: notificationMessage.title,
            text: notificationMessage.text,
            sourceId: notificationMessage.seekerId,
            sourceType: notificationMessage.sourceType,
            date,
            viewed: false,
          };

          if (oldData) {
            return [newItem, ...oldData];
          }

          return [newItem];
        });

        if (msgType === 'reminder') {
          queryClient.invalidateQueries(QUERY_KEY_REMINDERS);

          // Handle general reminders
          if (notificationMessage.seekerId === AWRUserId) {
            const title = i18n.t('reminder');

            toastEnhanced({
              iconBeforeTitle: AeFontIcons.reminder,
              title,
              body: notificationMessage.text,
            }, {
              titleTextLength: title.length,
              type: 'info',
              autoClose: false,
            });
          }
          // =====
        } else if (msgType === 'request-prayer') {
          queryClient.invalidateQueries(QUERY_KEY_PRAYER);
        }

        if (msgType.includes('request')) {
          queryClient.invalidateQueries(QUERY_KEY_REQUESTS);
        }

        let messages: MessageItemType[] = [];
        try {
          const response = await getMessageUpdatesRequest(userData, {
            messageIds: [notificationMessage.msgId],
          }, event.data.evangelistId);
          messages = response.messages;
        } catch (error) {
          return;
        }

        if (messages[0]) {
          // --- Update message logic --- //
          const isRoleAdmin = uiRole === Role.ADMIN;
          const recipientId = isRoleAdmin
            ? notificationMessage.evangelistId
            : notificationMessage.seekerId;
          const { status } = pushToMessageHistory(queryClient, {
            recipientId,
            message: messages[0],
          });

          // --- Update chat item logic --- //
          const isCanIncreaseCounter = status !== 'fail/already-exists';
          updateChatItem(queryClient, {
            filter: recipientId,
            recipientType: notificationMessage.sourceType,
            seekersListStatus: 'active',
            isUiRoleNotDM: !isUiRoleDM,
            newProperties: {
              text: notificationMessage.text,
              date
            },
            messagesCountsFactors: {
              unread: isCanIncreaseCounter ? 1 : 0,
              incoming: isCanIncreaseCounter ? 1 : 0
            },
            isRoleAdmin,
          });
          //If seeker sent a message then he is automatically activated and should be removed
          //from inactive list
          removeSeekerListItemsByIds(queryClient, {
            ids: [event.data.seekerId],
            isUiRoleLeader: !isUiRoleDM,
            seekerListStatus: 'inactive',
          });
        }
      }

      if (msgType === 'leaderUpdate') {
        if (isUiRoleDM) {
          toastEnhanced({
            title: i18n.t('updatesInLeaderMode'),
          });
        } else {
          // TODO: May be implement getLeaderUpdatesRequest as in the mobile app.
        }
      }
    }

    if ('event' in event.data) {
      const msgEvent = event.data.event;

      if (msgEvent === 'msgStatus') {
        // destId is seekerId in this context
        const { destId, msgId, msgNote, msgScore, msgStatus } = event.data;
        const newProperties: Partial<MessageItemType> = {};
        if (msgNote) {
          newProperties.msgNotes = msgNote;
        }
        if (msgScore) {
          newProperties.msgScore = msgScore;
        }
        if (msgStatus) {
          newProperties.msgStatus = msgStatus;
        }
        updateMessageHistoryWithIdsOrIndexes(queryClient, {
          recipientId: destId,
          messagesIds: [msgId],
          newProperties,
        });
      }

      if (
        msgEvent === 'sent'
        || msgEvent === 'viewed'
      ) {
        const notificationMessage = event.data;

        updateMessageHistoryWithIdsOrIndexes(queryClient, {
          recipientId: notificationMessage.seekerId,
          messagesIds: [notificationMessage.messageId],
          newProperties: {
            status: notificationMessage.event,
          },
        });

        // FIXME: Any broadcast message is in broadcast and private seeker chats.
        //  We can't determine what broadcast this message is refer to,
        //  so the status in broadcast chat can't be updated.
      }

      if (msgEvent === 'achievement') {
        const { keyName, progress } = event.data;
        updateAchievementItemByKeyName(queryClient, {
          keyName,
          newProperties: { progress: Number(progress) }
        });
      }

      if (msgEvent === 'training') {
        const { trainingId, totalProgress } = event.data;
        updateCourseItemById(queryClient, { 
          trainingId, 
          newProperties: { progress: Number(totalProgress) } 
        });
      }

      if (msgEvent === 'transferAway') {
        const { seekerId } = event.data;
        removeSeekerListItemsByIds(queryClient, {
          ids: [seekerId],
          isUiRoleLeader: !isUiRoleDM,
          seekerListStatus: 'active',
        });
        removeSeekerListItemsByIds(queryClient, {
          ids: [seekerId],
          isUiRoleLeader: !isUiRoleDM,
          seekerListStatus: 'inactive',
        });
      }
      
      if (msgEvent === 'campaignChange' && !isUiRoleDM) {
        const { action, evangelistId, campaignId } = event.data;
        queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_EVANGELISTS);
        queryClient.invalidateQueries([QUERY_KEY_CAMPAIGN_MEMBERS, campaignId]);
        if (action === 'delete') {
          let campaigns = getQueryDataListCampaigns(queryClient, uiRole);
          const evangelists = getQueryDataEvangelists(queryClient);
          const evangelistIdNumber = Number(evangelistId);
          const foundCampaign = campaigns?.find((item) => item.id === campaignId);
          const foundEvangelist = evangelists?.find((item) => item.id === evangelistIdNumber);
          if (foundCampaign && foundEvangelist) {
            toastEnhanced({ title: i18n.t('dmLeftTeam', 
              {
                userName: makeLabelFullName(foundEvangelist),
                teamName: foundCampaign.name,
              }) 
            });
          }
        }
      }

      if (msgEvent === 'student') {
        const { seekerId: studentId } = event.data;
        queryClient.invalidateQueries(QUERY_KEY_STUDENTS);
        queryClient.invalidateQueries(getQueryKeyStudentHistory(studentId));
        queryClient.invalidateQueries(getQueryKeyStudentCommand(studentId));
      }
    }

    if ('action' in event.data) {
      const { action } = event.data;

      if (action === 'activateCampaign') {
        const { campaignId } = event.data;
        const campaignIdString = campaignId?.toString() || '';
        updateMyCampaignsItemById(queryClient, {
          id: campaignIdString,
          newProperties: { active: '1', },
        });
        queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_CAMPAIGNS);
      }

      if (action === 'deactivateCampaign') {
        const { campaignId } = event.data;
        const campaignIdString = campaignId?.toString() || '';
        updateMyCampaignsItemById(queryClient, {
          id: campaignIdString,
          newProperties: { active: '0', },
        });
        deleteChatListCampaignItemsById(queryClient, { campaignIds: [campaignIdString], });
      }

      if (action === 'addCampaign') {
        queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_CAMPAIGNS);
        queryClient.invalidateQueries(QUERY_KEY_MY_CAMPAIGNS);
      }

      if (action === 'removeCampaign') {
        const { campaignId } = event.data;
        const campaignIdString = campaignId?.toString() || '';
        deleteMyCampaignsById(queryClient, { campaignIds: [campaignIdString] });
        deleteChatListCampaignItemsById(queryClient, { campaignIds: [campaignIdString], });
      }

      if (action === 'create') {
        toastEnhanced({
          title: i18n.t('newEvangelistCreated', { evangelist: event.data.event }),
        });
        queryClient.invalidateQueries(QUERY_KEY_QUICK_EVANGELISTS);
      }

      if (action === 'delete') {
        logout();
      }

      queryClient.invalidateQueries(QUERY_KEY_SEEKERS_LIST);

      if (isUiRoleDM) {
        queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_LEADERS);
      } else {
        queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_EVANGELISTS);
      }
    }
  };
};

const usePushNotifications = () => {
  const queryClient = useQueryClient();

  const { 
    userData,
    uiRole,
    isUiRoleDM,
    isAuth,
    isInitDataLoaded,
    isRoleAdmin,
    logout
  } = useMainContext();

  const {
    isNotificationsInit,
    isPushPermissionGranted,

    setIsAppReceivesNotifications,

    areNotificationsEnabled,
    areNotificationsSilent,

    setNotificationsList,
  } = useNotificationContext();

  useEffect(() => {
    let handleSWMessage: (e: MessageEvent) => void;

    if (isInitDataLoaded) {
      const handleReadyToListenNotifications = (wasSubscribed: boolean) => {
        postMessageOfConfigToSW(isUiRoleDM, areNotificationsSilent);

        handleSWMessage = createHandlerSWMessage(
          queryClient, setNotificationsList, userData, isUiRoleDM, uiRole, logout,
        );

        window.navigator.serviceWorker.addEventListener('message', handleSWMessage);

        // eslint-disable-next-line no-console
        console.log(
          '%c🔔 Notifications: '
          + (wasSubscribed ? 'listening old subscription' : 'subscribed anew'),
          'background: blue; color: white;'
        );
      };

      if (isNotificationsInit && isPushPermissionGranted) {
        window.navigator.serviceWorker.ready.then(async (result) => {
          let isSubscribedToPushes = false;

          if (result.pushManager) {
            const subscription = await result.pushManager.getSubscription();

            if (!isAuth || !areNotificationsEnabled) {
              if (subscription) {
                subscription.unsubscribe().then(() => {
                  // eslint-disable-next-line no-console
                  console.log('%c🔕 Notifications: unsubscribed', 'background: blue; color: white;');
                });
              }
            } else {
              const handleSubscribeSuccess = (wasSubscribed: boolean) => {
                isSubscribedToPushes = true;
                handleReadyToListenNotifications(wasSubscribed);
              };

              if (subscription) {
                handleSubscribeSuccess(true);
              } else {
                const { key } = await getWebNotificationKeyRequest(userData);
                
                if (key) {
                  try {
                    const subscription = await result.pushManager.subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: key,
                    });

                    await setNotificationTokenRequest(userData, appId, subscription, isRoleAdmin)
                      .then(() => {
                        handleSubscribeSuccess(false);
                      });
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(
                      '%cError subscribe to push service with the key below!',
                      'background: red; color: white;', {
                        key,
                        error,
                      });
                  }
                }
              }
            }
          }

          setIsAppReceivesNotifications(isSubscribedToPushes);
        });
      } else {
        setIsAppReceivesNotifications(false);
      }
    }

    return () => {
      window.navigator.serviceWorker?.removeEventListener('message', handleSWMessage);
    };
  }, [
    isInitDataLoaded,
    isNotificationsInit,
    isPushPermissionGranted,
    areNotificationsEnabled,
    setNotificationsList,
    setIsAppReceivesNotifications,
    userData,
    isAuth,
    isUiRoleDM,
    queryClient,
    userData.id,
    areNotificationsSilent,
    isRoleAdmin,
  ]);
};

export default usePushNotifications;
