import { apiRequest } from './api';
import { ApiParamUserData } from './api.types';
import {
  CommandItem,
  CommandItemApi,
  CommandParamItem,
  HistoryItem,
  HistoryItemApi,
  StudentItemApi,
  StudyItemApi,
  STUDY_LIMIT,
  StudyLimitApi
} from './students.types';
import {
  normalizeCommandItem,
  normalizeHistoryItem,
  normalizeStudentChatItem,
  normalizeStudyItem,
  normalizeStudyLimitItem
} from './students.utils';

export const getStudentsRequest = async (userData: ApiParamUserData) => {
  return apiRequest<{ students: StudentItemApi[] }>('getStudentList', userData).then((response) =>
    response.students.map((item) => normalizeStudentChatItem(item))
  );
};

export const getStudyLimitsRequest = async (userData: ApiParamUserData) => {
  return apiRequest<StudyLimitApi>('getStudentHighlightInfo', userData).then((response) => {
    return {
      [STUDY_LIMIT.lastChat]: normalizeStudyLimitItem(response.lastChat),
      [STUDY_LIMIT.progress]: normalizeStudyLimitItem(response.progress),
      [STUDY_LIMIT.lastProgress]: normalizeStudyLimitItem(response.lastProgress)
    };
  });
};

export const getStudiesRequest = async (userData: ApiParamUserData) => {
  return apiRequest<{ studies: StudyItemApi[] }>('getStudyInfo', userData).then((response) =>
    response.studies.map((item) => normalizeStudyItem(item))
  );
};

export const getStudentHistoryRequest = async (userData: ApiParamUserData, studentId: string) => {
  return apiRequest<{ history: { [id: string]: HistoryItemApi[] } }>('getStudentHistory', {
    ...userData,
    seekerId: [studentId]
  }).then((response) => {
    let studentHistoryItems: HistoryItem[] = [];
    if (response.history[studentId]) {
      studentHistoryItems = response.history[studentId].map((item, index) =>
        normalizeHistoryItem(item, index)
      );
    }
    return studentHistoryItems;
  });
};

export const getStudentCommandsRequest = async (userData: ApiParamUserData, studentId: string) => {
  return apiRequest<{ commands: { [id: string]: CommandItemApi[] } }>('getStudentCommands', {
    ...userData,
    seekerId: [studentId]
  }).then((response) => {
    let studentCommandsItems: CommandItem[] = [];
    if (response.commands[studentId]) {
      studentCommandsItems = response.commands[studentId].map((item) => normalizeCommandItem(item));
    }
    return studentCommandsItems;
  });
};

export const executeStudentCommandRequest = async (
  userData: ApiParamUserData,
  options: { seekerId: string; command: string; param: CommandParamItem | [] }
) => {
  return apiRequest('sendStudentCommand', { ...userData, ...options });
};
