import { apiRequest } from './api';
import {
  APIAdminEvangelistItem,
  APIQuickAdminEvangelistItem,
  EvangelistItemType,
  LeaderStatsAPI,
  StatsMap
} from './evangelists.types';
import { ApiParamUserData } from './api.types';
import { Role, RoleStatus } from '../utils/enums';
import {
  normalizeChatItemAdminEvangelist,
  normalizeLeaderStatsMap,
  normalizeQuickAdminEvangelistItem
} from './evangelists.utils';

export const getLeaderEvangelistsRequest = (userData: ApiParamUserData, id?: string) => {
  const params: { id: string, vetterId?: string } = { id: id || userData.id };

  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }

  return apiRequest<{
    evangelists: EvangelistItemType[],
  }>('getLeaderEvangelists', {
    agentToken: userData.agentToken,
    ...params,
  }).then((res) => res.evangelists);
};

export const getEvangelistInfoRequest = (userData: ApiParamUserData, id?: string) => {
  const params: { id: string, vetterId?: string } = { id: userData.id };

  if (userData.role === Role.ADMIN && id) {
    params.id = id;
    params.vetterId = userData.id;
  }

  return apiRequest<{
    info: string,
  }>('getEvangelistInfo', {
    agentToken: userData.agentToken,
    ...params,
  }).then((response) => response.info);
};

export type EvangelistsRequestParams = {
  limit?: number,
  offset?: number,
};

// added limit to 2000 temporary according to DMT-526
export const getEvangelistsRequest = (userData: ApiParamUserData, params?: EvangelistsRequestParams) => {
  return apiRequest<{
    numEvangelists: number,
    evangelists: APIAdminEvangelistItem[],
  }>('getEvangelistList', {
    id: userData.id,
    agentToken: userData.agentToken,
    ...params,
  }).then((res) => ({
    ...res,
    evangelists: res.evangelists.map(normalizeChatItemAdminEvangelist),
  }));
};

export const getQuickEvangelistsRequest = (userData: ApiParamUserData) => {
  return apiRequest<{
    numEvangelists: number,
    evangelists: APIQuickAdminEvangelistItem[],
  }>('getEvangelistList', {
    id: userData.id,
    agentToken: userData.agentToken,
    quick: true,
  }).then((res) => ({
    ...res,
    evangelists: res.evangelists.map(normalizeQuickAdminEvangelistItem),
  }));
};

export type ModifyEvangelistStatusData = {
  id: string;
  status: RoleStatus;
  role?: Role;
}

export const modifyEvangelistStatusRequest = (
  userData: ApiParamUserData,
  data: ModifyEvangelistStatusData,
) => apiRequest('setEvangelistStatus', {
  vetterId: userData.id,
  agentToken: userData.agentToken,
  ...data,
});

export const deleteEvangelistRequest = (
  userData: ApiParamUserData,
  id: string,
) => apiRequest('deleteEvangelist', {
  id,
  agentToken: userData.agentToken,
});

type LeaderStatsParams = {
  leaderId: string[];
  agentToken: string;
  campaignId?: string;
}

export const getLeaderStatsRequest = (
  userData: ApiParamUserData,
  leaders: string[],
  campaignId?: string,
) => {
  const params: LeaderStatsParams = {
    leaderId: leaders,
    agentToken: userData.agentToken,
  };

  if (campaignId) {
    params.campaignId = campaignId;
  }

  return apiRequest<{
    stats: StatsMap<LeaderStatsAPI>
  }>('getLeaderStats', params).then((res) => normalizeLeaderStatsMap(res.stats));
};
