import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useMainContext } from '../MainProvider';
import {
  Prayer,
  getPrayerListRequest,
  PrayerListCategory,
  AddPrayerRequestValues,
  addPrayerRequest,
  EditPrayerRequestValues,
  editPrayerRequest,
  deletePrayerRequest,
  addPrayerListCategoryRequest,
  ModifyPrayerListCategoriesValues,
  deletePrayerListCategoryRequest
} from '../api/prayer';
import { useGetByIdWithCache } from '../hooks/useCache';
import { getPrayerListCategoriesRequest } from '../api/prayer';

export const QUERY_KEY_PRAYER_LIST_CATEGORIES = 'prayerListCategories';
export const QUERY_KEY_PRAYER = 'prayerList';

const prayerListCategoriesDefault: [] = [];
const prayerListDefault: [] = [];

const getPrayerListCategoryId = (item: PrayerListCategory) => item.categoryId;

export const useGetPrayerlistCategories = (config?: {
  enabled: boolean,
}) => {
  const { userData } = useMainContext();

  const {
    isLoading,
    data = prayerListCategoriesDefault,
  } = useQuery(QUERY_KEY_PRAYER_LIST_CATEGORIES, () => (
    getPrayerListCategoriesRequest(userData)
  ), config);

  const get =
    useGetByIdWithCache(QUERY_KEY_PRAYER_LIST_CATEGORIES, data, getPrayerListCategoryId);

  return {
    isPrayerListCategoriesLoading: isLoading,
    getPrayerListCategoryById: isLoading ? () => undefined : get,
    prayerListCategories: data
  };
};

const getPrayerId = (item: Prayer) => item.prayerId;

export const useGetPrayerList = () => {
  const { userData } = useMainContext();

  const {
    data = prayerListDefault,
    isLoading
  } = useQuery(QUERY_KEY_PRAYER, () => (
    getPrayerListRequest(userData)
  ));

  const get = useGetByIdWithCache(QUERY_KEY_PRAYER, data, getPrayerId);

  return {
    isPrayersLoading: isLoading,
    getPrayerById: isLoading ? () => undefined : get,
    prayers: data
  };
};

export const useModifyPrayerList = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();
  const { prayerListCategories: categories } = useGetPrayerlistCategories();

  const addPrayerMutation = useMutation((data: AddPrayerRequestValues[]) => {
    return addPrayerRequest(userData, data);
  }, {
    onSuccess: () => {
      // Note: 'invalidate' feasibly can be replaced with the new item pushing.
      queryClient.invalidateQueries(QUERY_KEY_PRAYER);

      showToast({ title: t('prayerAddedMsg') });
    }
  });

  const editPrayerMutation = useMutation((data: EditPrayerRequestValues[]) => {
    return editPrayerRequest(userData, data);
  }, {
    onSuccess: (_, mutationPayload) => {
      queryClient.setQueryData<Prayer[] | undefined>(QUERY_KEY_PRAYER, (old) => {
        if (old && categories.length) {
          const mutationPayloadCategoryIds: string[] = [];

          // Map [edited prayer id] to [edited prayer data]
          const prayerIdToNewValuesMap = mutationPayload.reduce((acc, item) => {
            if (item.categoryId) {
              mutationPayloadCategoryIds.push(item.categoryId);
            }

            acc[item.prayerId] = item;

            return acc;
          }, {} as {[field: string]: EditPrayerRequestValues});

          // Map [category id from mutation prayer items] to [category data]
          const categoryIdToCategoryMap = mutationPayloadCategoryIds.reduce((acc, categoryId) => {
            if (!acc[categoryId]) {
              const suitableCategory =
                categories.find((category) => category.categoryId === categoryId);

              if (suitableCategory) {
                acc[categoryId] = suitableCategory;
              }
            }

            return acc;
          }, {} as {[field: string]: PrayerListCategory});

          return old.map((item) => {
            const newItemData = prayerIdToNewValuesMap[item.prayerId];

            if (newItemData) {
              return {
                ...item,
                ...newItemData,
                category: categoryIdToCategoryMap[newItemData.categoryId || item.categoryId]
              };
            }

            return item;
          });
        }

        queryClient.invalidateQueries(QUERY_KEY_PRAYER);

        return old;
      });

      const isPray = mutationPayload.findIndex((item) => item.lastPrayed) !== -1;
      const toastMessage = isPray ? 'success' : 'changesSuccess';

      showToast({ title: t(toastMessage) });
    }
  });

  const deletePrayerMutation = useMutation((data: string[]) => {
    return deletePrayerRequest(userData, data);
  }, {
    onSuccess: (_, mutationData) => {
      queryClient.setQueryData<Prayer[] | undefined>(QUERY_KEY_PRAYER, (old) => {
        if (old) {
          return old.filter((item) => !mutationData.includes(item.prayerId));
        }

        queryClient.invalidateQueries(QUERY_KEY_PRAYER);

        return old;
      });
    }
  });

  return {
    addPrayerMutation,
    editPrayerMutation,
    deletePrayerMutation,
  };
};

export const useModifyPrayerListCategories = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();

  const addPrayerCategoryMutation = useMutation((data: ModifyPrayerListCategoriesValues[]) => {
    return addPrayerListCategoryRequest(userData, data);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY_PRAYER_LIST_CATEGORIES);
      showToast({ title: t('prayerCategoryAddedMsg') });
    }
  });

  const deletePrayerCategoryMutation = useMutation((data: ModifyPrayerListCategoriesValues[]) => {
    return deletePrayerListCategoryRequest(userData, data);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY_PRAYER_LIST_CATEGORIES);
      showToast({ title: t('prayerCategoryDeletedMsg') });
    }
  });

  return {
    addPrayerCategoryMutation,
    deletePrayerCategoryMutation
  };
};
