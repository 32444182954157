import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { formatDateToUiDate, toLocalDateString } from '../../../utils/time';
import {
  Button,
  Row,
  Typography,
  AudioPlayer,
  TextMore,
  Loader,
  Divider,
  FontIconView
} from '../../index';
import { Color, ColorScheme } from '../../../types';
import {
  getIsTextWithAttachments,
  getMessageAttachments,
  MessageAppearanceType,
  REG_EXP_MESSAGE_VOICE,
} from '../../../utils/chat';
import ImageTemplateItem from '../../../pages/knowledge/ImageTemplateItem';
import VideoTemplateItem from '../../../pages/knowledge/VideoTemplateItem';
import { useMainContext } from '../../../MainProvider';
import { fetchResourcesContent, Template } from '../../../api/resources';
import {useUiLanguage} from '../../../hooks/useLanguage';
import {MessageReviewStatus, MessageStatus} from '../../../api/chat.types';
import AeFontIcons from '../../../assets/Icons';
import { Tooltip } from '../../tooltip/Tooltip';
import { addQuotes } from '../../../utils/string';

import './MessageItem.scss';

const getTranslateStringBtnReview = (
  messageReviewStatus: MessageItemProps['messageReviewStatus']
) => {
  if (messageReviewStatus === 'verifying') {
    return 'waiting';
  }

  if (messageReviewStatus === 'denied') {
    return 'rejected';
  }

  return messageReviewStatus || '';
};

const getMessageColors = (messageType: MessageAppearanceType,  incoming: boolean) => {
  let bg: Color = 'white',
    headText: Color = 'green',
    headTextAfter: Color = 'text-title',
    text: Color = 'text-body',
    footText: Color = 'white',
    footTextAfter: Color = 'white',
    date: Color = 'gray',
    btnMore: Color = 'blue',
    divider: ColorScheme = 'gray-opacity';

  if (messageType === 'violet' && incoming) {
    bg = 'violet-opacity';
    headText = 'violet';
    date = 'text-title-light';
    divider = 'violet-opacity';
  } else if (messageType === 'blue' && incoming) {
    bg = 'blue-opacity';
    headText = 'blue';
    date = 'text-title-light';
    divider = 'blue-opacity';
  } else if (messageType === 'default') {
    if (incoming) {
      bg = 'lime';
      date = 'text-title-light';
    } else {
      footText = 'gray';
      footTextAfter = 'text-body';
    }
  } else if (messageType === 'request-closed') {
    bg = 'bg-light';
    headText = 'green';
    text = 'text-body';
    date = 'text-body';
    footText = 'gray';
    footTextAfter = 'text-body';
  } else {
    const isReminderOrNotification = messageType === 'reminder' || messageType === 'notification';

    if (messageType === 'request' || isReminderOrNotification) {
      bg = isReminderOrNotification ? 'chat-reminder' : 'green';
      headText = 'white';
      text = 'white';
      headText = 'white';
      headTextAfter = 'white';
      footText = 'white';
      footTextAfter = 'white';
      btnMore = 'white';
      date = isReminderOrNotification ? 'white-70' : 'white';
    }
  }

  return {
    bg,
    headText,
    headTextAfter,
    text,
    footText,
    footTextAfter,
    date,
    btnMore,
    divider,
  };
};

const TEMPLATE_HEIGHT = 164;

export interface MessageItemProps {
  id: string;
  canReviewOutgoing?: boolean;
  incoming: boolean;
  messageAppearance: MessageAppearanceType;

  replyText?: string;
  replyTextClearUrlified?: string;
  isReplyMessageQuote?: boolean;
  replyAudio?: string;
  isReplyRequestClosed?: boolean;
  replyRequestCategoryName?: string;

  name?: string;
  roleLabel?: string;

  replyName?: string;
  replyRoleLabel?: string;

  text: string;
  textClearUrlified: string;
  audio: string | null;
  date: string;
  requestCategoryName?: string;

  messageScore?: string | null;
  messageReviewStatus?: MessageReviewStatus;
  sendStatus?: MessageStatus;

  onClickRepliedMessage?: () => void;
  onClickReview?: () => void;
}

// TODO: Optimize.
const MessageItem: React.FC<MessageItemProps> = React.memo(({
  id,
  incoming,
  canReviewOutgoing,
  messageAppearance,

  isReplyMessageQuote,
  replyName,
  replyRoleLabel,
  replyText,
  replyTextClearUrlified,
  replyAudio,
  replyRequestCategoryName,
  isReplyRequestClosed,

  name,
  roleLabel,
  text,
  textClearUrlified,
  audio,
  date,
  requestCategoryName,

  messageScore,
  messageReviewStatus,
  sendStatus,

  onClickRepliedMessage,
  onClickReview,
}) => {
  const { t } = useTranslation();
  const {language} = useUiLanguage();
  const { userData } = useMainContext();

  const messageAttachments = getMessageAttachments(text);

  const {
    data: attachmentsData = [],
  } = useQuery(['attachmentsData', messageAttachments], () => (
    fetchResourcesContent(userData, language, {
      resourceIds: messageAttachments.map((attachment) => attachment.data),
    })
  ));
  
  const resourceIdToResourceMap = useMemo(() => {
    const out: {[field: string]: Template} = {};

    for (let i = 0; i < attachmentsData.length; i++) {
      out[attachmentsData[i].id] = attachmentsData[i];
    }

    return out;
  }, [attachmentsData]);

  const messageAttachmentsElements = messageAttachments.map((attachment, index) => {
    if (attachment.type === 'audio') {
      return (
        <AudioPlayer
          key={index}
          src={attachment.data || ''}
        />
      );
    } else if (attachment.type === 'image') {
      if (resourceIdToResourceMap[attachment.data]) {
        return <ImageTemplateItem
          key={index}
          style={{
            width: '100%',
            overflow: 'hidden',
            height: TEMPLATE_HEIGHT,
            justifySelf: 'flex-start'
          }}
          isShowTitle={true}
          item={resourceIdToResourceMap[attachment.data]}
        />;
      }

      return <Loader key={index} style={{ height: TEMPLATE_HEIGHT }} />;
    } else if (attachment.type === 'video') {
      if (resourceIdToResourceMap[attachment.data]) {
        return <VideoTemplateItem
          key={index}
          style={{
            width: '100%',
            overflow: 'hidden',
            height: TEMPLATE_HEIGHT,
            justifySelf: 'flex-start'
          }}
          isShowTitle={true}
          item={resourceIdToResourceMap[attachment.data]}
        />;
      }

      return <Loader key={index} style={{ height: TEMPLATE_HEIGHT }} />;
    }

    return null;
  });

  const renderAudioPlayer = useCallback((text, audio) => {
    if (audio) {
      const duration = text.match(REG_EXP_MESSAGE_VOICE)?.[1]?.trim();

      return (
        <AudioPlayer
          sliderColor={(
            messageAppearance === 'default'
            || (messageAppearance === 'reminder' || messageAppearance === 'notification')
            || messageAppearance === 'request'
            || messageAppearance === 'request-closed'
          ) ? 'green'
            : messageAppearance}
          variant='message'
          // Fix UI, playing couple players at once if the same {src} (e.g. reply on audio msg).
          src={audio ? (audio + '#' + id) : ''}
          durationPredefined={duration ? Number(duration) : undefined}
        />
      );
    }

    return '';
  }, [id, messageAppearance]);

  const replyMessageTextFinal = useMemo(() => {
    let out;

    if (replyText) {
      out = (
        (replyTextClearUrlified ? (replyTextClearUrlified + ' ') : '')
        + (getIsTextWithAttachments(replyText) ? ('(' + t('withAttachments') + ')') : '')
      );

      if (out && isReplyMessageQuote) {
        out = addQuotes(out);
      }
    } else {
      out = '';
    }

    return out;
  }, [t, isReplyMessageQuote, replyText, replyTextClearUrlified]);

  const messageColors = getMessageColors(messageAppearance, incoming);
  let messageStatusIcon,
    messageStatusIconColor: Color = 'green';

  if (!incoming) {
    if (sendStatus === 'sent' || sendStatus === 'received' || sendStatus === 'viewed') {
      messageStatusIconColor = (messageAppearance === 'blue' || messageAppearance === 'violet')
        ? messageAppearance
        : 'green';
    }

    if (sendStatus === 'sent' || sendStatus === 'received') {
      messageStatusIcon = <FontIconView icon={AeFontIcons.sent} className='icon-sent' />;
    } else if (sendStatus === 'viewed') {
      messageStatusIcon = <FontIconView icon={AeFontIcons.read} className='icon-read' />;
    } else if (sendStatus === 'sending') {
      messageStatusIconColor = 'gray';
      messageStatusIcon = <FontIconView icon={AeFontIcons.sent} className='icon-sent' />;
    } else if (sendStatus === 'queue') {
      messageStatusIconColor = 'gray';
      messageStatusIcon = <FontIconView icon={AeFontIcons.clock} />;
    } else if (
      sendStatus === 'error' || sendStatus === 'errorSend' || sendStatus === 'undelivered'
    ) {
      messageStatusIconColor = 'red';
      messageStatusIcon = <FontIconView icon={AeFontIcons.error} className='icon-error' />;
    }
  }

  let replyMessageHead;

  if (replyName || replyRoleLabel) {
    replyMessageHead = (
      <Row layout='flex' directionHorizontal autoColumns horizontal='start'>
        {replyName && (
          <Typography threeDots component='h5' color={messageColors.headText}>
            {replyName}
          </Typography>
        )}
        {replyRoleLabel && (
          <Typography
            color={messageColors.headTextAfter}
            component='h5'
            style={{ minWidth: 90 }} // Let 'Missionary' label to be not cropped.
            threeDots
          >&nbsp;• {replyRoleLabel}</Typography>
        )}
      </Row>
    );
  }

  let replyMessageFoot;

  if (replyRequestCategoryName) {
    replyMessageFoot = (
      <>
        <Typography component='h6' tagName='span' color={messageColors.footText}>
          <br/>• {replyRequestCategoryName} —&nbsp;
        </Typography>
        <Typography component='h6' tagName='span' color={messageColors.footTextAfter}>
          {t(isReplyRequestClosed ? 'closed' : 'openRequest')}
        </Typography>
      </>
    );
  }

  let head, foot;

  let headText, headTextAfter;

  if (messageAppearance === 'reminder') {
    headText = <>
      <FontIconView icon={AeFontIcons.reminder} />&nbsp;{t('reminder')}
    </>;
    headTextAfter = requestCategoryName;
  } else if (messageAppearance === 'notification') {
    headText = <>
      <FontIconView icon={AeFontIcons.bell} />&nbsp;{t('notification')}
    </>;
  } else if (name || roleLabel) {
    headText = name;
    headTextAfter = roleLabel;
  }

  if (headText || headTextAfter) {
    head = (
      <Row layout='flex' directionHorizontal autoColumns horizontal='start'>
        {headText && (
          <Typography threeDots component='h5' color={messageColors.headText}>
            {headText}
          </Typography>
        )}
        {headTextAfter && (
          <Typography
            color={messageColors.headTextAfter}
            component='h5'
            style={{ minWidth: 90 }} // Let 'Missionary' label to be not cropped.
            threeDots
          >&nbsp;• {headTextAfter}</Typography>
        )}
      </Row>
    );
  }

  let footText, footTextAfter;

  if (messageAppearance === 'reminder') {
    footText = t('onlyYouCanSeeThis');
  } else if (messageAppearance === 'request' || messageAppearance === 'request-closed') {
    footText = <>• {requestCategoryName}</>;
    footTextAfter = t(messageAppearance === 'request' ? 'openRequest' : 'closed');
  }

  if (footText || footTextAfter) {
    foot = (
      <>
        {
          footText && <Typography component="h6" tagName="span" color={messageColors.footText}>
            <br/>{footText}
          </Typography>
        }
        {
          footTextAfter && (
            <Typography component='h6' tagName='span' color={messageColors.footTextAfter}>
              &nbsp;—&nbsp;{footTextAfter}
            </Typography>
          )
        }
      </>
    );
  }

  const canReviewMessage = !incoming && canReviewOutgoing;

  return (
    <Row
      bgColor={messageColors.bg}
      borderRadius='all'
      layout='block'
      className={classNames('message', incoming ? 'in' : 'out')}
    >
      {
        (replyMessageTextFinal || replyAudio) && (
          <>
            <Row
              borderRadius='top'
              layout='block'
              className='message_replied'
              onClick={(e) => {
                // Prevent go to reply message if button 'More' was clicked.
                // @ts-ignore
                if (e.target.tagName !== 'BUTTON' && onClickRepliedMessage) {
                  onClickRepliedMessage();
                }
              }}
            >
              {replyMessageHead}
              <Typography
                component='body2'
                tagName='span'
                color={messageColors.text}
              >
                <FontIconView
                  className="msg_quote"
                  icon={isReplyMessageQuote ? AeFontIcons.share : AeFontIcons.reply}
                />
                <TextMore buttonProps={{ color: messageColors.btnMore, }}>
                  {replyMessageTextFinal}
                </TextMore>
              </Typography>
              {renderAudioPlayer(replyText, replyAudio)}
              {replyMessageFoot}
            </Row>
            <Divider className="msg_divider" color={messageColors.divider}/>
          </>
        )
      }

      {head}
      {messageAttachmentsElements.length !== 0 && <Row gap='sm'>{messageAttachmentsElements}</Row>}
      {renderAudioPlayer(text, audio)}

      {
        (!textClearUrlified && !messageAttachments.length && !audio) && (
          <Typography
            component='body2'
            tagName='span'
            color={messageColors.date}
            style={{ wordWrap: 'break-word' }}
          >
            <i>{t('messageIsNotSupported')}</i>
          </Typography>
        )
      }

      {
        textClearUrlified && (
          <Typography
            component='body2'
            tagName='span'
            color={messageColors.text}
            style={{ wordWrap: 'break-word' }}
          >
            <TextMore buttonProps={{ color: messageColors.btnMore, }}>
              {textClearUrlified}
            </TextMore>
          </Typography>
        )
      }

      {foot}

      <div className='message_meta'>
        {
          (
            messageReviewStatus === 'denied'
            || messageReviewStatus === 'approved'
            || canReviewMessage) && (
            <Tooltip
              content={t(getTranslateStringBtnReview(messageReviewStatus))}
              className='msg-item_tooltip-review'
            >
              <Button
                className='message_btn-review'
                variant='transparent'
                size='extra-sm'
                iconBefore={AeFontIcons.star}
                color={messageReviewStatus === 'approved'
                  ? 'green'
                  : messageReviewStatus === 'denied' ? 'red' : 'text-title-light'}
                onClick={() => {
                  if (onClickReview) {
                    onClickReview();
                  }
                }}
              />
            </Tooltip>
          )
        }
        {
          (!!Number(messageScore) && canReviewMessage) && (
            <Typography
              className='message_text-review'
              component='h5'
              color='text-title-light'
              tagName='span'
            >
              {messageScore}
            </Typography>
          )
        }
        <Typography component='body4' color={messageColors.date} className='msg-date'>
          &nbsp;{formatDateToUiDate(toLocalDateString(date), 'message')}
        </Typography>
        {messageStatusIcon && (
          <Typography
            tagName='span'
            component='body3'
            color={messageStatusIconColor}
          >&nbsp;{messageStatusIcon}</Typography>
        )}
      </div>
    </Row>
  );
});

export default MessageItem;
