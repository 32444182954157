import React, { SyntheticEvent } from 'react';
import { CalendarProps } from 'react-calendar';
import classNames from 'classnames';

import { Input } from '../index';
import { useModalContext } from '../modal/ModalProvider';
import { formatDateToUiDate } from '../../utils/time';
import ModalCalendar from './ModalCalendar';
import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';

import './DatePicker.scss';

export type DatePickerValue = Date;

export type DatePickerProps = {
  size?: 'sm' | 'lg';
  value?: DatePickerValue;
  name?: string;
  calendarProps?: CalendarProps;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  onChange: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>, value: DatePickerValue) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value = new Date(),
  onChange,
  onFocus,
  onBlur,
  calendarProps,
  size,
  name,
  disabled,
}) => {
  const { openModal } = useModalContext();

  const handleOpen = () => {
    if (typeof onFocus === 'function') {
      onFocus();
    }

    openModal(<ModalCalendar
      calendarProps={calendarProps}
      initialValue={value}
      onSubmit={(e, value) => {
        onChange(e, value);
      }}
    />, {
      widthSize: 'sm',
      closeButton: false,
      onClose: () => {
        if (typeof onBlur === 'function') {
          onBlur();
        }
      },
    });
  };

  return (
    <Input
      name={name}
      size={size}
      disabled={disabled}
      className='date-picker_input'
      readOnly
      value={disabled ? '__ /__ /____' : formatDateToUiDate(value, 'date-digits')}
      onClick={handleOpen}
      slotAfter={
        <FontIconView
          icon={AeFontIcons.calendar}
          className={classNames('date-picker__icon', disabled ? 'gray-light' : 'text-title-light')}
        />
      }
    />
  );
};

export default DatePicker;
