import { Role } from './enums';
import { Color, RoleColors } from '../types';

type RoleColorsMapType = {[key in RoleColors] : Color };

const roleColorsDM: RoleColorsMapType = {
  'role-color': 'green',
  'role-color-light': 'green-light',
  'role-color-dark': 'green-dark',
  'role-color-opacity': 'green-opacity',

  'role-color-gradient-from': 'green-light',
  'role-color-gradient-to': 'green-gradient-to',
  'role-color-gradient-hover-from': 'green',
  'role-color-gradient-hover-to': 'green-dark',
};

const roleColorsLeader: RoleColorsMapType = {
  'role-color': 'blue',
  'role-color-light': 'blue-light',
  'role-color-dark': 'blue-dark',
  'role-color-opacity': 'blue-opacity',

  'role-color-gradient-from': 'blue-gradient-from',
  'role-color-gradient-to': 'blue-gradient-to',
  'role-color-gradient-hover-from': 'blue-gradient-hover-from',
  'role-color-gradient-hover-to': 'blue-gradient-hover-to',
};

export const setUiRoleCssVariables = (role = Role.DM) => {
  const rootElement = document.querySelector<HTMLElement>(':root');

  const isLeaderRoles =
    role === Role.LEADER || role === Role.DM_LEADER || role === Role.CM || role === Role.ADMIN;

  if (rootElement) {
    const colors = isLeaderRoles ? roleColorsLeader : roleColorsDM;
    const colorsKeys = Object.keys(colors) as (keyof typeof colors)[];

    for (let i = 0; i < colorsKeys.length; i++) {
      const key = colorsKeys[i];

      rootElement.style.setProperty('--' + key, 'var(--' + colors[key] + ')');
    }
  }
};

export const colors = {
  grayTrans: '#3C3C4355',
  grayLightTrans: '#C7C7CC50',
  grayLight: '#B2BBC8',
  grayBack: '#F2F6FC',
  grayBackTrans: '#F2F6FC',
  grayDark: '#7A8799',
  grayMedium: '#C7C7CC',
  grayBtn: '#ECF1F8',
  textTitle: '#45575F',
  textBody: '#3C4148',
  blue: '#3185FC',
  blueTrans: '#3185FC22',
  blueDark: '#07477B',
  blueLight: '#86B8FD',
  blueFb: '#3A77EA',
  red: '#ff96ad',
  redLight: '#F87267',
  redMedium: '#DA2C38',
  green: '#61A331',
  greenBright: '#1BAB29',
  greenMeadow: '#16A78D',
  greenLight: '#A6D981',
  greenAqua: '#4CC9C2',
  blueDarkGradient: ['#07477B', '#07477B'],
  gradGreenGreen: ['#87c957', '#61A331'],
  gradBlueBlue: ['#1494FC', '#3185FC'],
  white: '#ffffff',
  gradGreen: ['transparent', '#17667C'],
  gradWhite: ['#FFFFFF00', '#FFFFFF77', '#FFFFFF'],
  gradWhiteTrans: ['#FFFFFF', '#FFFFFF00'],
  gradBlue: ['#1459BC', '#FFFFFF'],
  gradWhiteLight: ['#FFFFFF00', '#FFFFFF99'],
  gradWhiteCenter: ['#FFFFFF55', '#FFFFFF', '#FFFFFF55'],
  gradBlackCenter: ['#00000055', '#000000', '#00000055'],
  black: '#3C4148',
  border: '#3C3C434C',
  borderLight: '#3C3C4332',
  transparent: 'transparent',
  transGray: '#cccccc44',
  avaBlue: '#007AFF',
  avaBlueLight: '#5AC8FA',
  pink: '#FF375F',
  pinkLight: '#FF99AD',
  yellow: '#FFCC00',
  orange: '#FF9500',
  orangeLight: '#FFAA33',
  orangeDark: '#F58F00',
  violet: '#AF52DE',
  violetBright: '#CC32C5',
  violetDark: '#5856D6',
  chatBg: '#EEDFC8',
  chatMsgOut: '#FFFBF0',
  chatMsgIn: '#AB7B60',
  chatReminder: '#356CBA',
  olive: '#C0DDAA'
};

const randomColor = [
  colors.pink,
  colors.orange,
  colors.yellow,
  colors.greenLight,
  colors.avaBlueLight,
  colors.avaBlue,
  colors.violetDark,
  colors.violet
];

export function getRandomColor(id: string) {
  const code = parseInt(id, 10);
  return randomColor[code % randomColor.length];
}

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find((key) => object[key] === value) || '';
}
