import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../index';
import {ButtonProps} from '../button/Button';

const TEXT_MORE_INDEX_SLICE = 350;

type TextMoreProps = {
  children: string,
  buttonProps?: ButtonProps,
};

const TextMore: React.FC<TextMoreProps> = (props) => {
  const { t } = useTranslation();
  const [showHiddenText, setShowHiddenText] = useState(false);
  const isTextLengthExceedsLimit = !!props.children.slice(TEXT_MORE_INDEX_SLICE).length;

  if (isTextLengthExceedsLimit) {
    const shownText = showHiddenText
      ? props.children
      : props.children.slice(0, TEXT_MORE_INDEX_SLICE);

    return (
      <>
        <span dangerouslySetInnerHTML={{
          __html: shownText,
        }}/>
        {showHiddenText ? '' : '…'}
        &nbsp;<Button
          variant='link'
          color='blue'
          {...props.buttonProps}
          onClick={() => {
            setShowHiddenText(!showHiddenText);
          }}
        >{t(showHiddenText ? 'less' : 'more')}</Button>
      </>
    );
  }

  return <span dangerouslySetInnerHTML={{
    __html: props.children,
  }}/>;
};

export default TextMore;
