import React  from 'react';
import classnames from 'classnames';

import './DividerVertical.scss';

const DividerVertical: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className, ...restProps
}) => {
  return (
    <div className={classnames('icon-divider', className)} {...restProps} />
  );
};

export default DividerVertical;
