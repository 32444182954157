import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components';
import Form  from '../../../components/form/Form';
import { isRequired } from '../../../components/form/Form.utils';
import { FieldEmail, FieldPassword } from '../../../components/form/Fields';
import { loginRequest, registerRequest } from '../../../api/account';
import { useToastEnhanced } from '../../../enhanced-components/toaster/ToasterEnhanced';
import { useMainContext } from '../../../MainProvider';
import { useUiLanguage } from '../../../hooks/useLanguage';
import { Role } from '../../../utils/enums';
import { useModalContext } from '../../../components/modal/ModalProvider';
import { AuthAccountsTypes, registerAccountTypeDM } from '../../../utils/constants';
import PasswordValidityView from '../../../components/password-validity-view/PasswordValidityView';

export type DefaultFormSignUpData = {
  email: string;
}

export type ExtendedFormSignUpData = DefaultFormSignUpData & {
  password: string;
  confirmPassword: string;
}

const FormRegister = ({ agentType }: { agentType: AuthAccountsTypes }) => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { handleAuthWithFetchedUserData, userData } = useMainContext();
  const { language } = useUiLanguage();
  const { openModal } = useModalContext();
  const [password, setPassword] = useState('');

  const isDM = agentType === registerAccountTypeDM;

  let fields: DefaultFormSignUpData = { email: '' };

  if (isDM) {
    fields = {
      email: '',
      password: '',
      confirmPassword: '',
    } as ExtendedFormSignUpData;
  }

  return (
    <Form
      // Prevent validate form on blur when focused not valid field and click on 'Sign In'.
      validateFormOnFieldBlur={false}
      fields={fields}
      onSubmit={async ({ isValid, values }) => {
        if (isValid) {
          await registerRequest(values.email, values.password || '', language, agentType)
            .then(async (response) => {
              await handleAuthWithFetchedUserData({ ...response }, false);
            })
            .catch((e) => {
              if (e.request?.role === Role.LEADER) {
                openModal('', {
                  widthSize: 'sm',
                  title: t('registeredLeaderMsg'),
                  cancelButton: true,
                  okButton: true,
                  okButtonProps: {
                    onClick: async () => {
                      await loginRequest(values.email, values.password)
                        .then(async (response) => {
                          await handleAuthWithFetchedUserData({
                            ...userData,
                            id: response.id,
                            role: Role.DM_LEADER,
                            agentToken: response.agentToken,
                            roleStatus: { evangelist: 'pending', leader: 'pending', manager: null },
                          }, true);
                        }).catch((e) => {
                          showToast({
                            title: e.message || t('errorWrongSignin'),
                          }, { type: 'error' });
                        });
                    },
                    children: t('yes'),
                  }
                });
              } else {
                showToast({ title: e.message }, { type: 'error' });
              }
            });
        }
      }}
    >
      {
        ({ isSubmitting }) => (
          <>
            <FieldEmail
              labelText={t('labelEmail')}
            />

            {isDM && (
              <>
                <FieldPassword
                  labelText={t('labelPass')}
                  componentProps={{
                    onChange: (e: any) => {
                      setPassword(e.currentTarget.value);
                    }
                  }}
                />
                <FieldPassword
                  componentProps={{
                    placeholder: t('repeatPassword'),
                  }}
                  validators={[
                    isRequired,
                    (v: string) => ({
                      label: ['errorConfirmPass'],
                      valid: v === password,
                    }),
                  ]}
                  name="confirmPassword"
                  labelText={t('labelConfirmPass')}
                />
              </>
            )}

            <PasswordValidityView password={password} />

            <Button
              style={{ width: '100%' }}
              type="submit"
              disabled={isSubmitting}
            >{t('signUp')}</Button>
          </>
        )
      }
    </Form>
  );
};

export default FormRegister;
