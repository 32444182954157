import { TFunction } from 'i18next';

import { Role, RoleStatus, UserRoleStatus, UserRoleStatusValues } from './enums';
import { SourceDestTypes } from '../api/chat.types';
import { Profile, ProfileData } from '../api/account.types';

type Fn = (profile: Profile, isUiRoleNotDM?: boolean) => boolean;

export const getIsProfileExistCurry = (profileData: ProfileData | null) => (cb: Fn) => {
  if (profileData?.profile) {
    return cb(profileData.profile);
  }

  return false;
};

export const getIsEmailVerified = (profile: Profile) => !!profile.verified;

export const getIsCanUserUseLeaderUi = (role: Role) => {
  return isRoleIncludesLeader(role) || isRoleInlcudesCM(role) || role === Role.ADMIN;
};

export const getRole = (role: Role) => role;

export const roleStatusesToRole = (rolesStatuses: UserRoleStatus) => {
  const dm = getIsRoleStatusExist(rolesStatuses.evangelist);
  const leader = getIsRoleStatusExist(rolesStatuses.leader);
  const cm = getIsRoleStatusExist(rolesStatuses.manager);

  const enumValue = (dm ? Role.DM : 0) + (leader ? Role.LEADER : 0) + (cm ? Role.CM : 0);
  // @ts-ignore
  return Role[Role[enumValue]];
};

export const getIsProfileFilled = (profile: Profile, roleIsNotDM: boolean, options?: {
  skipPastorChurchCheck: boolean,
}) => {
  const commonRequiredFields =
    !!(
      profile.country &&
      profile.firstname &&
      profile.lastname
    );

  if (roleIsNotDM) {
    return commonRequiredFields;
  }

  return !!(
    commonRequiredFields &&
    (options?.skipPastorChurchCheck || profile.pastorChurch) &&
    profile.pastorName &&
    profile.pastorEmail
  );
};

export const getIsAgreementsFilled = (profile: Profile) => {
  return !!(profile.aupAccept && profile.dpaAccept);
};

export const getUserSourceDestType = (role?: Role) => {
  if (role) {
    if (getIsCanUserUseLeaderUi(role)) {
      return SourceDestTypes.LEADER;
    }
  }

  return SourceDestTypes.EVANGELIST;
};

export const getIsRoleStatusActive = (roleStatus: RoleStatus) => roleStatus === 'active';
export const getIsRoleStatusPending = (roleStatus: RoleStatus) => roleStatus === 'pending';
export const getIsRoleStatusExist = (roleStatus: RoleStatus) => roleStatus !== null;

export const getUserRoleStatuses = (roleStatus?: UserRoleStatus) => {
  let isOneOfRoleActive = false;
  let isOneOfRolePending = false;
  let isOneOfRoleDenied = false;

  if (roleStatus) {
    Object.keys(roleStatus).forEach((item) => {
      if (roleStatus[item as UserRoleStatusValues] === 'active') {
        isOneOfRoleActive = true;
      } else if (roleStatus[item as UserRoleStatusValues] === 'pending') {
        isOneOfRolePending = true;
      } else if (roleStatus[item as UserRoleStatusValues] === 'denied') {
        isOneOfRoleDenied = true;
      }
    });
  }
  
  return [isOneOfRoleActive, isOneOfRolePending, isOneOfRoleDenied];
};

export const isRoleInlcudesDM = (role: Role) => {
  const numberRole = +role;
  return (
    numberRole === Role.DM ||
    numberRole === Role.DM_LEADER ||
    numberRole === Role.CM_DM ||
    numberRole === Role.CM_DM_LEADER
  );
};
export const isRoleInlcudesCM = (role: Role) => {
  const numberRole = +role;
  return (
    numberRole === Role.CM ||
    numberRole === Role.CM_DM ||
    numberRole === Role.CM_LEADER ||
    numberRole === Role.CM_DM_LEADER
  );
};

export const isRoleIncludesLeader = (role: Role) => {
  const numberRole = +role;
  return (
    numberRole === Role.LEADER ||
    numberRole === Role.DM_LEADER ||
    numberRole === Role.CM_LEADER ||
    numberRole === Role.CM_DM_LEADER
  );
};

export const isRoleAdmin = (role: Role) => +role === Role.ADMIN;
export const isRoleDM = (role: Role) => +role === Role.DM;

const _createRoleStatusChecker = (predicate: (roleStatus: UserRoleStatus) => boolean) => {
  return (roleStatus: UserRoleStatus, isAdmin: boolean) => {
    if (isAdmin) {
      return false;
    }

    if (!roleStatus) {
      return false;
    }

    return predicate(roleStatus);
  };
};

const hasDM = (roleStatus: UserRoleStatus) => !!roleStatus.evangelist;
const hasLeader = (roleStatus: UserRoleStatus) => !!roleStatus.leader;
const hasCM = (roleStatus: UserRoleStatus) => !!roleStatus.manager;

export const isRoleStatusHasLeaderAndNotCM = _createRoleStatusChecker(
  (roleStatus) => hasLeader(roleStatus) && !hasCM(roleStatus)
);

export const isRoleStatusHasCmAndNotLeader = _createRoleStatusChecker(
  (roleStatus) => hasCM(roleStatus) && !hasLeader(roleStatus)
);

export const isRoleStatusHasLeaderAndCM = _createRoleStatusChecker(
  (roleStatus) => hasLeader(roleStatus) && hasCM(roleStatus)
);

export const isRoleStatusHasLeaderAndDM = _createRoleStatusChecker(
  (roleStatus) => hasLeader(roleStatus) && hasDM(roleStatus)
);

export const isRoleStatusHasCmAndDM = _createRoleStatusChecker(
  (roleStatus) => hasLeader(roleStatus) && hasCM(roleStatus)
);

export const isRoleStatusHasDM = _createRoleStatusChecker(hasDM);

export const isRoleStatusOnlyDM = _createRoleStatusChecker(
  (roleStatus) => hasDM(roleStatus) && !hasLeader(roleStatus) && !hasCM(roleStatus)
);

export const getInitialUiRoleByUserRoleStatus = (roleStatus: UserRoleStatus) => {
  let activeRole = Role.DM;

  if (!roleStatus) {
    return Role.ADMIN;
  }

  const activeRoleStatuses = Object.keys(roleStatus).filter((key) => (
    getIsRoleStatusActive(roleStatus[key as UserRoleStatusValues])
  ));

  // Prefer the UI role of DM ('evangelist'),
  // if a multi-role user has a DM role - this is a requirement from the customer.
  if (activeRoleStatuses.includes('evangelist')) {
    return activeRole;
  }

  for (let i = 0; i < activeRoleStatuses.length; i++) {
    const role = activeRoleStatuses[i];

    if (role === 'leader') {
      activeRole = Role.LEADER;
    } else if (role === 'manager') {
      activeRole = Role.CM;
    }
  }

  return activeRole;
};

export const createRoleString = (roleStatus: UserRoleStatus, t: TFunction) => {
  const roles = [t('missionary')];

  if (getIsRoleStatusExist(roleStatus.leader)) {
    roles.unshift(t('leader'));
  }
  if (getIsRoleStatusExist(roleStatus.manager)) {
    roles.unshift(t('manager'));
  }

  return roles.join(' / ');
};

export const createRoleStatusString = (roleStatus: UserRoleStatus, t: TFunction) => {
  const statuses: string[] = [];

  for (let key in roleStatus) {
    const status = roleStatus[key as UserRoleStatusValues];

    if (getIsRoleStatusExist(status)) {
      statuses.unshift(t(status as string));
    }
  }

  return statuses.join(' / ');
};
