import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import isYesterday from 'date-fns/isYesterday';
import parseISO from 'date-fns/parseISO';
import set from 'date-fns/set';
import subDays from 'date-fns/subDays';
import isAfter from 'date-fns/isAfter';

import { AWRUserId } from './constants';
import { GetByIdWithCacheFn } from '../hooks/useCache';
import {
  ChatItemCampaignType,
  ListCampaignsItemConcise,
} from '../api/campaigns.types';
import {ReminderItemTypeApi} from '../api/reminder.types';
import {SeekerCommonType, SeekerItemType} from '../api/seekers.types';
import {Prayer} from '../api/prayer';
import { CampaignMember } from '../api/account';
import { Role, RoleStatus } from './enums';
import { ChatItemLeaderCMType } from '../api/chat.types';
import { EvangelistItemType, AdminEvangelistItem } from '../api/evangelists.types';
import { RequestCategoriesType, RequestItemType, RequestStatus } from '../api/request.types';
import { BroadcastItemType } from '../api/broadcasts.types';
import { StudentItem } from '../api/students.types';
import { differenceInDays } from 'date-fns';
import { getLimits } from '../api/students.utils';
import { SelectOption } from '../components/select/Select.types';
import { MultiSelectValue } from '../components/multi-select/MultiSelect.types';
import { isRoleDM } from './profile';
import { ChannelPlatform } from '../api/channels.types';

export type FilterByDateValues = '' | 'today' | 'yesterday' | 'pastWeek' | 'pastMonth'
  | 'customRange';
export type FilterRequestStatusValues = '' | RequestStatus;
export type FilterCampaignStatusValues = '' | 'ready' | 'running' | 'finished';
export type FilterMessageStatusValues = '' | 'newSeeker' | 'unread';
export type FilterProfileStatusValues = '' | Exclude<RoleStatus, null>;
export type FilterRolesValues = '' | 'missionary' | 'leader' | 'manager';
export type FilterVetterValues = '' | 'has-vetter' | 'no-vetter' | string;
export type FilterActiveSeekersValues = '' | 'has-interests' | 'no-interests';
export type FilterLanguageValues = '' | string;
export const FilterRoles: FilterRolesValues[] = ['missionary', 'leader', 'manager'];

type AdminFilterValues = {
  channelPlatforms: ChannelPlatform[],
  language: FilterLanguageValues,
};

type StudentsFilterValues = {
  series: string[],
  topic: string[],
  lessonNumber: string[],
  studentLanguages: string[],
  lastLesson: string,
  lastChat: string,
};

export type FiltersValues = AdminFilterValues & StudentsFilterValues & {
  requestStatus: FilterRequestStatusValues,
  campaignStatus: FilterCampaignStatusValues,
  requestType: string[],
  dateStart: string,
  dateEnd: string,
  filterByDate: FilterByDateValues,
  messageStatus: FilterMessageStatusValues,
  category: string[],
  interest: string[],
  missionary: string[],
  campaigns: string[],
  roles: FilterRolesValues[],
  profileStatus: FilterProfileStatusValues,
  showLatestSeekers: boolean,
  vetter: FilterVetterValues[],
  activeSeekers: FilterActiveSeekersValues,
}

export const connectFiltersDefault: ConnectTeamsTabFiltersFormData = {
  filterByDate: {key: '', label: ''},
  dateStart: new Date(),
  dateEnd: new Date(),
  messageStatus: {key: '', label: ''},
  requestStatus: {key: '', label: ''},
  requestType: [],
  category: [],
  interest: [],
  campaigns: [],
  missionary: [],
  roles: [],
  profileStatus: { key: '', label: '' },
  campaignStatus: { key: '', label: '' },
  showLatestSeekers: false,
  vetter: [],
  activeSeekers: { key: '', label: '' },
};

const adminFiltersDefault: AdminTabFiltersFormData = {
  channelPlatforms: [],
  language: { key: '', label: '' },
};

const studentsFiltersDefault: StudentFiltersFormData = {
  series: [],
  topic: [],
  lessonNumber: [],
  studentLanguages: [],
  lastLesson: { key: '', label: '' },
  lastChat: { key: '', label: '' }
};

export const allFiltersDefault: AllFiltersFormData = {
  ...connectFiltersDefault,
  ...studentsFiltersDefault,
  ...adminFiltersDefault,
};

export type ConnectTeamsTabFiltersFormData = {
  filterByDate: SelectOption<FilterByDateValues>,
  dateStart: Date,
  dateEnd: Date,
  messageStatus: SelectOption<FilterMessageStatusValues>,
  requestStatus: SelectOption<FilterRequestStatusValues>,
  requestType: MultiSelectValue,
  category: MultiSelectValue,
  interest: MultiSelectValue,
  missionary: MultiSelectValue,
  campaigns: MultiSelectValue,
  roles: MultiSelectValue<FilterRolesValues>,
  profileStatus: SelectOption<FilterProfileStatusValues>,
  campaignStatus: SelectOption<FilterCampaignStatusValues>,
  showLatestSeekers: boolean,
  vetter: MultiSelectValue<FilterVetterValues>,
  activeSeekers: SelectOption<FilterActiveSeekersValues>,
};

export type AdminTabFiltersFormData = {
  channelPlatforms: MultiSelectValue<ChannelPlatform>,
  language: SelectOption,
}

export type StudentFiltersFormData = {
  series: MultiSelectValue,
  topic: MultiSelectValue,
  lessonNumber: MultiSelectValue,
  studentLanguages: MultiSelectValue,
  lastLesson: SelectOption,
  lastChat: SelectOption,
};

export type AllFiltersFormData = ConnectTeamsTabFiltersFormData & StudentFiltersFormData & AdminTabFiltersFormData;

type FilterWithSearchFn<T> = (items: T[], search: string, ...additionalArgs: any) => T[];
type FilterWithFiltersFn<T, FiltersKeys extends keyof FiltersValues> =
  (items: T[], filters: Pick<FiltersValues, FiltersKeys>, ...additionalArgs: any) => T[];

const startTime = { hours: 0, minutes: 0, seconds: 0 };
const endTime = { hours: 23, minutes: 59, seconds: 59 };

const checkDate = (
  date: string,
  filterDate: FilterByDateValues,
  dateStart: string,
  dateEnd: string
) => {
  const dateValue = parseISO(date);

  if (filterDate === 'today') {
    return isToday(dateValue);
  } else if (filterDate === 'yesterday') {
    return isYesterday(dateValue);
  } else if (filterDate === 'pastWeek') {
    const today = new Date();
    const lastWeekDate = subDays(today, 7);

    return isAfter(dateValue, set(lastWeekDate, startTime));
  } else if (filterDate === 'pastMonth') {
    const today = new Date();
    const lastMonthDate = subDays(today, 30);

    return isAfter(dateValue, set(lastMonthDate, startTime));
  } else if (filterDate === 'customRange') {
    const start = new Date(dateStart);
    const end = new Date(dateEnd);

    return isWithinInterval(dateValue, {
      start: set(start, startTime),
      end: set(end, endTime)
    });
  }

  return true;
};

const checkMessageStatus = (item: SeekerCommonType, status: FilterMessageStatusValues) => {
  if (status === 'unread') {
    return item.unread > 0;
  } else if (status === 'newSeeker') {
    return item.outgoing === 0 && item.id !== AWRUserId;
  }

  return true;
};

const checkRequestStatus = (item: SeekerCommonType, requestStatus: string) => {
  switch (requestStatus) {
    case 'open':
      return item.open;
    case 'closed':
      return item.closed;
    case 'waiting':
      return item.waiting;
    default:
      return true;
  }
};

const checkRequestType = (item: SeekerCommonType, requestType: string[]) => {
  let result = false;

  for (let i = 0; i < requestType.length; i++) {
    const requestCategoryName = RequestCategoriesType[+requestType[i]];

    switch (requestCategoryName) {
      case 'prayer':
        result = item.prayer !== 0;
        break;
      case 'question':
        result = item.question !== 0;
        break;
      case 'study':
        result = item.study !== 0;
        break;
    }
  }

  return result;
};

const checkDateWithinLimits = (date: string | null, limits: number[]): boolean => {
  const today = new Date();
  if (date) {
    const dateValue = parseISO(date);
    const daysCount = differenceInDays(today, dateValue);

    if (limits.length === 1) {
      return daysCount > limits[0];
    } else if (limits.length === 2) {
      return daysCount >= limits[0] && daysCount <= limits[1];
    }
  }
  return false;
};

export const filterSeekersWithFilters: FilterWithFiltersFn<
  SeekerCommonType,
  'filterByDate' | 'messageStatus' | 'requestStatus' | 'requestType'
  | 'dateEnd' | 'dateStart' | 'missionary' | 'campaigns'
> = (
  items,
  filters,
  listCampaignsInterestIds: Array<string>,
) => {
  const {
    filterByDate,
    messageStatus,
    requestStatus,
    requestType,
    missionary,
    campaigns,
  } = filters;

  const isAnyFilterApplied = filterByDate
    || messageStatus
    || requestStatus
    || missionary.length
    || campaigns.length
    || requestType.length;

  if (isAnyFilterApplied) {
    const { dateStart, dateEnd } = filters;

    return items.filter((item) => {
      return (
        (
          filterByDate
            ? item.date ? checkDate(item.date, filterByDate, dateStart, dateEnd) : false
            : true
        ) && (
          messageStatus
            ? checkMessageStatus(item, messageStatus)
            : true
        ) && (
          requestType.length
            ? checkRequestType(item, requestType)
            : true
        ) && (
          requestStatus
            ? checkRequestStatus(item, requestStatus)
            : true
        ) && (
          missionary.length
            ? (item.evangelistId && missionary.includes(item.evangelistId.toString()))
            : true
        ) && (
          campaigns.length
            ? listCampaignsInterestIds.length ? listCampaignsInterestIds.includes(item.id) : false
            : true
        )
      );
    });
  }

  return items;
};

export const filterStudentsWithFilters: FilterWithFiltersFn<
  StudentItem,
  'series' | 'topic' | 'lessonNumber' | 'studentLanguages' | 'lastLesson' | 'lastChat'
> = (items, filters, search = '') => {
  const { series, topic, lessonNumber, studentLanguages, lastLesson, lastChat } = filters;
  const isAnyFilterApplied = series.length 
    || topic.length 
    || lessonNumber.length 
    || studentLanguages.length
    || lastLesson
    || lastChat
    || !!search;

  let lastLessonLimits: number[] = [];
  let chatLessonLimits: number[] = [];
  if (lastLesson) {
    lastLessonLimits = getLimits(lastLesson);
  }
  if (lastChat) {
    chatLessonLimits = getLimits(lastChat);
  }

  if (isAnyFilterApplied) {
    const searchLowerCased = search.toLowerCase();
    return items.filter((item) => {
      return (
        (series.length ? item.seriesId && series.includes(item.seriesId) : true) &&
        (topic.length ? item.lessonTopic !== '' && topic.includes(item.lessonTopic) : true) &&
        (lessonNumber.length
          ? item.lessonIndex && lessonNumber.includes(item.lessonIndex.toString())
          : true) &&
        (studentLanguages.length ? item.language && studentLanguages.includes(item.language) : true) &&
        (lastLessonLimits.length
          ? checkDateWithinLimits(item.lessonDate, lastLessonLimits)
          : true) &&
        (chatLessonLimits.length
          ? checkDateWithinLimits(item.lastContact, chatLessonLimits)
          : true) &&
        (search !== ''
          ? (item?.firstname || '').toLowerCase().indexOf(searchLowerCased) !== -1 ||
            (item?.lastname || '').toLowerCase().indexOf(searchLowerCased) !== -1
          : true)
      );
    });
  }
  return items;
};

export const filterRequestsWithFilters: FilterWithFiltersFn<
  RequestItemType, 'requestType' | 'requestStatus'
> = (
  items,
  filters,
) => {
  const {
    requestStatus,
    requestType,
  } = filters;

  const isAnyFilterApplied = requestStatus || requestType.length;

  if (isAnyFilterApplied) {
    return items.filter((item) => {
      return (
        (requestStatus ? item.request.status === requestStatus : true)
        && (requestType.length ? requestType.includes(item.request.categoryId) : true)
      );
    });
  }

  return items;
};

export const filterPrayersWithFilters: FilterWithFiltersFn<
  Prayer, 'category' | 'interest'
> = (
  items,
  filters,
) => {
  const { category, interest } = filters;
  return items.filter((item) => {
    return (category.length ? category.includes(item.categoryId) : true)
      && (interest.length ? interest.includes(item.seekerId) : true);
  });
};

export const filterCampaignChatsWithFilters: FilterWithFiltersFn<ChatItemCampaignType, 'campaignStatus'> = (
  items,
  filters,
  getCampaignById: GetByIdWithCacheFn<ListCampaignsItemConcise>
) => {
  const { campaignStatus } = filters;

  const isAnyFilterApplied = !!campaignStatus;

  if (isAnyFilterApplied) {
    return items.filter((item) => {
      return (
        (
          campaignStatus
            ? getCampaignById(item.campaignId)?.status === campaignStatus
            : true
        )
      );
    });
  }

  return items;
};

export const filterEvangelistChatsWithFilters: FilterWithFiltersFn<
  EvangelistItemType, 'campaigns'
> = (
  items,
  filters,
) => {
  const { campaigns } = filters;

  const isAnyFilterApplied = !!campaigns;

  if (isAnyFilterApplied) {
    return items.filter((item) => {
      return (
        (
          campaigns.length
            ? (item.campaigns?.some(
              (campaign) => campaigns.includes(campaign.campaignId)
            ))
            : true
        )
      );
    });
  }

  return items;
};

export const filterBySearch = <T> (items: T[], keys: (keyof T)[], search?: string): T[] => {
  if (search) {
    const searchLowerCased = search.toLowerCase();

    return items.filter((item) => {
      let isItemPassedFilter = false;
      let key;
      for (let i = 0; i < keys.length; i++) {
        key = keys[i];
        if (((item as any)[key] || '').toLowerCase().indexOf(searchLowerCased) !== -1) {
          isItemPassedFilter = true;
          break;
        }
      }      
      return isItemPassedFilter;
    });
  }

  return items;
};

export const filterSeekersBySearch: FilterWithSearchFn<SeekerCommonType> = (
  items, search
) => filterBySearch(items, ['firstname', 'lastname', 'text'], search);

export const filterPrayersBySearch: FilterWithSearchFn<Prayer> = (
  prayers,
  search,
  getSeekerById: GetByIdWithCacheFn<SeekerItemType>,
) => {
  if (!search) {
    return prayers;
  }

  const searchLowerCased = search.toLowerCase();

  return prayers.filter((item) => {
    const seeker = getSeekerById(item.seekerId);

    return (
      (seeker?.firstname || '').toLowerCase().indexOf(searchLowerCased) !== -1
      || (seeker?.lastname || '').toLowerCase().indexOf(searchLowerCased) !== -1
      || (item.text || '').toLowerCase().indexOf(searchLowerCased) !== -1
    );
  });
};

export const filterRemindersBySearch: FilterWithSearchFn<ReminderItemTypeApi> = (
  items,
  search,
  getSeekerById: GetByIdWithCacheFn<SeekerItemType>,
) => {
  if (search) {
    const searchLowerCased = search.toLowerCase();

    return items.filter((item) => {
      const seeker = item.seekerId ? getSeekerById(item.seekerId) : undefined;
      return (
        (seeker ? (seeker.firstname || '').toLowerCase().indexOf(searchLowerCased) !== -1 : true)
        || (seeker ? (seeker?.lastname || '').toLowerCase().indexOf(searchLowerCased) !== -1 : true)
        || (item.title || '').toLowerCase().indexOf(searchLowerCased) !== -1
      );
    });
  }

  return items;
};

export const filterRequestsBySearch: FilterWithSearchFn<RequestItemType> = (
  items, search
) => {
  if (search) {
    const searchLowerCased = search.toLowerCase();

    return items.filter((item) => {
      return (
        (item.request.message || '').toLowerCase().indexOf(searchLowerCased) !== -1
      );
    });
  }

  return items;
};

export const filterLeaderChatsBySearch: FilterWithSearchFn<ChatItemLeaderCMType> = (
  items, search
) => filterBySearch(items, ['firstname', 'lastname'], search);

export const filterEvangelistChatsBySearch: FilterWithSearchFn<EvangelistItemType> = (
  items, search
) => filterBySearch(items, ['firstname', 'lastname'], search);

export const filterEvangelistsBySearch: FilterWithSearchFn<AdminEvangelistItem> = (
  items, search
) => filterBySearch(items, ['firstname', 'lastname', 'text'], search);

export const filterCampaignChatsBySearch: FilterWithSearchFn<ChatItemCampaignType> = (
  items, search
) => filterBySearch(items, ['name'], search);

export const filterBroadcastListsBySearch: FilterWithSearchFn<BroadcastItemType> = (
  items, search
) => filterBySearch(items, ['name'], search);

export const filterCampaignMembersBySearch: FilterWithSearchFn<CampaignMember> = (
  items, search
) => filterBySearch(items, ['firstname', 'lastname'], search);

// Returns only those campaigns are visible in campaigns chat list.
export const createPredicateIsShowCampaignInChatList = (isUserLeader: boolean) => {
  return <T>(item: T & {role: Role | string | null}) => {
    const isUserLeaderInCampaign = item.role && !isRoleDM(item.role as Role);
    // We filter Leader campaigns to show only those he owns.
    // We filter DM campaigns to remove those he owns as a Leader, this is requirement.
    return (isUserLeader && isUserLeaderInCampaign)
      || (!isUserLeader && !isUserLeaderInCampaign);
  };
};
