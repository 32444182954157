import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import AeFontIcons from '../../../assets/Icons';
import { Button, Row, Typography } from '../../../components';
import TemplateMailSent from '../TemplateMailSent';
import { getProfileRequest, resendEmailRequest } from '../../../api/account';
import { useMainContext } from '../../../MainProvider';
import { useToastEnhanced } from '../../../enhanced-components/toaster/ToasterEnhanced';
import { URLS } from '../../../utils/constants';
import AuthContainer from '../AuthContainer';
import { QUERY_KEY_PROFILE, useGetProfile } from '../../../query/profile';
import { getIsEmailVerified, getIsProfileFilled } from '../../../utils/profile';

const PageEmailVerification: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useToastEnhanced();

  const { userData, isUiRoleNotDM } = useMainContext();
  const { profileData } = useGetProfile(userData.id);
  const [isViewEmailVerificationFetching, setIsViewEmailVerificationFetching] = useState(false);

  const onResendEmail = async () => {
    setIsViewEmailVerificationFetching(true);

    await resendEmailRequest(userData)
      .then((response) => {
        if (response?.status === 'success') {
          showToast({
            body: t('resendEmailSuccess')
          });
        }
      })
      .catch((e) => {
        showToast({
          title: t(e.message)
        }, {
          type: 'error'
        });
      })
      .finally(() => {
        setIsViewEmailVerificationFetching(false);
      });
  };

  const onAccept = async () => {
    setIsViewEmailVerificationFetching(true);
    const response = await getProfileRequest(userData);
    setIsViewEmailVerificationFetching(false);

    queryClient.setQueryData([QUERY_KEY_PROFILE, userData.id], response);

    const isProfileFilled = getIsProfileFilled(response.profile, isUiRoleNotDM);
    const isEmailVerified = getIsEmailVerified(response.profile);

    if (!isEmailVerified) {
      showToast({
        title: t('emailNotVerified'),
        body: t('resendEmailError'),
      }, {
        type: 'error'
      });
    } else if (isUiRoleNotDM && !isProfileFilled) {
      showToast({
        title: t('profileNotSetup'),
        body: t('setupYourProfileError'),
      }, {
        type: 'error'
      });
    }
  };

  return (
    <AuthContainer>
      <TemplateMailSent
        size='lg'
        icon={AeFontIcons.emailSent}
        title={t('emailVerification')}
        slotContent={(
          <>
            <div>
              {t('youAlmostThere')}&nbsp;
              <Typography
                component='body1'
                tagName='span'
                color='blue'
              >{profileData.profile?.email}</Typography>
            </div>
            <br/>
            {t('clickToEmailLink')}
          </>
        )}
        slotActions={(
          <Row directionHorizontal gap='md'>
            <Button
              color='gray'
              variant='opacity'
              onClick={() => history.push(URLS.login)}
            >{t('back')}</Button>
            <Button
              disabled={isViewEmailVerificationFetching}
              onClick={onAccept}
              style={{ textTransform: 'capitalize' }}
            >{t('continueRegistration')}</Button>
          </Row>
        )}
        onResendEmail={onResendEmail}
      />
    </AuthContainer>
  );
};

export default PageEmailVerification;
