import React, { CSSProperties, useCallback, useMemo } from 'react';

import {Button, FontIconView, Row, Slider, Typography} from '../index';
import { useAudioPlayer } from '../../hooks/useAudioPlayer';
import { formatDateToUiDate } from '../../utils/time';
import { TypographyProps } from '../typography/Typography';
import { RowProps } from '../row/Row';
import { Tooltip } from '../tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import {ColorScheme} from '../../types';
import AeFontIcons from '../../assets/Icons';

type AudioPlayerVariant = 'message' | 'record';

type AudioPlayerProps = {
  src: string,
  durationPredefined?: number,
  variant?: AudioPlayerVariant,
  sliderColor?: ColorScheme,
  style?: CSSProperties,
};

const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
  const { t } = useTranslation();
  const {
    isFetching,
    isPlaying,
    isError,
    currentTime,
    duration,
    play,
    pause,
    updateCurrentTime,
  } = useAudioPlayer(props.src);

  const handleChangeSlider = useCallback((e, value) => {
    updateCurrentTime(value);
  }, [updateCurrentTime]);

  const handleClickBtnTogglePlaying = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, play, pause]);

  const [wrapperProps, durationProps] = useMemo(() => {
    const wrapperProps: Partial<Omit<RowProps, 'ref'>> = {
      gap: 'sm',
    };
    const durationProps: TypographyProps = {
      component: 'body3',
    };

    if (props.variant === 'message') {
      wrapperProps.style = {
        width: 300,
        maxWidth: '100%',
      };

      durationProps.color = 'gray';
    } else if (props.variant === 'record') {
      wrapperProps.gap = 'biggest';

      durationProps.color = 'blue';
      durationProps.component = 'h5';
    }

    return [wrapperProps, durationProps];
  }, [props.variant]);

  const durationToShow = Math.max(1000, props.durationPredefined || duration);

  return (
    <Row
      directionHorizontal
      {...wrapperProps}
      style={{ ...props.style, ...wrapperProps.style, gridTemplateColumns: '1fr auto' }}
      vertical='center'
    >
      <Row
        layout='flex'
        directionHorizontal
        gap='sm'
        vertical='center'
        autoColumns
        style={{ flexGrow: 1 }}
      >
        <Tooltip content={t(isPlaying ? 'stop' : 'start')}>
          <Button
            disabled={isFetching || isError}
            onClick={handleClickBtnTogglePlaying}
            style={{ flexShrink: 0 }}
            variant='circle-transparent'
            color='gray'
          >
            {
              (isPlaying)
                ? <FontIconView icon={AeFontIcons.playerStop} style={{
                  fontSize: '0.75rem',
                  width: '0.75rem',
                  height: '0.75rem',
                }} />
                : <FontIconView icon={AeFontIcons.play} style={{
                  fontSize: '0.85rem',
                  width: '0.75rem',
                  height: '0.85rem',
                }} />
            }
          </Button>
        </Tooltip>
        <Slider
          disabled={!duration || isError}
          value={currentTime}
          step={0.3}
          max={duration}
          onChange={handleChangeSlider}
          style={{ flexGrow: 1 }}
          color={props.sliderColor}
        />
      </Row>
      {durationToShow && (
        <Typography {...durationProps}>
          {formatDateToUiDate(
            // rounding to show '00:06' when duration is 5900ms.
            // rounding to show '00:01' when currentTime is 900ms.
            Math.round((isPlaying ? currentTime : durationToShow) / 1000) * 1000,
            'duration'
          )}
        </Typography>
      )}
    </Row>
  );
};

AudioPlayer.defaultProps = {
  variant: 'message',
};

export default AudioPlayer;
