import React, { ReactNode, useMemo } from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

import { Button, FontIconView, Typography } from '../../components';
import AeFontIcons from '../../assets/Icons';
import { isDev } from '../../utils/constants';
import PortalRootAfter from '../../components/PortalRootAfter';
import { FontIconViewIcon } from '../../assets/FontIconView';

import 'react-toastify/dist/ReactToastify.css';
import './ToasterEnhanced.scss';

type ToastEnhancedShowDuration = 'sm' | 'md';

interface ToastEnhancedOptions extends ToastOptions {
  showDuration?: ToastEnhancedShowDuration,
  titleTextLength?: number,
  bodyTextLength?: number,
}

interface ToastEnhancedContent {
  iconBeforeTitle?: FontIconViewIcon;
  title?: string | ReactNode;
  body?: string | ReactNode;
}

const getTextSizeByContentLength = (contentLength: number) => {
  let out: ToastEnhancedShowDuration;

  if (contentLength < 90) {
    out = 'sm';
  } else {
    out = 'md';
  }

  return out;
};

// eslint-disable-next-line no-unused-vars
const ShowDurationToAutoCloseMap: Record<ToastEnhancedShowDuration, number> = {
  'sm': 2000,
  'md': 3000,
};

// Enhances styles: adds custom close button and changes diff toast types messages colors.
export const ToasterContainerEnhanced = () => {
  return (
    <PortalRootAfter>
      <ToastContainer
        position='top-center'
        autoClose={ShowDurationToAutoCloseMap['sm']}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={({ closeToast }) => (
          <Button
            className='toast__close-btn'
            variant='transparent'
            color='gray'
            onClick={closeToast}
            iconBefore={AeFontIcons.close}
          />
        )}
      />
    </PortalRootAfter>
  );
};

export const toastEnhanced = ({
  title,
  body,
  iconBeforeTitle,
}: ToastEnhancedContent, options?: ToastEnhancedOptions) => {
  // AutoClose is needed to improve user reading experience,
  // by means of the regulating toast's duration based on the text size.
  let autoClose: number | boolean;

  if (options?.autoClose !== undefined) {
    autoClose = options.autoClose;
  } else if (options?.showDuration) {
    autoClose = ShowDurationToAutoCloseMap[options.showDuration];
  } else {
    let contentLength = 0;

    if (title) {
      if (typeof title === 'string') {
        contentLength += title.length;
      } else if (options?.titleTextLength) {
        contentLength += options.titleTextLength;
      } else {
        if (isDev) {
          // eslint-disable-next-line no-console
          console.log(
            '%cSpecify {titleTextLength}for the toast better reading experience!',
            'background: red; color: white;',
          );
        }
      }
    }

    if (body) {
      if (typeof body === 'string') {
        contentLength += body.length;
      } else if (options?.bodyTextLength) {
        contentLength += options.bodyTextLength;
      } else {
        if (isDev) {
          // eslint-disable-next-line no-console
          console.log(
            '%cSpecify {bodyTextLength}for the toast better reading experience!',
            'background: red; color: white;',
            `===${title}===`
          );
        }
      }
    }

    autoClose = ShowDurationToAutoCloseMap[getTextSizeByContentLength(contentLength)];
  }
  // ===========================

  // ToastId is needed to prevent toast duplicates.
  let toastId: string | number = '';

  if (options?.toastId) {
    toastId = options.toastId;
  } else {
    if (title && typeof title === 'string') {
      toastId += title;
    }

    if (body && typeof body === 'string') {
      toastId += body;
    }
  }
  // ===========================

  const jsxContent = (
    <>
      {
        title && (
          <Typography component='h5' className='toaster-enhanced_text-title'>
            {iconBeforeTitle && (
              <>
                <FontIconView icon={iconBeforeTitle} />
                &nbsp;
              </>
            )}
            {title}
          </Typography>
        )
      }
      {
        body && (
          <Typography component='body4' className='toaster-enhanced_text-body'>{body}</Typography>
        )
      }
    </>
  );

  if (toastId && toast.isActive(toastId)) {
    toast.update(toastId, {
      render: () => {
        return jsxContent;
      },
      progress: 0,
    });
  } else {
    toast(jsxContent, {
      ...options,
      type: options?.type || 'success',
      autoClose,
      toastId,
    });
  }
};

type ToastEnhancedHookType = () => {
  showToast: (content: ToastEnhancedContent, options?: ToastEnhancedOptions) => void,
};

export const useToastEnhanced: ToastEnhancedHookType = () => {
  return useMemo(() => {
    return {
      showToast: (content, options) => {
        toastEnhanced(content, options);
      },
    };
  }, []);
};
