import React, {forwardRef} from 'react';

import InputClean, { InputCleanProps } from './InputClean';
import InputWrapper, { InputWrapperProps } from './InputWrapper';

export type InputProps = InputCleanProps & {
  invalid?: boolean,
  slotBefore?:
    React.ReactNode | ((inputProps: Omit<InputCleanProps, 'className'>) => React.ReactNode),
  slotAfter?:
    React.ReactNode | ((inputProps: Omit<InputCleanProps, 'className'>) => React.ReactNode),
  wrapperProps?: InputWrapperProps,
};

const Input = forwardRef<HTMLInputElement, InputProps>(({
  invalid,
  slotBefore,
  slotAfter,
  wrapperProps = {},
  ...inputProps
}, ref) => {
  const { className: wrapperClassName,...restWrapperProps } = wrapperProps;

  return (
    <InputWrapper
      {...restWrapperProps}
      invalid={invalid}
      disabled={inputProps.disabled}
      size={inputProps.size}
      className={wrapperClassName}
    >
      {typeof slotBefore === 'function' ? slotBefore(inputProps) : slotBefore}
      <InputClean ref={ref} {...inputProps} />
      {typeof slotAfter === 'function' ? slotAfter(inputProps) : slotAfter}
    </InputWrapper>
  );
});

Input.defaultProps = {
  size: 'lg',
};

export default Input;
