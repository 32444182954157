import React  from 'react';
import classnames from 'classnames';

import { Row } from '../index';
import { RowProps } from '../row/Row';

import './ListItem.scss';

export interface ListItemProps extends RowProps {
  disabled?: boolean;
}

const ListItem: React.FC<ListItemProps> = (props) => {
  return (
    <Row
      tagName='li'
      {...props}
      className={classnames('list-item', props.className, {
        'disabled': props.disabled,
      })}
    />
  );
};

export default ListItem;
