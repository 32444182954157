import { useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useMainContext } from '../MainProvider';
import { useGetByIdWithCache } from '../hooks/useCache';
import { getChatListEvangelistsRequest } from '../api/chat';
import { useNotificationContext } from '../PushNotificationsProvider';
import { NO_NOTIFICATIONS_RE_FETCH_INTERVAL } from '../utils/constants';
import {
  GetQueryDataFn,
  PromiseResolveValue,
  SetQueryDataFnTyped,
  UpdateQueryDataFn,
  UpdateQueryDataFnWithStatus,
  UpdateQueryFnStatus,
} from '../types';
import { createChatItemUpdated, MessageFactors } from '../utils/chat';
import { EvangelistItemType, AdminEvangelistItem } from '../api/evangelists.types';
import {
  deleteEvangelistRequest, EvangelistsRequestParams,
  getEvangelistInfoRequest,
  getEvangelistsRequest,
  getLeaderEvangelistsRequest, getQuickEvangelistsRequest,
  ModifyEvangelistStatusData,
  modifyEvangelistStatusRequest
} from '../api/evangelists';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { RoleStatus, UserRoleStatus, UserRoleStatusValues } from '../utils/enums';
import {
  getIsRoleStatusActive,
  getIsRoleStatusExist,
  getIsRoleStatusPending
} from '../utils/profile';
import { SelectOption } from '../components/select/Select.types';
import { makeLabelFullName } from '../utils/common';
import { getContactCode } from '../utils/string';
import { setEvangelistVetterRequest } from '../api/vetters/vetters';
import { ItemFilter } from './common.types';

export const QUERY_KEY_CHAT_LIST_EVANGELISTS = 'chatListEvangelists';
export const QUERY_KEY_LEADER_EVANGELISTS = 'leaderEvangelists';
export const QUERY_KEY_EVANGELISTS = 'evangelists';
export const QUERY_KEY_QUICK_EVANGELISTS = 'quickEvangelists';
export const QUERY_KEY_EVANGELIST_INFO = 'evangelistInfo';

type QueryDataEvangelists = PromiseResolveValue<ReturnType<typeof getChatListEvangelistsRequest>>
  | undefined

const getQueryKeyLeaderEvangelists = (id?: string) => {
  return id ? [QUERY_KEY_LEADER_EVANGELISTS, id] : [QUERY_KEY_LEADER_EVANGELISTS];
};

export const getQueryDataEvangelists: GetQueryDataFn<QueryDataEvangelists> = (queryClient) =>
  queryClient.getQueryData(QUERY_KEY_CHAT_LIST_EVANGELISTS);

const setQueryDataChatListEvangelists: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getChatListEvangelistsRequest>>
  > = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_CHAT_LIST_EVANGELISTS, cb);
};

const setQueryDataEvangelists: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getEvangelistsRequest>>
  > = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_QUICK_EVANGELISTS, cb);
};

export const updateStatusEvangelistById: UpdateQueryDataFn<{
  evangelistId: string,
  status: RoleStatus,
}> = (queryClient, options) => {
  setQueryDataEvangelists(queryClient, (oldData) => {
    if (oldData?.evangelists.length) {
      const { evangelistId, status } = options;
      return {
        ...oldData,
        evangelists: oldData.evangelists.map((item) => {
          if (item.id === evangelistId) {
            return {
              ...item,
              status,
              roleStatus: Object.keys(item.roleStatus).reduce((acc, key) => {
                const typedKey = key as UserRoleStatusValues;
                const roleItem = item.roleStatus[typedKey];

                if (getIsRoleStatusExist(roleItem)) {
                  acc[typedKey] = status;
                } else {
                  acc[typedKey] = null;
                }

                return acc;
              }, {} as UserRoleStatus)
            };
          }
          return item;
        }),
      };
    }

    return oldData;
  }, undefined);
};

export const updateEvangelistVetterById: UpdateQueryDataFn<ModifyEvangelistVetterData> = (
  queryClient,
  options
) => {
  setQueryDataEvangelists(
    queryClient,
    (oldData) => {
      if (oldData?.evangelists.length) {
        const { evangelistId, vetterId } = options;

        return {
          ...oldData,
          evangelists: oldData.evangelists.map((item) => {
            if (item.id === evangelistId) {
              return {
                ...item,
                vetterId
              };
            }
            return item;
          }),
        };
      }

      return oldData;
    },
    undefined
  );
};

export const deleteEvangelistsById: UpdateQueryDataFn<{
  id: string,
}> = (queryClient, options) => {
  setQueryDataEvangelists(queryClient, (oldData) => {
    if (oldData?.evangelists.length) {
      const newEvangelists = oldData.evangelists.filter((item) => item.id !== options.id);

      return {
        ...oldData,
        numEvangelists: newEvangelists.length,
        evangelists: newEvangelists,
      };
    }

    return oldData;
  }, undefined);
};

export const updateEvangelistsFieldsById: UpdateQueryDataFn<
  { id: string, profile: AdminEvangelistItem }
  > = (queryClient, options) => {
  setQueryDataEvangelists(queryClient, (oldData) => {
    if (oldData?.evangelists) {
      return {
        ...oldData,
        evangelists: oldData.evangelists.map((item) => {
          if (item.id === options.id) {
            return {
              ...item,
              firstname: options.profile.firstname,
              lastname: options.profile.lastname,
              email: options.profile.email,
              phone: options.profile.phone,
            };
          }
          return item;
        }),
      };
    }

    return oldData;
  }, undefined);
};

export const updateChatListEvangelistItem: UpdateQueryDataFnWithStatus<{
  filter: ItemFilter<EvangelistItemType>
  newProperties?: Partial<EvangelistItemType>,
  messagesCountsFactors?: Partial<MessageFactors>,
  isRoleAdmin?: boolean,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';
  setQueryDataChatListEvangelists(queryClient, (oldQueryData) => {
    if (oldQueryData) {
      const {filter, newProperties, messagesCountsFactors} = options;

      return oldQueryData.map((item) => {
        if (typeof filter === 'string' ? item.id.toString() === filter : filter(item)) {
          status = 'success';

          return createChatItemUpdated(item, newProperties || {}, messagesCountsFactors);
        }

        return item;
      });
    }

    status = 'fail/no-data';

    return oldQueryData;
  }, undefined);

  return {
    status,
  };
};

export const updateAdminChatListEvangelistItem: UpdateQueryDataFnWithStatus<{
  filter: ItemFilter<AdminEvangelistItem>,
  newProperties?: Partial<AdminEvangelistItem>,
  messagesCountsFactors?: Partial<MessageFactors>,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';
  setQueryDataEvangelists(queryClient, (oldQueryData) => {
    if (oldQueryData) {
      const {filter, newProperties, messagesCountsFactors} = options;

      return {
        ...oldQueryData,
        evangelists: oldQueryData.evangelists.map((item) => {
          if (typeof filter === 'string' ? item.id === filter : filter(item)) {
            status = 'success';

            return createChatItemUpdated(item, newProperties || {}, messagesCountsFactors);
          }

          return item;
        }),
      };
    }

    status = 'fail/no-data';

    return oldQueryData;
  }, undefined);

  return {
    status,
  };
};

const getChatItemEvangelistId = (item: EvangelistItemType) => item.id.toString();

const leaderEvangelistsDefault: [] = [];
const evangelistsDefault: {
  numEvangelists: number,
  evangelists: [],
} = {
  numEvangelists: 0,
  evangelists: [],
};

// FIXME: Use or remove. "On the cards" it will be used.
export const updateLeaderEvangelistById: UpdateQueryDataFnWithStatus<{
  evangelistId: number,
  newProperties: Partial<EvangelistItemType>,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';

  setQueryDataChatListEvangelists(queryClient, (oldQueryData) => {
    if (oldQueryData) {
      const {evangelistId, newProperties} = options;

      return oldQueryData.map((item) => {
        if (item.id === evangelistId) {
          status = 'success';

          return { ...item, ...newProperties, };
        }

        return item;
      });
    }

    status = 'fail/no-data';

    return oldQueryData;
  }, undefined);

  return {
    status,
  };
};



export const useGetEvangelistInfo = (id?: string) => {
  const { userData } = useMainContext();

  const {
    isLoading,
    isError,
    data,
  } = useQuery([QUERY_KEY_EVANGELIST_INFO, id], () => getEvangelistInfoRequest(userData, id));

  return {
    isEvangelistInfoLoading: isLoading,
    isEvangelistInfoError: isError,
    evangelistInfo: data,
    contactCode: getContactCode(data),
  };
};

export const useGetLeaderEvangelists = (
  id?: string,
  config?: Omit<UseQueryOptions<EvangelistItemType[]>, 'queryKey' | 'queryFn'>
) => {
  const { userData } = useMainContext();

  const { isLoading, isError, refetch, data = leaderEvangelistsDefault } = useQuery(
    getQueryKeyLeaderEvangelists(id),
    () => getLeaderEvangelistsRequest(userData, id),
    config
  );

  const get = useGetByIdWithCache(QUERY_KEY_LEADER_EVANGELISTS, data, getChatItemEvangelistId);

  return {
    refetchLeaderEvangelists: refetch,
    isLeaderEvangelistsLoading: isLoading,
    isLeaderEvangelistsError: isError,
    getLeaderEvangelistById: get,
    leaderEvangelists: data
  };
};

const chatListEvangelistsDataDefault: [] = [];

export const useGetChatListEvangelists = (config?: {
  enabled?: boolean,
  refetchInterval?: number,
}) => {
  const {userData} = useMainContext();
  const {isAppReceivesNotifications} = useNotificationContext();

  const {
    isLoading,
    data = chatListEvangelistsDataDefault,
    refetch,
    isError,
  } = useQuery(QUERY_KEY_CHAT_LIST_EVANGELISTS, () => (
    getChatListEvangelistsRequest(userData)
  ), {
    refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
    staleTime: isAppReceivesNotifications ? Infinity : 0,
    ...config,
  });

  const get = useGetByIdWithCache(QUERY_KEY_CHAT_LIST_EVANGELISTS, data, getChatItemEvangelistId);

  return {
    isChatListEvangelistsError: isError,
    isChatListEvangelistsLoading: isLoading,
    getChatListEvangelistsItemById: isLoading ? () => undefined : get,
    chatListEvangelists: data,
    refetchChatListEvangelists: refetch,
  };
};

export const useGetEvangelists = (config?: {
  enabled?: boolean,
  refetchInterval?: number,
  limit?: number
} & Partial<EvangelistsRequestParams>) => {
  const { userData, isRoleAdmin } = useMainContext();

  const enabled = config?.enabled ?? isRoleAdmin;

  const queryKey = [QUERY_KEY_EVANGELISTS, (config?.limit || ''), (config?.offset || '')];

  const {
    isLoading,
    isError,
    isFetching,
    refetch,
    data = evangelistsDefault,
  } = useQuery(queryKey, () => getEvangelistsRequest(userData, config ? {
    limit: config.limit,
    offset: config.offset
  } : undefined), {
    enabled,
    refetchInterval: Infinity,
    ...config,
  });

  const get = useGetByIdWithCache(queryKey, data.evangelists);

  const { pendingEvangelists, activeEvangelists } = useMemo(() => {
    const pendingEvangelists = [];
    const activeEvangelists = [];

    for (let i = 0; i < data.evangelists.length; i++) {
      const evangelistItem = data.evangelists[i];

      if (getIsRoleStatusPending(evangelistItem.status)) {
        pendingEvangelists.push(evangelistItem);
      }
      if (getIsRoleStatusActive(evangelistItem.status)) {
        activeEvangelists.push(evangelistItem);
      }
    }
    return { pendingEvangelists, activeEvangelists };
  }, [data.evangelists]);

  const evangelistsOptions = useMemo(() => {
    const options: SelectOption[] = [];

    for (let i = 0; i < data.evangelists.length; i++) {
      const item = data.evangelists[i];

      if (getIsRoleStatusActive(item.status) && getIsRoleStatusActive(item.roleStatus.manager)) {
        options.push({ key: item.id, label: makeLabelFullName(item) });
      }
    }

    return options;
  }, [data.evangelists]);

  useEffect(() => {
    if (enabled && !data.evangelists.length) {
      refetch();
    }
  }, [enabled, config?.limit, config?.offset]);

  return {
    refetchEvangelists: refetch,
    isEvangelistsFetching: isFetching,
    isEvangelistsLoading: isLoading,
    isEvangelistsError: isError,
    getEvangelistById: get,
    evangelists: data.evangelists,
    numEvangelists: data.numEvangelists,
    pendingEvangelists,
    activeEvangelists,
    evangelistsOptions,
  };
};

export const useGetQuickEvangelists = (config?: {
  enabled?: boolean,
  refetchInterval?: number,
}) => {
  const { userData, isRoleAdmin } = useMainContext();

  const enabled = config?.enabled ?? isRoleAdmin;

  const queryKey = [QUERY_KEY_QUICK_EVANGELISTS];
  const cacheKey = QUERY_KEY_QUICK_EVANGELISTS;

  const {
    isLoading,
    isError,
    isFetching,
    refetch,
    data = evangelistsDefault,
  } = useQuery(queryKey, () => getQuickEvangelistsRequest(userData), {
    enabled,
    refetchInterval: Infinity,
    ...config,
  });

  const get = useGetByIdWithCache(cacheKey, data.evangelists);

  const { pendingEvangelists, activeEvangelists } = useMemo(() => {
    const pendingEvangelists = [];
    const activeEvangelists = [];

    for (let i = 0; i < data.evangelists.length; i++) {
      const evangelistItem = data.evangelists[i];

      if (getIsRoleStatusPending(evangelistItem.status)) {
        pendingEvangelists.push(evangelistItem);
      }
      if (getIsRoleStatusActive(evangelistItem.status)) {
        activeEvangelists.push(evangelistItem);
      }
    }
    return { pendingEvangelists, activeEvangelists };
  }, [data.evangelists]);

  const evangelistsOptions = useMemo(() => {
    const options: SelectOption[] = [];

    for (let i = 0; i < data.evangelists.length; i++) {
      const item = data.evangelists[i];

      if (getIsRoleStatusActive(item.status) && getIsRoleStatusActive(item.roleStatus.manager)) {
        options.push({ key: item.id, label: makeLabelFullName(item) });
      }
    }

    return options;
  }, [data.evangelists]);

  return {
    refetchEvangelists: refetch,
    isEvangelistsFetching: isFetching,
    isEvangelistsLoading: isLoading,
    isEvangelistsError: isError,
    getEvangelistById: get,
    evangelists: data.evangelists,
    numEvangelists: data.numEvangelists,
    pendingEvangelists,
    activeEvangelists,
    evangelistsOptions,
  };
};

type ModifyEvangelistVetterData = {
  evangelistId: string;
  vetterId: string;
}

export const useMutationEvangelists = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { userData } = useMainContext();
  const { showToast } = useToastEnhanced();

  const deleteEvangelist = useMutation((id: string) => (
    deleteEvangelistRequest(userData, id)
  ), {
    onSuccess: (data, id) => {
      deleteEvangelistsById(queryClient, { id });
      showToast({ title: t('successDeleteEvangelist') });
    }
  });

  const updateStatusEvangelist = useMutation((data: ModifyEvangelistStatusData) => (
    modifyEvangelistStatusRequest(userData, data)
  ), {
    onSuccess: (data, variables) => {
      updateStatusEvangelistById(queryClient, {
        evangelistId: variables.id,
        status: variables.status,
      });
      showToast({ title: t('changesSuccess') });
    }
  });

  const updateEvangelistVetter = useMutation(
    (data: ModifyEvangelistVetterData) =>
      setEvangelistVetterRequest(userData, data.evangelistId, data.vetterId),
    {
      onSuccess: (_, variables) => {
        updateEvangelistVetterById(queryClient, {
          evangelistId: variables.evangelistId,
          vetterId: variables.vetterId
        });
        showToast({ title: t('changesSuccess') });
      }
    }
  );

  return {
    updateStatusEvangelist,
    deleteEvangelist,
    updateEvangelistVetter
  };
};
