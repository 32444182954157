import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars-2';

import { useMainContext } from '../../MainProvider';
import { URLS } from '../../utils/constants';
import { Role, RoleStatus } from '../../utils/enums';
import { Backdrop, Button, Typography } from '..';
import { Tooltip } from '../tooltip/Tooltip';
import Divider from '../divider/Divider';
import Row from '../row/Row';
import { useUserStatusPendingPopup } from '../UserStatusPendingPopup';
import { getIsProfileFilled, getIsRoleStatusPending, isRoleDM, isRoleIncludesLeader } from '../../utils/profile';
import { useGetProfile } from '../../query/profile';
import { useModalContext } from '../modal/ModalProvider';
import AeFontIcons from '../../assets/Icons';
import FontIconView, { FontIconViewIcon } from '../../assets/FontIconView';

import './SideMenu.scss';

type SideMenuProps = {
  className: string,
};

const getIsSideMenuItemActive = (pathname: string, itemUrl: string): boolean => {
  return itemUrl === '/' ? pathname === itemUrl : pathname.includes(itemUrl);
};

type MenuItem = {
  url: string,
  label: string,
  icon: typeof AeFontIcons[keyof typeof AeFontIcons]
}

const MenuDmItems: MenuItem[] = [
  {
    url: URLS.main,
    label: 'mission',
    icon: AeFontIcons.mission,
  },
  {
    url: URLS.connect,
    label: 'connect',
    icon: AeFontIcons.connect,
  },
  {
    url: URLS.students,
    label: 'students',
    icon: AeFontIcons.student,
  },
  {
    url: URLS.teams,
    label: 'teams',
    icon: AeFontIcons.teams,
  },
  {
    url: URLS.profile,
    label: 'profile',
    icon: AeFontIcons.profile,
  },
  {
    url: URLS.settings,
    label: 'settings',
    icon: AeFontIcons.settings,
  },
];

const MenuLeaderCMItems: MenuItem[] = [
  {
    url: URLS.main,
    label: 'dashboard',
    icon: AeFontIcons.dashboard,
  },
  {
    url: URLS.teams,
    label: 'teams',
    icon: AeFontIcons.teams,
  },
  {
    url: URLS.connect,
    label: 'connect',
    icon: AeFontIcons.connectLeader,
  },
  {
    url: URLS.settings,
    label: 'settings',
    icon: AeFontIcons.settings,
  },
];

const MenuAdminItems: MenuItem[] = [
  {
    url: URLS.main,
    label: 'dashboard',
    icon: AeFontIcons.dashboard,
  },
  {
    url: URLS.teams,
    label: 'teams',
    icon: AeFontIcons.teams,
  },
  {
    url: URLS.admin,
    label: 'admin',
    icon: AeFontIcons.profile,
  },
  {
    url: URLS.api,
    label: 'api',
    icon: AeFontIcons.settings,
  },
  {
    url: URLS.connect,
    label: 'connect',
    icon: AeFontIcons.connectLeader,
  },
];

const SideMenu: React.FC<SideMenuProps> = ({ className }) => {
  const {
    logout,
    isMenuOpen,
    isSideMenuSliding,
    toggleMenu,
    setRole,
    userData: { roleStatus },
    isRoleLeader,
    isUiRoleNotDM,
    isUiRoleDM,
    isUiRoleCM,
    isRoleCM,
    isRoleLeaderCM,
    isRoleAdmin,
  } = useMainContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { openModal } = useModalContext();
  const { openUserStatusPendingPopup } = useUserStatusPendingPopup();
  const { profileData: { profile } } = useGetProfile();

  const renderMenuItem = (
    item: {
      url: string,
      label: string,
      icon: FontIconViewIcon,
    },
    action?: () => void,
    additionalClassName?: string,
  ) => {
    const label = t(item.label);

    return (
      <Tooltip
        key={item.label}
        content={label}
        className='side-menu-item'
      >
        <Link
          onClick={() => {
            if (isSideMenuSliding) {
              toggleMenu();
            }

            if (action) {
              action();
            }
          }}
          to={item.url}
          className={classNames(additionalClassName, {
            active: action ? false : getIsSideMenuItemActive(pathname, item.url)
          })}
        >
          <FontIconView className="side-menu-icon" icon={item.icon} />
          <span className="side-menu-item__label">{isMenuOpen && label}</span>
        </Link>
      </Tooltip>
    );
  };

  let menuItemsFiltered: MenuItem[];

  if (isRoleAdmin) {
    menuItemsFiltered = MenuAdminItems;
  } else if (isUiRoleDM) {
    menuItemsFiltered = MenuDmItems;
  } else {
    menuItemsFiltered = MenuLeaderCMItems;
  }

  const menuItemsElements = menuItemsFiltered.map((item) => renderMenuItem(item));

  const toggleRole = (role: Role) => {
    const { leader, manager } = roleStatus;

    if (
      role === Role.LEADER && getIsRoleStatusPending(leader) ||
      role === Role.CM && getIsRoleStatusPending(manager)
    ) {
      openUserStatusPendingPopup();
      return;
    }


    if (isRoleDM(role) && !getIsProfileFilled(profile, false, {
      // Explanation:
      // CASE: Switching from Leader GUI to DM GUI, and DM role has unfilled "pastorChurch" field.
      // #From Michael: Ignore pastorChurch field for leaders for above case.
      skipPastorChurchCheck: true,
    })) {
      openModal((
        <Typography component='body3' color='text-title'>
          {t('fillUpProfileMsg')}
        </Typography>
      ), {
        title: t('connectToMissionary'),
        widthSize: 'md',
        okButton: true,
        cancelButton: true,
        okButtonProps: {
          children: t('setProfile'),
          onClick: () => {
            setRole(role);
            return true;
          }
        },
      });
      return;
    }

    setRole(role);
  };

  const createStatusClassName = (roleStatus: RoleStatus) => {
    return getIsRoleStatusPending(roleStatus) ? 'inactive' : '';
  };

  const renderRoleButtons = () => {
    let content;

    if (isRoleAdmin) {
      return null;
    }

    const RoleButtonDM = (
      renderMenuItem({
        url: URLS.main,
        label: 'dmMode',
        icon: AeFontIcons.mission,
      },
      () => toggleRole(Role.DM),
      )
    );

    const RoleButtonLeader = (
      renderMenuItem({
        url: URLS.main,
        label: 'leaderMode',
        icon: AeFontIcons.leader,
      },
      () => toggleRole(Role.LEADER),
      createStatusClassName(roleStatus.leader),
      )
    );

    const RoleButtonCM = (
      renderMenuItem({
        url: URLS.main,
        label: 'cmMode',
        icon: AeFontIcons.leader,
      },
      () => toggleRole(Role.CM),
      createStatusClassName(roleStatus.manager),
      )
    );

    if (isRoleLeaderCM) {
      content = (
        <>
          {isUiRoleDM ? RoleButtonLeader : RoleButtonDM}
          {isUiRoleCM ? RoleButtonLeader : RoleButtonCM}
        </>
      );
    }

    if (isRoleLeader) {
      content = isUiRoleDM ? RoleButtonLeader : RoleButtonDM;
    }

    if (isRoleCM) {
      content = isUiRoleDM ? RoleButtonCM : RoleButtonDM;
    }

    return content && (
      <Row paddingVertical="extra-lg">
        <Divider color="white" />
        {content}
      </Row>
    );
  };

  return (
    <div className={className}>
      <div className={classNames('side-menu', {
        'is-open': isMenuOpen,
        'is-sliding': isSideMenuSliding,
        'leader': isUiRoleNotDM,
      })}>
        <div className='side-menu__head'>
          <Link to={'/'}>
            {isMenuOpen ? (
              <img src="/images/logo_app.svg" className="logo" alt={t('logo')} />
            ) : (
              <img src="/images/logo_app-sm.svg" className="logo" alt={t('logo')} />
            )}
          </Link>
          <Button
            className='side-menu__close-btn'
            variant='transparent'
            color='white'
            iconAfter={AeFontIcons.close}
            onClick={toggleMenu}
          />
        </div>
        <Scrollbars
          className="side-menu__content"
          renderView={(props) => <div {...props} className='side-menu__content__scrollbars' />}
        >
          <nav>
            {menuItemsElements}
            {renderRoleButtons()}
          </nav>
          {
            renderMenuItem({
              url: URLS.main,
              label: 'logout',
              icon: AeFontIcons.logout,
            }, logout)
          }
        </Scrollbars>
      </div>
      {
        isMenuOpen && (
          <Backdrop
            className='side-menu-backdrop'
            onClick={toggleMenu}
          />
        )
      }
    </div>
  );
};

export default SideMenu;
