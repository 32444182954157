import React, {CSSProperties} from 'react';
import classnames from 'classnames';

import FieldLabel, {FieldLabelProps} from './FieldLabel';
import {Typography} from '../../index';

import './Field.scss';

export type FieldSize = 'sm' | 'lg';

export type FieldProps = {
  showMarkRequired?: boolean;
  hasErrorGap?: boolean;
  labelText?: string;
  labelProps?: FieldLabelProps;
  name?: string;
  disabled?: boolean;
  errorText?: string;
  size?: FieldSize;
  style?: CSSProperties;
  children: React.ReactNode;
  tagName?: 'div' | 'label' | React.FC;
};

const Field: React.FC<FieldProps> = (props) => {
  const Tag = props.tagName ? props.tagName : 'label';

  return (
    <Tag
      className={classnames('field', props.size, {
        'has-error-gap': props.hasErrorGap,
      })}
      htmlFor={props.name}
      style={props.style}
    >
      {props.labelText && (
        <FieldLabel
          size={props.size}
          disabled={props.disabled}
          showMarkRequired={props.showMarkRequired}
          {...props.labelProps}
        >
          {props.labelText}
        </FieldLabel>
      )}

      {props.children}

      {
        props.errorText && (
          <Typography color='text-error' component='body4' className='field__text-error' threeDots>
            {props.errorText}
          </Typography>
        )
      }
    </Tag>
  );
};

export default Field;
