import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '../index';
import {ButtonProps} from './Button';
import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';

import './ButtonClose.scss';

const ButtonClose: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button minWidth='sm' className='btn-close' variant='opacity' size='sm' color='gray' {...props}>
      <FontIconView icon={AeFontIcons.close} />{t('close')}
    </Button>
  );
};

export default ButtonClose;
