export const arrayMove = <T = any>(arr: T[], fromIndex: number, toIndex: number) => {
  if (fromIndex === toIndex) {
    return arr;
  }

  return arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
};

export const findNode = <T extends { id: K, children?: T[] }, K = string>(
  children: T[],
  id: K,
  trace?: T[],
): T | null => {

  for (const child of children) {
    if (child.id === id) {
      if (trace) {
        trace.push(child);
      }

      return child;
    }

    if (child.children) {
      const result = findNode(child.children, id, trace);

      if (result) {
        if (trace) {
          trace.push(child);
        }

        return result;
      }
    }
  }

  return null;
};
