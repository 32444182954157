import React from 'react';
import ReactDOM from 'react-dom';

const elementRootAfter = document.getElementById('root-after');

class PortalRootAfter extends React.Component {
  el: HTMLDivElement

  constructor(props: any) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    elementRootAfter?.appendChild(this.el);
  }

  componentWillUnmount() {
    elementRootAfter?.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}

export default PortalRootAfter;
