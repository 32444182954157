const urlRegex = /(https?:\/\/[^\s]+)/gi;
// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const urlify = (text: string): string => {
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank" rel="noreferrer">' + url + '</a>';
  });
};

export const addQuotes = (text: string): string => {
  return '"' + text + '"';
};

export const addParentheses = (text: string): string => {
  return '(' + text + ')';
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const interpolateTextTemplate = (text: string, dmName: string | null, name?: string) => {
  return text
    .replaceAll(/({dm-name})/gi, dmName || 'anonymous')
    .replaceAll(/(, )?{name}(.|!)?/gi, (fullMatch, nameBefore = '', nameAfter = '') => {
      return (name ? (nameBefore + name) : '') + nameAfter;
    });
};

export const getContactCode = (string: string = '') => string.match(/<\/p>(.*?)<\/p>/)?.[1] || '';

export const getWordsFromString = (str: string): string[] | null => {
  if (!str) {
    return null;
  }

  return str.match(/\b(\w+)\b/g);
};
