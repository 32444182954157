import React, {CSSProperties, forwardRef} from 'react';
import classnames from 'classnames';

import './InputClean.scss';

export type InputSize = 'sm' | 'lg';

export type InputCleanProps = {
  readOnly?: boolean,
  disabled?: boolean,
  name?: string,
  type?: 'text' | 'email' | 'password' | 'number',
  autoComplete?: string,

  className?: string,
  style?: CSSProperties,
  size?: InputSize,

  tabIndex?: number;
  defaultValue?: string,
  value?: string,
  placeholder?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
  onClick?: (e: any) => void,
  onKeyDown?: (e: React.SyntheticEvent<HTMLInputElement, KeyboardEvent>) => void,
};

const InputClean = forwardRef<HTMLInputElement, InputCleanProps>(({
  className, size, onChange, ...restProps
}, ref) => {
  return (
    <input
      ref={ref}
      className={classnames('input-clean', className, size, {
        'disabled': restProps.disabled,
      })}
      onChange={(e) => {
        if (onChange) {
          onChange(e, e.currentTarget.value);
        }
      }}
      {...restProps}
    />
  );
});

export default InputClean;
