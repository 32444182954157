import React  from 'react';

import AeFontIcons from '../../assets/Icons';
import { FontIconView } from '../index';
import ListItem from './ListItem';
import { RowProps } from '../row/Row';

import './ListItemGo.scss';

interface ListItemGoProps extends RowProps {}

const ListItemGo: React.FC<ListItemGoProps> = (props) => {
  return (
    <ListItem
      paddingVertical='extra-lg'
      paddingHorizontal='sm'
      hover
      color='blue-opacity'
      horizontal='space-between'
      vertical='center'
      directionHorizontal
      autoColumns
      {...props}
    >
      <div>{props.children}</div>
      <FontIconView
        className="list-item-go_icon"
        icon={AeFontIcons.arrowLongRight}
      />
    </ListItem>
  );
};

export default ListItemGo;
