import { apiRequest } from './api';
import { Achievement } from '../components/achievements/types';
import { Course } from '../components/courses/types';
import { Role, UserRoleStatus } from '../utils/enums';
import { Profile, ProfileData } from './account.types';
import { ApiParamUserData } from './api.types';
import { isRoleAdmin, isRoleInlcudesCM } from '../utils/profile';

export type Country = {
  country: string, // Name of country
  abbreviation: string, // 2-letter country abbreviation
  code: number | null, // calling code
};

export type City = {
  id: number | string,
  city: string,
  country: string,
};

export type UserCountry = {
  id: string;
  country: string;
};

export type PostalCode = {
  id: number | string,
  postalCode: string,
};

export type UserLocations = {
  cities: City[];
  countries: UserCountry[];
  postalCodes: PostalCode[];
};

export type OneOfUserLocations = {
  [K in keyof UserLocations]: UserLocations[K]
};

export const resendEmailRequest = (userData: ApiParamUserData) =>
  apiRequest('resendVerifyEmail', {
    id: userData.id,
    agentToken: userData.agentToken,
  });

export const resetPassword = (email: string, language: string) =>
  apiRequest('requestResetEmail', { email, language });

export const registerRequest = (
  email: string,
  password: string,
  lang: string,
  agentType: string,
) => {
  return apiRequest<{
    role: Role;
    agentToken: string;
    id: string;
    roleStatus: UserRoleStatus;
  }>('createLogin', {
    username: email,
    password,
    sendVerifyEmail: true,
    verifyEmailLanguage: lang,
    agentType,
  });
};

type ProfileCategoryStatus = '1' | '0'

export type ProfileCategoryLimit = {
  categoryId: string,
  categoryLimit: string,
  currentRequests: string,
  id: string
  status: 'unverified'
  requestCount: string

  privateStatus: ProfileCategoryStatus
  teamStatus: ProfileCategoryStatus
  externalStatus: ProfileCategoryStatus
}

export type ProfileLanguages = string[]

export type ProfileCampaign = {
  activeSeekers: string
  campaignId: string
  inactiveSeekers: number
  totalSeekers: string
}

// Get evangelist profile information
export const getProfileRequest = (
  userData: ApiParamUserData,
  evangelistId: string = '',
  isRoleAdmin: boolean = false,
) => {
  let params: any = {
    countries: true,
    agentToken: userData.agentToken
  };

  if (evangelistId) {
    if (isRoleAdmin) {
      params.vetterId = userData.id;
      params.id = evangelistId;
    } else {
      params.leaderId = userData.id;
      params.id = evangelistId;
    }
  } else {
    params.id = userData.id;
  }

  return apiRequest<ProfileData>('getProfile', params).then((response) => {
    return {
      profile: response.profile,
      categories: response.categories,
      languages: response.languages,
      campaigns: response.campaigns,
    };
  });
};

// Login for evangelist given username and password
export const loginRequest = (email: string, password: string) => {
  return apiRequest<{
    role: Role;
    agentToken: string;
    id: string;
    roleStatus: UserRoleStatus;
    resetPassword?: boolean, // true if evangelist should change their password
  }>('login', { username: email, password });
};

// Sets/Gets Application initialization data
export const appInitializeRequest = (userData: ApiParamUserData, isUiRoleLeader: boolean, params: {
  countries: boolean;
  appSource: 'web' | 'android' | 'ios';
  appVersion: string;
  browserName?: string;
  browserVersion?: string;
}) => {
  return apiRequest('appInitialize', {
    agentToken: userData.agentToken,
    leaderId: isUiRoleLeader ? userData.id : undefined,
    id: isUiRoleLeader ? undefined : userData.id,
    ...params,
  });
};

export const getCountryInfoRequest = <T extends {
  countries?: boolean, // set "true" if countries list is needed
  cities?: string, // set as "country code" to get cities of that country.
}>(params: T) => {
  return apiRequest<{
    countries: keyof T extends 'countries' ?  Country[] : never,
    cities: keyof T extends 'cities' ?  string[] : never,
  }>('getCountryInfo', params);
};

export const getCountriesRequest = () => getCountryInfoRequest({
  countries: true,
}).then((response) => response.countries);

export const getCitiesByCountryRequest = (country: string) => getCountryInfoRequest({
  cities: country,
}).then((response) => response.cities);

// Get statistics about given evangelist
export const fetchEvangelistStats = (userData: ApiParamUserData) =>
  apiRequest('getEvangelistStats', userData);

// Get evangelist achievement info
export const getAchievementsRequest = (userData: ApiParamUserData) =>
  apiRequest<{
    achievements: Achievement[],
  }>('getAchievements', userData).then((response) => {
    return response.achievements;
  });

// Get evangelist training info
export const getCoursesRequest = (userData: ApiParamUserData) =>
  apiRequest<{
    trainings: Course[]
  }>('getTrainings', userData).then((response) => {
    return response.trainings;
  });

// Get training lesson info
export const fetchCoursesLessons = (userData: ApiParamUserData, trainingId: string) =>
  apiRequest('getTrainingLessons', { ...userData, trainingId }).then((response) => {
    return response.lessons;
  });

export const setNotificationTokenRequest = (
  userData: ApiParamUserData,
  appId: string,
  pushSubscription: PushSubscription,
  isRoleAdmin: boolean,
) => {
  const params: Record<string, any> = {
    evangelistId: userData.id,
    notificationToken: pushSubscription,
    agentToken: userData.agentToken,
    appId: appId
  };
  if (isRoleAdmin) {
    params.vetterId = userData.id;
  } else {
    params.id = userData.id;
  }
  return apiRequest('setNotificationToken', params);
};

export type CampaignMember = {
  id: string
  firstname: string | null
  lastname: string | null
  role: Role
  status: string
}

export type InvitedEvangelist = {
  email: string;
  language: string;
  campaignId?: string | number;
  campaignRole?: Role;
}

export type InvitedEvangelistAsLeader = {
  id: string;
  language: string;
  campaignId?: string | number;
  vetterId?: string;
  managerId?: string;
}

// Get list evangelists and leaders for campaign(s)
export const getCampaignMembersRequest = (campaignIds: string[]) =>
  apiRequest<{
    [field: string]: {
      members: CampaignMember[],
    },
  }>('getCampaignMembers', {
    campaignIds
  });

// Get text of DPA or AUP documents
export const fetchDocument = (document: 'aup' | 'dpa', language: string) =>
  apiRequest<{ text: string }>('getDocument', {
    document,
    language,
  });

export type SetProfileRequestCategory = {
  categoryId: ProfileCategoryLimit['categoryId'],
  categoryLimit?: ProfileCategoryLimit['categoryLimit'],
  privateStatus?: ProfileCategoryStatus
  teamStatus?: ProfileCategoryStatus
  externalStatus?: ProfileCategoryStatus
};

// Set evangelist profile information
// NOTE: Empty fields will overwrite profile data on the server.
export const setProfileRequest = (
  userData: ApiParamUserData,
  profile: Profile,
  evangelistId?: string,
  categories?: SetProfileRequestCategory[]
) => {
  let params: any = {
    id: userData.id,
  };

  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
    params.id = evangelistId;
  } else if (evangelistId) {
    params.leaderId = userData.id;
    params.id = evangelistId;
  }

  return apiRequest<{
    profile: Profile,
    categories: ProfileCategoryLimit[],
    languages: ProfileLanguages,
    campaigns?: ProfileCampaign[],
  }>('setProfile', {
    ...params,
    categories,
    profile,
    agentToken: userData.agentToken,
  });
};

// Get list of locations that that this evangelist has registered for
export const fetchUserLocations = (userData: ApiParamUserData) =>
  apiRequest<UserLocations>('getLocations', {
    id: userData.id,
    agentToken: userData.agentToken,
  }).then((response) => {
    return {
      cities: response.cities,
      countries: response.countries,
      postalCodes: response.postalCodes
    };
  });

export type RequestModifyLocationsPayload = {
  countries: {
    action: 'add' | 'delete',
    country: string, // 2-letter country code
  }[],
  cities: {
    action: 'add' | 'delete',
    city: string, // name of city
    country: string, // abbreviation of country
  }[],
  postalCodes: {
    action: 'add' | 'delete',
    postalCode: string, // name of postalCode
  }[],
};

// Modify the active locations for an evangelist
export const modifyUserLocationsRequest = (userData: ApiParamUserData, {
  countries = [], cities = [], postalCodes = [],
}: RequestModifyLocationsPayload) =>
  apiRequest('modifyLocations', {
    countries,
    cities,
    postalCodes,
    id: userData.id,
    agentToken: userData.agentToken,
  });

export const addOrDeleteLocationsRequest = (
  userData: ApiParamUserData,
  data: OneOfUserLocations,
  action: 'add' | 'delete'
) => {
  const { countries = [], cities = [], postalCodes = [] } = data;
  return modifyUserLocationsRequest(userData, {
    countries: countries.map((c: UserCountry) => ({ action, country: c.country })),
    cities: cities.map((c: City) => ({ action, city: c.city, country: c.country })),
    postalCodes: postalCodes.map((p: PostalCode) => ({ action, postalCode: p.postalCode })),
  });
};

export const getWebNotificationKeyRequest = (userData: ApiParamUserData) => {
  return apiRequest<{
    key: string, // web notification public key
  }>('getWebNotificationKey', {
    id: userData.id,
    agentToken: userData.agentToken,
  });
};

export const checkEmailRequest = (email: string) => apiRequest('checkEmail', { email });

export const inviteEvangelistOrLeaderRequest = (
  userData: ApiParamUserData,
  data: InvitedEvangelist,
) => (
  apiRequest('inviteEvangelist', {
    ...data,
    id: userData.id,
    agentToken: userData.agentToken,
  })
);

export const inviteExistedDmAsLeaderRequest = (
  userData: ApiParamUserData,
  data: InvitedEvangelistAsLeader,
) => {
  const params: InvitedEvangelistAsLeader = data;

  if (userData.role) {
    if (isRoleAdmin(userData.role)) {
      params.vetterId = userData.id;
    }
    if (isRoleInlcudesCM(userData.role)) {
      params.managerId = userData.id;
    }
  }

  return apiRequest('inviteLeader', {
    ...params,
    agentToken: userData.agentToken,
  });
};

export const sendCommandRequest = (
  userData: ApiParamUserData,
  cmdStr: string
) => {
  return apiRequest('sendCommand', {
    cmdStr,
    vetterId: userData.id,
    agentToken: userData.agentToken,
  });
};
