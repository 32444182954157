import React, {useEffect} from 'react';

import {FormValidator, useFormContext} from '../Form';

import Field, {FieldProps} from './Field';
import {InputProps} from '../../input/Input';
import {InputPhoneNumberProps} from '../../input/InputPhoneNumber';
import {InputTagsProps} from '../../input/InputTags';
import {SelectContainerProps} from '../../select/SelectContainer';
import {CheckboxProps} from '../../checkbox/Checkbox';
import {SelectListProps} from '../../select/SelectList';
import {TimePickerProps} from '../../time-picker/TimePicker.types';
import {MultiSelectListProps} from '../../multi-select/MultiSelect.types';
import {ReminderNoteProps} from '../../note/ReminderNote';
import {MultiSelectProps} from '../../multi-select/MultiSelect';
import {DatePickerProps} from '../../date-picker/DatePicker';
import {SeekerControlListProps} from '../../../pages/connect/broadcasts/SeekerControlList';
import {SeekerControlListAddProps} from '../../../pages/connect/broadcasts/SeekerControlListAdd';
import {TextAreaProps} from '../../textarea/TextArea';
import { useTranslation } from 'react-i18next';

export type FormFieldProps = Partial<FieldProps> & {
  component:
    React.FC<InputProps>
    | React.FC<InputPhoneNumberProps>
    | React.FC<InputTagsProps>
    | React.FC<SelectContainerProps>
    | React.FC<CheckboxProps>
    | React.FC<SelectListProps>
    | React.FC<TimePickerProps>
    | React.FC<MultiSelectListProps>
    | React.FC<ReminderNoteProps>
    | React.FC<MultiSelectProps>
    | React.FC<DatePickerProps>
    | React.FC<SeekerControlListProps>
    | React.FC<SeekerControlListAddProps>
    | React.FC<TextAreaProps>,
  componentProps?: Partial<SelectContainerProps
    | InputProps
    | InputPhoneNumberProps
    | InputTagsProps
    | CheckboxProps
    | SelectListProps
    | TimePickerProps
    | MultiSelectListProps
    | ReminderNoteProps
    | MultiSelectProps
    | DatePickerProps
    | SeekerControlListProps
    | SeekerControlListAddProps
    | TextAreaProps
    | {}>,
  validators?: FormValidator[],
};

const defaultValidators: [] = [];

const FormField: React.FC<FormFieldProps> = (props) => {
  const { t } = useTranslation();
  const {
    registerField,
    unregisterField,
    validationResults,
    touched,
    values,
    handleChange,
    handleFocus,
    handleBlur,
    wasSubmitted,
  } = useFormContext();

  const fieldName = props.name || '';
  const fieldError = validationResults[fieldName].fieldError;
  const fieldValue = values[fieldName];
  const showError = fieldError && (touched.has(fieldName) || wasSubmitted);

  const componentPropsExpanded: any = {
    ...props.componentProps,
    size: props.size,
    value: fieldValue,
    invalid: showError,
    onChange: (e: any, value: any) => {
      handleChange(fieldName, value);

      // @ts-ignore
      if (typeof props.componentProps?.onChange === 'function') {
        // @ts-ignore
        props.componentProps.onChange(e, value);
      }
    },
    onFocus: (e: React.FocusEvent<HTMLFormElement> & React.FocusEvent<HTMLLabelElement>) => {
      handleFocus(fieldName);

      // @ts-ignore
      if (typeof props.componentProps?.onFocus === 'function') {
        // @ts-ignore
        props.componentProps.onFocus(e);
      }
    },
    onBlur: (e: React.FocusEvent<HTMLFormElement> & React.FocusEvent<HTMLLabelElement>) => {
      handleBlur(fieldName);

      // @ts-ignore
      if (typeof props.componentProps?.onBlur === 'function') {
        // @ts-ignore
        props.componentProps.onBlur(e);
      }
    },
    children: props.children,
  };

  useEffect(() => {
    if (fieldName) {
      registerField(fieldName, props.validators || defaultValidators);
    }

    return () => {
      if (fieldName) {
        unregisterField(fieldName);
      }
    };
  }, [registerField, unregisterField, fieldName, props.validators]);

  const Component = props.component;

  return (
    <Field
      hasErrorGap={!!props.validators?.length}
      errorText={showError ? t(fieldError) : ''}
      {...props}
    >
      <Component {...componentPropsExpanded} />
    </Field>
  );
};

export default FormField;
