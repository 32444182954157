import React from 'react';
import classnames from 'classnames';

import { Button } from '../index';
import AeFontIcons from '../../assets/Icons';
import { ColorScheme } from '../../types';

import './Tag.scss';

type TagSize = 'extra-sm' | 'sm';
type TagVariant = 'opacity' | 'outline';
type TagColor = ColorScheme;

type TagProps = Omit<React.HTMLProps<HTMLDivElement>, 'size'> & {
  variant?: TagVariant,
  color?: TagColor,
  size?: TagSize,
  onRemove: () => void,
}

const Tag: React.FC<TagProps> = ({
  className,
  size,
  variant,
  color,
  children, onRemove, ...restProps
}) => {
  return (
    <div
      className={classnames('tag', className, size, variant, color)}
      onClick={onRemove}
      {...restProps}
    >
      <span className='tag_children'>{children}</span>
      <Button
        type='button'
        variant='transparent'
        color={color}
        className='tag_btn'
        iconBefore={AeFontIcons.close}
      />
    </div>
  );
};

Tag.defaultProps = {
  size: 'extra-sm',
  variant: 'opacity',
  color: 'gray'
};

export default Tag;
