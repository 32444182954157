export type StorageManagerReturnType<T> = {
  save: (data: T) => void,
  load: () => T | null,
  remove: () => void,
};

type StorageManagerCreator = <T = any>(key: string) => StorageManagerReturnType<T>;

export const createLocalStorageManager: StorageManagerCreator = (key) => {
  return {
    save: (data) => {
      localStorage.setItem(key, JSON.stringify(data));
    },
    load: () => {
      const dataString = localStorage.getItem(key) || 'null';

      try {
        return JSON.parse(dataString);
      } catch (error) {
        return null;
      }
    },
    remove: () => {
      localStorage.removeItem(key);
    }
  };
};

export const createSessionStorageManager: StorageManagerCreator = (key) => {
  return {
    save: (data) => {
      sessionStorage.setItem(key, JSON.stringify(data));
    },
    load: () => {
      const dataString = sessionStorage.getItem(key) || 'null';

      try {
        return JSON.parse(dataString);
      } catch (error) {
        return null;
      }
    },
    remove: () => {
      sessionStorage.removeItem(key);
    }
  };
};

export const createCookieStorageManager: StorageManagerCreator = (key) => {
  return {
    save: (data) => {
      document.cookie = `${key}=${JSON.stringify(data)};path=/;`;
    },
    load: () => {
      const value = `; ${document.cookie}`;

      const parts = value.split(`; ${key}=`);

      if (parts.length === 2) {
        const dataString = parts.pop()?.split(';').shift() || 'null';

        try {
          return JSON.parse(dataString);
        } catch (error) {
          return null;
        }
      }

      return null;
    },
    remove: () => {
      document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  };
};
