import React, { SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { isDev, URLS } from '../../../utils/constants';
import { Button, Checkbox, CriteriaList, Input, Row, Typography } from '../../../components';
import { FieldPasswordLogin } from '../../../components/form/Fields';
import { useModalContext } from '../../../components/modal/ModalProvider';
import { loginRequest } from '../../../api/account';
import { useToastEnhanced } from '../../../enhanced-components/toaster/ToasterEnhanced';
import { useMainContext } from '../../../MainProvider';
import useLongPress from '../../../hooks/useLongPress';
import { renderField } from '../../../components/custom-field/customField';

import './FormLogin.scss';

const accounts = [
  {
    email: 'yo@rollncode.com',
    password: 'Qwe12345',
  },
  {
    email: 'develyoper@gmail.com',
    password: 'Qwe12345',
  },
  {
    email: 'oa@rollncode.com',
    password: 'H5hhhhhh',
  },
  {
    email: 'sg@rollncode.com',
    password: 'Qwe12345',
  },
  {
    email: 'sv.g.qa1@gmail.com',
    password: 'Qwe12345',
  },
  {
    email: 'sv.g.qa3@gmail.com',
    password: 'Qwe12345',
  },
  {
    email: 'sv.g.qa5@gmail.com',
    password: 'Qwe12345',
  },
  {
    email: 'dmlen@rollncode.com',
    password: 'Qwerty123',
  },
  {
    email: 'shopen1692@gmail.com',
    password: 'Qwerty123',
  },
  {
    email: 'fsdf@geg.rt',
    password: 'Qwerty123',
  },
  {
    email: 'mt@rollncode.com',
    password: '1Qaz2Wsx',
  },
  {
    email: 'y.krl.qa1@gmail.com',
    password: 'Gg06944960',
  },
  {
    email: 'navij78733@noobf.com',
    password: 'Qwe12345',
  },
  {
    email: 'yofowo9772@herrain.com',
    password: 'Qwe12345',
  },
  {
    email: 'xejaf31941@nhmty.com',
    password: 'Qwe12345',
  },
  {
    email: 'aleks.rednaxela@gmail.com',
    password: 'H5hhhhhh',
  },
  {
    email: 'wepax76613@dedatre.com',
    password: 'Qwe12345',
  },
  {
    email: 'didak30551@fxseller.com',
    password: 'Qwe12345',
  },
  {
    email: 'bijikof145@mom2kid.com',
    password: 'Qwe12345',
  },
  {
    email: 'coheja1910@rebation.com',
    password: 'Qwe12345',
  },
  {
    email: 'depibe4272@shensufu.com',
    password: 'Qwe12345',
  },
  {
    email: 'vopebeh153@shensufu.com',
    password: 'Qwe12345',
  },
  {
    email: 'wetov45452@shensufu.com',
    password: 'Qwe12345',
  },
];

export type SignInFormData = {
  email: string,
  password: string,
  rememberMe: boolean,
}

const SignInValidationSchema = Yup.object().shape({
  email: Yup.string().email('errorInvalidEmail').required('errorFieldRequired'),
  password: Yup.string().required('errorFieldRequired'),
});

const FormLogin = () => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { openModal, closeModal } = useModalContext();
  const { handleAuthWithFetchedUserData } = useMainContext();

  const onLongPress = (e: any) => {
    handleContextMenuSignIn(e);
  };

  const longPressEvent = useLongPress(onLongPress, () => {}, {
    shouldPreventDefault: true,
    delay: 500,
  });

  const handleContextMenuSignIn = (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    if (isDev) {
      e.preventDefault();

      openModal((
        <ul>
          {accounts.map(({email, password}) => (
            <li key={email}>
              <Button
                onClick={async () => {
                  await onSubmit({ email, password, rememberMe: true });
                  closeModal();
                }}
                variant="transparent"
              >{email}</Button>
            </li>
          ))}
        </ul>
      ));
    }
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<SignInFormData>({
    resolver: yupResolver(SignInValidationSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    }
  });

  // show popup if invalid email
  useEffect(() => {
    if (errors?.email?.message === 'errorInvalidEmail') {
      const msg = t('msgInvalidEmail');
      showToast({
        title: t('errorInvalidEmail') + ':',
        body: <CriteriaList items={[{ completed: false, criteria: msg }]}/>,
      }, {
        type: 'error',
        bodyTextLength: msg.length,
      });
    }
  }, [t, errors, showToast]);

  const onSubmit: SubmitHandler<SignInFormData> = async (data) => {
    await loginRequest(data.email, data.password)
      .then(async (response) => {
        await handleAuthWithFetchedUserData({
          id: response.id,
          role: response.role,
          agentToken: response.agentToken,
          roleStatus: response.roleStatus,
        }, data.rememberMe);
      })
      .catch((e) => {
        showToast({ title: e.message || t('errorWrongSignin') }, { type: 'error' });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderField(Input, {
        ...register('email'),
        errorText: t(errors.email?.message || ''),
        placeholder: t('enterEmail'),
        labelText: t('labelEmail'),
        autoComplete: 'username',
        size: 'lg',
        showMarkRequired: true,
      })}
      <FieldPasswordLogin
        register={register}
        errors={errors}
        componentProps={{
          autoComplete: 'current-password',
        }}
      />
      <Row
        directionHorizontal
        autoColumns
        horizontal='space-between'
        style={{ paddingTop: '0.4rem' }}
      >
        {renderField(Checkbox, {
          ...register('rememberMe'),
          color: 'blue',
          size: 'sm',
          slotAfter: (
            <Typography component='body1' color='gray-dark'>{t('rememberMe')}</Typography>
          ),
          value: getValues('rememberMe'),
          onChange: (e) => {
            setValue('rememberMe', e.target.checked, { shouldValidate: true });
          }
        })}
        <Link to={URLS.loginRestore}>{t('forgotPassword')}</Link>
      </Row>
      <Button
        style={{ width: '100%' }}
        type="submit"
        onContextMenu={handleContextMenuSignIn}
        disabled={isSubmitting}
        {...longPressEvent}
      >
        {t('signIn')}
      </Button>
    </form>
  );
};

export default FormLogin;
