import { useCallback, useEffect, useState } from 'react';

import { useAudioPlayerContext } from '../components/audioplayer/AudioPlayerProvider';

// audio/webm;codecs=opus -> webm
// audio/ogg; codecs=opus -> ogg
// audio/mp4 -> mp4
const getFormatFromBlobType = (type: string): string | undefined => {
  return type.match(/\/(\w+);?/)?.[1];
};

export type RecordData = {
  blob: Blob,
  url: string,
  filename: string,
  duration: number,
};

let recordedDataChunks: any[] = [];

export const useRecordAudio = () => {
  const { pause } = useAudioPlayerContext();
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);

  const [isPreparingResult, setIsPreparingResult] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [recordTime, setRecordTime] = useState(0);

  const start = useCallback(() => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((audioRecordStream) => {
          // @ts-ignore
          let mediaRecorder = new MediaRecorder(audioRecordStream);

          mediaRecorder.addEventListener('start', () => {
            pause();
            setIsRecording(true);
            resolve(true);
          });

          mediaRecorder.addEventListener('dataavailable', (e: any) => {
            recordedDataChunks.push(e.data);
          });

          mediaRecorder.start();

          setMediaRecorder(mediaRecorder);
        })
        .catch(reject);
    });
  }, [pause]);

  const stop = useCallback(async (): Promise<RecordData> => {
    return await new Promise((resolve, reject) => {
      try {
        const stopHandler = () => {
          const recorderDataType = recordedDataChunks[0]?.type;
          const audioBlob = new Blob(recordedDataChunks, {
            type: recorderDataType,
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);

          // @see a way of getting the audio duration https://stackoverflow.com/a/59535002
          const handleAudioHasActualDuration = () => {
            audio.currentTime = 0.01;
            setIsPreparingResult(false);
            resolve({
              blob: audioBlob,
              filename: audioUrl.split('/').pop() + '.' + getFormatFromBlobType(recorderDataType),
              url: audioUrl,
              duration: audio.duration * 1000,
            });
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
          };

          const handleLoadedMetadata = () => {
            if (audio.duration === Infinity) {
              audio.currentTime = 1e101;
              audio.ontimeupdate = function() {
                audio.ontimeupdate = ()=> { return; };

                handleAudioHasActualDuration();
              };
            } else {
              handleAudioHasActualDuration();
            }
          };

          audio.addEventListener('loadedmetadata', handleLoadedMetadata);

          mediaRecorder.removeEventListener('stop', stopHandler);

          // Stop the record stream and remove the record icon from the tab.
          mediaRecorder.stream.getTracks().pop()?.stop();

          setIsPreparingResult(true);
          setIsRecording(false);
          setRecordTime(0);
          recordedDataChunks = [];
        };

        mediaRecorder.addEventListener('stop', stopHandler);
        mediaRecorder.stop();
      } catch (e) {
        reject(e);
      }
    });
  }, [mediaRecorder]);

  // Update {recordTime}. Should be removed and some CSS only stopwatch component used.
  useEffect(() => {
    let timerId: number;

    if (isRecording) {
      timerId = window.setTimeout(() => {
        setRecordTime(recordTime + 1000);
      }, 1000);
    } else {
      setRecordTime(0);
    }

    return () => {
      if (timerId) {
        clearTimeout();
      }
    };
  }, [isRecording, recordTime]);

  return {
    isRecording,
    isPreparingResult,
    startRecordAudio: start,
    stopRecordAudio: stop,
    recordTime
  };
};
