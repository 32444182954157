import {SourceDestType, SourceDestTypes} from '../api/chat.types';
import { AWRUserId, URLS } from './constants';

export const makePublicUrl = (url: string) => process.env.PUBLIC_URL + url;

export const makeChatUrlSeeker = (seekerId?: string, messageId?: string) => {
  if (!seekerId) {
    return URLS.seekers;
  }
  return URLS.seekers + '/' + seekerId + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrlAdminInterest = (interestId?: string, messageId?: string) => {
  if (!interestId) {
    return URLS.interests;
  }
  return URLS.interests + '/' + interestId + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrlBroadcast = (id?: string) => {
  if (!id) {
    return URLS.broadcasts;
  }
  return URLS.broadcasts + '/' + id;
};

export const makeChatUrlAwr = (isUiRoleDM: boolean) => {
  return isUiRoleDM ? makeChatUrlSeeker(AWRUserId) : URLS.awrHelp;
};

export const makeChatUrlAdminAwr = (id?: string, messageId?: string) => {
  if (!id) {
    return URLS.adminAwrHelp;
  }
  return URLS.adminAwrHelp + '/' + id + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrlInactiveSeeker = (seekerId?: string, messageId?: string) => {
  if (!seekerId) {
    return URLS.seekersInactive;
  }
  return URLS.seekersInactive + '/' + seekerId + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrlEvangelist = (dmId?: string, messageId?: string) => {
  if (!dmId) {
    return URLS.evangelistChats;
  }
  return URLS.evangelistChats + '/' + dmId + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrlLeader = (leaderId?: string, messageId?: string) => {
  if (!leaderId) {
    return URLS.leaderChats;
  }
  return URLS.leaderChats + '/' + leaderId + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrlCampaign = (campaignId?: string, messageId?: string) => {
  if (!campaignId) {
    return URLS.campaignChats;
  }
  return URLS.campaignChats + '/' + campaignId + (messageId ? ('?messageId=' + messageId) : '');
};

export const makeChatUrl = (
  recipientType: SourceDestType, isUiRoleNotDM: boolean, recipientId: string, messageId?: string
) => {
  if (recipientType === SourceDestTypes.SEEKER) {
    return makeChatUrlSeeker(recipientId, messageId);
  }

  if (recipientType === SourceDestTypes.EVANGELIST) {
    if (isUiRoleNotDM) {
      return makeChatUrlEvangelist(recipientId, messageId);
    }

    return makeChatUrlLeader(recipientId, messageId);
  }

  if (recipientType === SourceDestTypes.CAMPAIGN) {
    return makeChatUrlCampaign(recipientId, messageId);
  }

  return '';
};

export const makeStudentHistoryUrl = (studentId?: string) => {
  if (!studentId) {
    return URLS.students;
  }
  return URLS.students + '/' + studentId;
};
