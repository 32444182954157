import React, {CSSProperties, forwardRef} from 'react';
import classnames from 'classnames';

import {Color, TypographyVariant} from '../../types';

import './Typography.scss';

type TypographyTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'blockquote' | 'div';

export type TypographyComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  | 'subtitle1' | 'body1' | 'body2' | 'body3' | 'body4';
type TypographyAlign = 'left' | 'center' | 'right';

export const TypographyAlignToClassNameMap: Record<TypographyAlign, string> = {
  left: 'l',
  center: 'c',
  right: 'r',
};

export const TypographyComponentToTagMap: Record<TypographyComponent, TypographyTag> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h3',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'span',
};

export type TypographyProps = {
  className?: string,
  style?: CSSProperties & {
    '--clamp'?: number
  },
  threeDots?: boolean,
  clamp?: number,
  align?: TypographyAlign,
  component?: TypographyComponent,
  children?: React.ReactNode,
  variant?: TypographyVariant,
  color?: Color,
  tagName?: TypographyTag,
  dangerouslySetInnerHTML?: {
    __html: string;
  },
};

const Typography = forwardRef<any, TypographyProps>(({
  component, style, tagName, className,
  align, variant, color, clamp, threeDots,
  dangerouslySetInnerHTML, children,
  ...restProps
}, ref: any)  => {
  const Component = tagName || (component ? TypographyComponentToTagMap[component] : 'span');

  return (
    <Component
      style={{
        color: color ? `var(--${color})` : undefined,
        '--clamp': clamp ? clamp : undefined,
        ...style
      }}
      className={classnames(variant, className,
        component || 'typography',
        align ? TypographyAlignToClassNameMap[align] : undefined, {
          'three-dots': threeDots,
          'line-clamp': clamp,
        })}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...restProps}
      ref={ref}
    >
      {children}
    </Component>
  );
});

Typography.defaultProps = {
  variant: 'roboto',
};

export default Typography;
