import React, { useState } from 'react';

import { Row } from '../index';
import TabsHead from './TabsHead';

type TabsProps = {
  head: string[],
}

const Tabs: React.FC<TabsProps> = (
  { head, children, ...restProps }
) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Row gap='extra-lg' {...restProps}>
      <TabsHead items={head}  current={tabIndex} onClickTab={setTabIndex} />
      {React.Children.map(children, (child, index) => {
        if (index === tabIndex) {
          return child;
        }

        return null;
      })}
    </Row>
  );
};

export default Tabs;
