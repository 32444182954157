import React, { CSSProperties } from 'react';
import classnames from 'classnames';

import { AWRUserId, BroadcastAllId } from '../../utils/constants';
import { colors, getRandomColor } from '../../utils/theme';
import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';

import './UserIcon.scss';

const getUserText = (item: UserIconItem) => {
  let name = '';
  const withFirst = item.firstname?.length;
  const withLast = item.lastname?.length;

  if (withFirst) {
    name += item.firstname?.substr(0, 1);
  }

  if (withLast) {
    name += item.lastname?.substr(0, 1);
  } else if (withFirst) {
    name += item.firstname?.substr(1, 1);
  }

  if (!name) {
    name = '?';
  }

  return name;
};

const getColor = (id: string, inactive: boolean) => {
  if (!id || inactive) {
    return colors.grayLight;
  }

  return getRandomColor(id);
};

type UserIconItem = {
  id: string,// Seeker id
  firstname?: string | null, // Seeker firstname
  lastname?: string | null, // Seeker lastname
};

type UserIconType = 'outline' | 'solid';

export type UserIconProps = {
  item: UserIconItem,
  inactive?: boolean,
  variant?: UserIconType,
  size?: 'sm' | 'md',
  isBroadcast?: boolean,
}

const UserIcon: React.FC<UserIconProps> = ({
  isBroadcast, size, item, inactive, variant
}) => {
  const style: CSSProperties = {};

  let children, color;

  if (isBroadcast) {
    if (item.id === BroadcastAllId) {
      children = <FontIconView icon={AeFontIcons.crowd} />;
    } else {
      children = <FontIconView icon={AeFontIcons.broadcastFilled} />;
      color = getRandomColor(item.id);
    }
  } else if (item.id === AWRUserId) {
    children = <FontIconView icon={AeFontIcons.awrHelp} />;
  } else if (item.id === '') {
    children = <FontIconView icon={AeFontIcons.crowd} />;
  } else {
    color = getColor(item.id, inactive || false);
    children = getUserText(item);
  }

  if (variant === 'outline') {
    style.border = '0.2rem solid ' + color;
    style.color = color;
  } else if (variant === 'solid') {
    style.backgroundColor = color;
  }

  return (
    <div
      className={classnames('user-icon', size, {
        'awr': item.id === AWRUserId, // Awr chat item.
        'all': (item.id === '' || item.id === BroadcastAllId), // 'All' broadcast, requests.
        'broadcast': isBroadcast && item.id !== BroadcastAllId, // Broadcast, but not 'All'.
      })}
      style={style}
    >{children}</div>
  );
};

UserIcon.defaultProps = {
  variant: 'solid',
};

export default UserIcon;
