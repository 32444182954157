import React, {SyntheticEvent} from 'react';
import classnames from 'classnames';

import {ColorScheme} from '../../types';

import './TabButton.scss';

export type TabButtonProps = {
  type?: 'button' | 'submit' | 'reset',
  color?: ColorScheme,
  active: boolean,
  onClick?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void,
}

const TabButton: React.FC<TabButtonProps> = (props) => {
  return (
    <button
      className={classnames('tab-btn', props.color, {
        'active': props.active,
      })}
      onClick={props.onClick}
      type={props.type}
    >{props.children}</button>
  );
};

TabButton.defaultProps = {
  color: 'blue',
};

export default TabButton;
