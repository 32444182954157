import { StatelessFormValueUpdateEvent, SelectOption, SelectValue } from './Select.types';

export const parseSelectValueToPrimitive = (value: SelectValue): string => {
  return value ? (typeof value === 'object' ? value.key : value) : '';
};

export const parseSelectValueToSelectOption = (value: SelectValue): SelectOption => {
  return typeof value === 'string' ? { key: value, label: '', } : value;
};

export const makeStatelessFormValueUpdateEvent = (
  name = '', value: string, preventDefault = () => {},
): StatelessFormValueUpdateEvent => {
  return {
    target: { name, value, },
    preventDefault,
  };
};
