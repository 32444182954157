import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, TextArea, Typography } from '../index';
import { TextAreaProps } from '../textarea/TextArea';
import { formatDateToUiDate } from '../../utils/time';
import Note from './Note';
import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';
import {FieldProps} from '../form/field/Field';

export type ReminderNoteProps = {
  readOnly?: boolean,
  categoryName?: string,
  date: Date,
  value: string,
  onChange: TextAreaProps['onChange'],
  textAreaProps: Partial<TextAreaProps>,
  fieldProps?: Omit<FieldProps, 'component' | 'componentProps'>
};

const ReminderNote: React.FC<ReminderNoteProps> = ({
  categoryName,
  date,
  ...textAreaProps
}) => {
  const { t } = useTranslation();

  return (
    <Note
      head={(
        <Row directionHorizontal horizontal='space-between' autoColumns>
          <Typography component='h6' color='white'>
            <FontIconView icon={AeFontIcons.reminder} />&nbsp;{t('reminder')}
            {categoryName && (
              <>
                &nbsp;•&nbsp;{categoryName}
              </>
            )}
          </Typography>
          <Typography component='h6' color='white'>
            {formatDateToUiDate(date, 'reminder')}
          </Typography>
        </Row>
      )}
      body={(
        <TextArea
          textareaHeight='lg'
          wrapperProps={{
            style: {
              border: 'none',
            }
          }}
          {...textAreaProps}
        />
      )}
    />
  );
};

export default ReminderNote;
