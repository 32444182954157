import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button, Checkbox, Divider, Typography, UserIcon } from '../index';
import Row from '../row/Row';
import { PushNotificationItemType, useNotificationContext } from '../../PushNotificationsProvider';
import { useModalContext} from '../modal/ModalProvider';
import { Tooltip } from '../tooltip/Tooltip';
import AeFontIcons from '../../assets/Icons';
import { formatDateToUiDate, toLocalDateString } from '../../utils/time';
import { makeChatUrl } from '../../utils/url';
import { useMainContext } from '../../MainProvider';
import Scroll from '../scroll/Scroll';
import { makeMessageTextPlain } from '../../utils/chat';
import { isServiceWorkerSupported } from '../../utils/constants';

type ModalNotificationsProps = {};

const ModalNotifications: React.FC<ModalNotificationsProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isUiRoleNotDM } = useMainContext();
  const { closeModal } = useModalContext();

  const {
    isNotificationsInit,
    isPushPermissionGranted,
    areNotificationsEnabled,
    setAreNotificationsEnabled,
    areNotificationsSilent,
    setAreNotificationsSilent,
    notificationsList,
    setNotificationsList,
  } = useNotificationContext();

  const renderNotificationItem = useCallback((item: PushNotificationItemType) => {
    return (
      <Row
        key={item.messageId}
        tagName='li'
        directionHorizontal
        gap='md'
        autoColumns
        vertical='center'
        horizontal='space-between'
        hover
        color='role-color'
        paddingHorizontal='sm'
        paddingVertical='sm'
        style={{ gridTemplateColumns: 'auto 1fr auto' }}
        onClick={() => {
          history.push(makeChatUrl(item.sourceType, isUiRoleNotDM, item.sourceId, item.messageId));

          closeModal();
        }}
      >
        <UserIcon item={{
          id: item.sourceId,
          firstname: item.title,
        }} />
        <Row gap='sm'>
          <Row
            autoColumns
            directionHorizontal
            horizontal='space-between'
            gap='sm'
          >
            <Typography component='h4' threeDots>{item.title}</Typography>
            <Typography component='body3'>
              {formatDateToUiDate(toLocalDateString(item.date), 'message')}
            </Typography>
          </Row>
          <Typography component='body2' clamp={2}>{makeMessageTextPlain(item.text, t)}</Typography>
        </Row>

        <Tooltip content={t('remove')}>
          <Button
            color='red'
            variant='outline'
            iconAfter={AeFontIcons.trash}
            onClick={(e) => {
              setNotificationsList((oldData) => {
                return oldData ? oldData.filter((notification) => {
                  return notification.id !== item.id;
                }) : [];
              });

              e.stopPropagation(); // Prevent open chat on 'remove' btn click.
            }}
          />
        </Tooltip>
      </Row>
    );
  }, [closeModal, isUiRoleNotDM, history, t, setNotificationsList]);

  const [notificationListViewed, notificationListNotViewed] = useMemo(() => {
    const viewedNotificationList = [];
    const notViewedNotificationList = [];

    for (let i = 0; i < notificationsList.length; i++) {
      const item = notificationsList[i];

      if (item.viewed) {
        viewedNotificationList.push(item);
      } else {
        notViewedNotificationList.push(item);
      }
    }

    return [viewedNotificationList, notViewedNotificationList];
  }, [notificationsList]);

  return (
    <Row gap='md'>
      <Typography component='h3'>{t('myNotifications')} ({notificationsList.length})</Typography>
      <Scroll style={{ maxHeight: 300, margin: '0 -0.55rem', width: 'auto', }} staticScrollView>
        <ul>
          {!notificationsList.length && (
            <Typography
              align='center'
              component='h4'
              color='text-title-light'
            >{t('noNotifications')}</Typography>
          )}
          {notificationListNotViewed.map(renderNotificationItem)}
          {notificationListViewed.map(renderNotificationItem)}
        </ul>
      </Scroll>
      {
        notificationsList.length ? (
          <Button style={{ justifySelf: 'end' }} variant='link' color='blue' onClick={() => {
            setNotificationsList([]);
          }}>{t('clearAll')}</Button>
        ) : ''
      }
      <Divider>{t('settings')}</Divider>
      <Row directionHorizontal horizontal='start' gap='md' autoColumns>
        <Checkbox
          disabled={!isPushPermissionGranted}
          slotAfter={<Typography
            component='h5'
            color='text-title-light'
          >{t('notifications')}</Typography>}
          value={areNotificationsEnabled}
          onChange={(e, value) => {
            setAreNotificationsEnabled(value);
          }}
        />
        <Checkbox
          disabled={!isPushPermissionGranted}
          slotAfter={<Typography
            component='h5'
            color='text-title-light'
          >{t('doNotDisturb')}</Typography>}
          value={areNotificationsSilent}
          onChange={(e, value) => {
            setAreNotificationsSilent(value);
          }}
        />
      </Row>
      {
        isNotificationsInit && (!isServiceWorkerSupported || !isPushPermissionGranted) && (
          <Typography component='body2' color='red'>
            {t(!isServiceWorkerSupported ? 'notificationsNoSupport' : 'notificationsDisabled')}
          </Typography>
        )
      }
    </Row>
  );
};

const useModalNotifications = () => {
  const { openModal } = useModalContext();

  const openModalNotifications = () => {
    openModal(<ModalNotifications />);
  };

  return {openModalNotifications};
};

export default useModalNotifications;
