import React  from 'react';

import Button from '../button/Button';
import AeFontIcons from '../../assets/Icons';
import { FontIconView } from '../index';
import ListItem, { ListItemProps } from './ListItem';

interface ListItemRemoveProps extends ListItemProps {
  disabled?: boolean;
  onClickRemove: () => void;
}

const ListItemRemove: React.FC<ListItemRemoveProps> = ({
  disabled, onClickRemove, children, ...restProps
}) => {
  return (
    <ListItem
      disabled={disabled}
      paddingVertical='extra-lg'
      directionHorizontal
      autoColumns
      horizontal='start'
      vertical='center'
      {...restProps}
    >
      <Button
        variant='transparent'
        color='red'
        iconBefore={<FontIconView icon={AeFontIcons.delete} />}
        style={{
          paddingTop: '0.1rem',
          paddingBottom: 0
        }}
        onClick={onClickRemove}
      />
      &nbsp;&nbsp;
      {children}
    </ListItem>
  );
};

export default ListItemRemove;
