import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';

import {createLocalStorageManager} from './utils/storage';
import { StorageManagerSetFn, useStorageManager } from './hooks/useStorageManager';
import {SourceDestType} from './api/chat.types';
import {isServiceWorkerSupported} from './utils/constants';

const PUSH_NOTIFICATIONS_LENGTH_MAX = 10;

const readNotificationPermission = (
  onSuccess: (permission: string) => void,
  onError: (message: string) => void,
) => {
  if ('Notification' in window) {
    try {
      // cb + then straightaway approach to prevent couple permission requests,
      // multi-requests cause ad-block browser extensions to consider app as the scam one.
      Notification.requestPermission(function(permission) {
        onSuccess(permission);
      }).then((permission) => {
        onSuccess(permission);
      });
    } catch (e) {
      onError('Error during request notification permission.');
    }
  } else {
    onError('Notification object is not supported by browser.');
  }
};

type PushNotificationsProviderProps = {
  isNotificationsInit: boolean;

  isPushPermissionGranted: boolean;

  isAppReceivesNotifications: boolean;
  setIsAppReceivesNotifications: (newState: boolean) => void;

  areNotificationsSilent: boolean;
  setAreNotificationsSilent: (newState: boolean) => void;

  areNotificationsEnabled: boolean;
  setAreNotificationsEnabled: (newState: boolean) => void;

  notificationsList: PushNotificationItemType[],
  setNotificationsList: StorageManagerSetFn<PushNotificationItemType[]>;
};

const PushNotificationsContext =
  createContext<PushNotificationsProviderProps>({} as PushNotificationsProviderProps);

type PushNotificationsSettingsStorage = {
  areNotificationsSilent: boolean,
  areNotificationsEnabled: boolean,
};

export type PushNotificationItemType = {
  id: string,
  title: string,
  text: string,
  viewed: boolean,
  messageId: string,
  date: string,
  sourceId: string,
  sourceType: SourceDestType,
};

const pushNotificationsListLS =
  createLocalStorageManager<PushNotificationItemType[]>('pn/list');
const pushNotificationsConfigLS =
  createLocalStorageManager<PushNotificationsSettingsStorage>('pn/config');

const pushNotificationListDefault: [] = [];

const PushNotificationsProvider: React.FC = (props) => {
  const [isNotificationsInit, setIsNotificationsInit] = useState(false);
  const [isPushPermissionGranted, setIsPushPermissionGranted] = useState(false);
  const [isAppReceivesNotifications, setIsAppReceivesNotifications] = useState(false);

  const {
    value: pushNotificationsList,
    updateValue: setNotificationsList,
  } = useStorageManager(pushNotificationsListLS);
  const {
    value: pushNotificationsConfig,
    updateValue: setNotificationsConfig,
  } = useStorageManager(pushNotificationsConfigLS);

  const setAreNotificationsSilent = useCallback((newValue: boolean) => {
    setNotificationsConfig({
      areNotificationsEnabled: pushNotificationsConfig?.areNotificationsEnabled || true,
      areNotificationsSilent: newValue,
    });
  }, [setNotificationsConfig, pushNotificationsConfig?.areNotificationsEnabled]);

  const setAreNotificationsEnabled = useCallback((newValue: boolean) => {
    setNotificationsConfig({
      areNotificationsSilent: pushNotificationsConfig?.areNotificationsSilent || false,
      areNotificationsEnabled: newValue,
    });
  }, [setNotificationsConfig, pushNotificationsConfig?.areNotificationsSilent]);

  useEffect(() => {
    const handleInitSuccess = (isPushPermissionGranted: boolean) => {
      setIsPushPermissionGranted(isPushPermissionGranted);
      setIsNotificationsInit(true);
    };

    if (isServiceWorkerSupported) {
      readNotificationPermission((pushPermission) => {
        handleInitSuccess(pushPermission === 'granted');
      }, (message) => {
        handleInitSuccess(false);

        // eslint-disable-next-line no-console
        console.log('%c' + message, 'background: red; color: white;');
      });
    } else {
      handleInitSuccess(false);

      // eslint-disable-next-line no-console
      console.log(
        '%cService worker is not available!\n'
        + 'Either browser does not support SW or current domain is not "https"!',
        'background: red; color: white;'
      );
    }
  }, []);

  // Slice notifications list to max allowed length to prevent large list.
  useEffect(() => {
    if (pushNotificationsList && pushNotificationsList.length > PUSH_NOTIFICATIONS_LENGTH_MAX) {
      setNotificationsList(pushNotificationsList.slice(0, PUSH_NOTIFICATIONS_LENGTH_MAX));
    }
  }, [pushNotificationsList, setNotificationsList]);

  return (
    <PushNotificationsContext.Provider value={{
      isNotificationsInit,
      isPushPermissionGranted,

      isAppReceivesNotifications,
      setIsAppReceivesNotifications,

      areNotificationsSilent: pushNotificationsConfig
        ? pushNotificationsConfig?.areNotificationsSilent
        : false,
      setAreNotificationsSilent,

      areNotificationsEnabled: pushNotificationsConfig
        ? pushNotificationsConfig.areNotificationsEnabled
        : true,
      setAreNotificationsEnabled,

      notificationsList: pushNotificationsList || pushNotificationListDefault,
      setNotificationsList,
    }}>{props.children}</PushNotificationsContext.Provider>
  );
};

export const useNotificationContext = () => useContext(PushNotificationsContext);


export default PushNotificationsProvider;
