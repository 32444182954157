import React from 'react';
import { BannerWelcome } from '../components';

import './LoadingLayout.scss';
import { useTranslation } from 'react-i18next';

const LoadingLayout = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className="layout-loading">
      <BannerWelcome className='layout-loading__banner'>
        <h2>{t('loading')}</h2>
      </BannerWelcome>
    </div>
  );
});

export default LoadingLayout;
