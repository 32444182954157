import React, {
  CSSProperties,
  EventHandler,
  ReactNode, useCallback,
  useEffect, useRef,
  useState
} from 'react';
import classnames from 'classnames';

import PortalRootAfter from '../PortalRootAfter';
import { Backdrop } from '../index';
import {useListenKey} from '../../hooks/useListenKey';

import './Popup.scss';

export const usePopup = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopup: EventHandler<any> = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closePopup = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isShown = !!anchorEl;

  return { anchorEl, isOpen: isShown, openPopup, closePopup };
};

type PopupProps = {
  minHeight?: number,
  preferOpenToBottom: boolean,
  isShown: boolean,
  innerProps?: any,
  backdropProps?: React.HTMLProps<HTMLDivElement>,
  anchorEl: HTMLElement | null,
  boundaryRect?: DOMRect,
  children: ReactNode,
  onHide: () => void,
};

const Popup: React.FC<React.HTMLProps<HTMLDivElement> & PopupProps> = ({
  isShown, preferOpenToBottom = true, minHeight = 128,
  innerProps = {}, backdropProps = {},
  anchorEl, boundaryRect,
  onHide, children, ...restProps
}) => {
  const recheckPositionAfterResizeTimerRef = useRef(-1);
  const [innerStyle, setInnerStyle] = useState<CSSProperties | null>(null);
  const { className: classNameInner, style: styleInner, ...restPropsInner } = innerProps;
  const { className: classNameBackdrop, ...restPropsBackdrop } = backdropProps;

  useListenKey({
    enabled: isShown,
    key: 'Escape',
    onKeyDown: () => {
      onHide();
    },
  });

  useEffect(() => {
    const updatePosition = () => {
      if (anchorEl) {
        let _boundaryRect =
          boundaryRect
            ? boundaryRect
            // Fallback boundary rect. Should be got newly every time for correct calculation.
            : new DOMRect(0, 0, window.innerWidth, window.innerHeight);

        const anchorElRect = anchorEl.getBoundingClientRect();
        const style: CSSProperties = {};

        const deltaTop = Math.min(anchorElRect.top - _boundaryRect.top, anchorElRect.top);
        const deltaLeft = Math.min(anchorElRect.right - _boundaryRect.left, anchorElRect.right);
        const deltaRight = Math.min(
          _boundaryRect.right - anchorElRect.left,
          window.innerWidth - anchorElRect.left
        );
        const deltaBottom = Math.min(
          _boundaryRect.bottom - anchorElRect.bottom,
          window.innerHeight - anchorElRect.bottom
        );

        const onBottom = preferOpenToBottom
          ?
          // Show on bottom only if delta more than {minHeight}, to popup be usable.
          deltaBottom > minHeight
          // else if delta less than {minHeight}, show on top if top delta is greater than bottom.
          || deltaTop < deltaBottom
          : deltaTop > deltaBottom;
        const leftSide = deltaLeft > deltaRight;

        if (onBottom) {
          style.top = anchorElRect.bottom + 'px';
        } else {
          style.bottom = (window.innerHeight - anchorElRect.top) + 'px';
        }

        if (leftSide) {
          style.right = (window.innerWidth - anchorElRect.right) + 'px';
        } else {
          style.left = anchorElRect.left + 'px';
        }

        style.maxWidth = leftSide ? deltaLeft + 'px' : deltaRight + 'px';
        style.maxHeight = onBottom ? deltaBottom + 'px' : deltaTop + 'px';

        setInnerStyle(style);
      }
    };

    const handleSetPositionInvalidation = () => {
      updatePosition();

      clearTimeout(recheckPositionAfterResizeTimerRef.current);

      // --- Check if wrong popup position after the keyboard is opened --- //
      const anchorElBoundaryRect = anchorEl?.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      recheckPositionAfterResizeTimerRef.current = window.setTimeout(() => {
        const isAnchorRectUpdated = anchorElBoundaryRect
          ? anchorElBoundaryRect.toJSON() !== anchorEl?.getBoundingClientRect().toJSON()
          : false;

        if (isAnchorRectUpdated || windowHeight !== window.innerHeight) {
          updatePosition();
        }
      }, 500);
      // ----------------------------------- //
    };

    if (isShown) {
      handleSetPositionInvalidation();

      window.addEventListener('resize', handleSetPositionInvalidation);
      window.addEventListener('scroll', updatePosition);
    }

    return () => {
      clearTimeout(recheckPositionAfterResizeTimerRef.current);
      setInnerStyle(null);

      if (isShown) {
        window.removeEventListener('resize', handleSetPositionInvalidation);
        window.removeEventListener('scroll', updatePosition);
      }
    };
  }, [minHeight, onHide, preferOpenToBottom, anchorEl, boundaryRect, isShown]);

  return (
    <PortalRootAfter>
      <div
        className={classnames('popup', {
          'open': isShown && innerStyle,
        })}
        {...restProps}
      >
        <Backdrop
          className={classnames('popup__backdrop', classNameBackdrop)}
          onClick={() => {
            onHide();
          }}
          {...restPropsBackdrop}
        />
        {
          innerStyle && (
            <div
              style={{ ...innerStyle, ...styleInner }}
              className={classnames('popup__inner', classNameInner)}
              {...restPropsInner}
            >{children}</div>
          )
        }
      </div>
    </PortalRootAfter>
  );
};

export default Popup;
