import {
  ChatItemLeaderCMType,
  ChatItemLeaderCMTypeApi,
  MessageHistoryItemApi,
  GetMessageUpdatesRequestMessage,
  MessageItemType,
  SendMessageFromEvangelistMsgData
} from './chat.types';
import { getMessageTextClearUrlified } from '../utils/chat';
import {
  ChatItemCampaignType,
  ChatItemAwrCampaignTypeApi, ChatItemCampaignTypeApi
} from './campaigns.types';

export const normalizeMessageHistoryItem = (
  message: MessageHistoryItemApi,
): MessageItemType => {
  return {
    ...message,
    textClearUrlified: getMessageTextClearUrlified(message.text),
  };
};

export const normalizeGetMessageUpdatesItem = (
  message: GetMessageUpdatesRequestMessage, userId: string,
): MessageItemType => {
  return {
    ...message,
    direction: message.destId === userId ? 'from' : 'to',
    // May be wrong.
    sendId: message.destId === userId ? message.sourceId : userId,
    sendDate: message.date,
    textClearUrlified: getMessageTextClearUrlified(message.text),
  };
};

export const normalizeSendMessageFromEvangelistMsgData = (
  message: SendMessageFromEvangelistMsgData, userId: string,
): MessageItemType => {
  return {
    ...message,
    direction: 'to',
    // May be wrong.
    sendId: message?.destId === userId ? message.sourceId : userId,
    sendDate: message?.date,
    textClearUrlified: getMessageTextClearUrlified(message?.text || ''),
  };
};

export const normalizeChatItemLeaderCM = (item: ChatItemLeaderCMTypeApi): ChatItemLeaderCMType => {
  const { msgCounts, lastMessage, ...restProperties } = item;
  return {
    ...restProperties,
    date: lastMessage?.date || '',
    text: lastMessage?.text || '',
    sourceType: lastMessage?.sourceType || null,
    unread: Number(msgCounts.unread),
    outgoing: Number(msgCounts.outgoing),
    incoming: Number(msgCounts.incoming),
    ungraded: Number(msgCounts.ungraded),
  };
};

export const getIsChatItemCampaignTypeApi = (
  item: ChatItemAwrCampaignTypeApi | ChatItemCampaignTypeApi
): item is ChatItemCampaignTypeApi => {
  return !('evangelistId' in item);
};

export const normalizeChatItemCampaign = (item: ChatItemCampaignTypeApi): ChatItemCampaignType => {
  return {
    campaignId: item.campaignId,
    name: item.name,
    date: item.lastMessage?.date || '',
    text: item.lastMessage?.text || '',
    audio: item.lastMessage?.text || '',
    role: item.role,
    unread: Number(item.unread),
    incoming: Number(item.incoming),
    outgoing: 0, // for consistency
    ungraded: 0, // for consistency
  };
};
