import { useMainContext } from '../MainProvider';
import { useQuery } from 'react-query';
import { useGetByIdWithCache } from '../hooks/useCache';
import { getCoursesRequest } from '../api/account';
import { PromiseResolveValue, SetQueryDataFnTyped, UpdateQueryDataFn } from '../types';
import { Course } from '../components/courses/types';

export const QUERY_KEY_COURSES = 'courses';

const setQueryDataCourses: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getCoursesRequest>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_COURSES, cb);
};

export const updateCourseItemById: UpdateQueryDataFn<{
  trainingId: string;
  newProperties: Partial<Course>;
}> = (queryClient, options) => {
  setQueryDataCourses(queryClient, (oldData) => {
    if (oldData) {
      const { trainingId } = options;
      return oldData.map((item) => {
        if (item.trainingId === trainingId) {
          return {
            ...item,
            ...options.newProperties,
          };
        }

        return item;
      });
    }

    return [];
  }, undefined);
};

const coursesDefault: [] = [];

const getCourseId = (item: Course) => item.trainingId;

export const useGetCourses = () => {
  const { userData } = useMainContext();

  const {
    data = coursesDefault,
    isLoading,
    isError,
    refetch,
  } = useQuery(QUERY_KEY_COURSES, () => getCoursesRequest(userData));

  const get = useGetByIdWithCache(QUERY_KEY_COURSES, data, getCourseId);

  return {
    isCoursesLoading: isLoading,
    isCoursesError: isError,
    courses: data,
    getCourseById: get,
    refetchCourses: refetch,
  };
};
