import React, {forwardRef} from 'react';

import SelectContainer, {SelectContainerProps} from './SelectContainer';
import {Button} from '../index';
import SelectArrow from './SelectArrow';
import {ButtonProps} from '../button/Button';

type SelectVariantButtonProps = Omit<SelectContainerProps, 'renderInput'> & {
  buttonProps?: Partial<ButtonProps>,
};

const SelectVariantButton = forwardRef<HTMLButtonElement, SelectVariantButtonProps>((
  props, ref
) => {
  return (
    <SelectContainer
      {...props}
      renderInput={(inputProps) => {
        return (
          <Button
            color='text-title-light'
            variant='transparent'
            isActive={inputProps.isOpen}
            onClick={inputProps.openSelect}
            iconAfter={<SelectArrow isRotated={inputProps.isOpen} />}
            {...props.buttonProps}
            // eslint-disable-next-line react/no-children-prop
            children={props.buttonProps?.children || inputProps.value}
            ref={ref}
          />
        );
      }}
    />
  );
});

export default SelectVariantButton;
