import { useQuery } from 'react-query';

import {useMainContext} from '../MainProvider';
import {useGetByIdWithCache} from '../hooks/useCache';
import {
  PromiseResolveValue, SetQueryDataFnTyped,
  UpdateQueryDataFnWithStatus,
  UpdateQueryFnStatus
} from '../types';
import { getChatListLeadersCMsRequest } from '../api/chat';
import { ChatItemLeaderCMType } from '../api/chat.types';
import {useNotificationContext} from '../PushNotificationsProvider';
import {NO_NOTIFICATIONS_RE_FETCH_INTERVAL} from '../utils/constants';
import { createChatItemUpdated, MessageFactors } from '../utils/chat';
import { getLeaderStatsRequest} from '../api/evangelists';
import { ItemFilter } from './common.types';
import { LeaderStats } from '../api/evangelists.types';

export const QUERY_KEY_CHAT_LIST_LEADERS = 'chatListLeaders';
export const QUERY_KEY_LEADER_STATS = 'leaderStats';

const setQueryDataChatListLeaders: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getChatListLeadersCMsRequest>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_CHAT_LIST_LEADERS, cb);
};

export const updateChatListLeadersItem: UpdateQueryDataFnWithStatus<{
  filter: ItemFilter<ChatItemLeaderCMType>
  newProperties?: Partial<ChatItemLeaderCMType>,
  messagesCountsFactors?: Partial<MessageFactors>,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';

  setQueryDataChatListLeaders(queryClient, (oldQueryData) => {
    if (oldQueryData) {
      const {filter, newProperties, messagesCountsFactors} = options;

      return oldQueryData.map((item) => {
        if (typeof filter === 'string' ? item.id.toString() === filter : filter(item)) {
          status = 'success';

          return createChatItemUpdated(item, newProperties || {}, messagesCountsFactors);
        }

        return item;
      });
    }

    status = 'fail/no-data';

    return oldQueryData;
  }, undefined);

  return {
    status,
  };
};

const chatListLeadersDataDefault: [] = [];

const getLeaderId = (item: ChatItemLeaderCMType) => item.id.toString();

export const useGetChatListLeaders = (config?: {
  enabled?: boolean,
  staleTime?: number,
}) => {
  const {userData} = useMainContext();
  const {isAppReceivesNotifications} = useNotificationContext();

  const {
    isLoading,
    isError,
    refetch,
    data = chatListLeadersDataDefault,
  } = useQuery(QUERY_KEY_CHAT_LIST_LEADERS, () => (
    getChatListLeadersCMsRequest(userData)
  ), {
    refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
    staleTime: isAppReceivesNotifications ? Infinity : 0,
    ...config,
  });

  const get = useGetByIdWithCache(QUERY_KEY_CHAT_LIST_LEADERS, data, getLeaderId);

  return {
    isChatListLeadersLoading: isLoading,
    isChatListLeadersError: isError,
    refetchChatListLeadersItemById: refetch,
    getChatListLeadersItemById: isLoading ? () => undefined : get,
    chatListLeaders: data,
  };
};

const leaderStatDefault: LeaderStats = {
  msgsGraded: 0,
  msgsNoted: 0,
  teamChatsSent: 0,
  privateChatsSent: 0,
  msgsRejected: 0,
};

export const useGetLeaderStats = (leaders?: string[], campaignId?: string) => {
  const { userData } = useMainContext();

  const leaderIdsFinal = leaders ?? [userData.id];

  const queryKey = [QUERY_KEY_LEADER_STATS, leaderIdsFinal.join(','), campaignId];

  const { isLoading, data = {} } = useQuery(queryKey, () => (
    getLeaderStatsRequest(userData, leaderIdsFinal, campaignId)
  ), {
    enabled: !!leaderIdsFinal.length,
  });

  const getLeaderStatsById = (id: string) => data[id] || leaderStatDefault;

  return {
    isLeaderStatsLoading: isLoading,
    leaderStats: data,
    getLeaderStatsById,
  };
};
