import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components';
import Form, { FormSubmitHandler } from '../../../components/form/Form';

import { FieldEmail } from '../../../components/form/Fields';
import Row from '../../../components/row/Row';

export type FormForgotPasswordData = {
  email: string,
}

type FormForgotPasswordProps = {
  initialValues: FormForgotPasswordData,
  onSubmit: FormSubmitHandler<FormForgotPasswordData>,
  onBack: () => void,
}

const FormForgotPassword: React.FC<FormForgotPasswordProps> = ({
  initialValues, onSubmit, onBack
}) => {
  const { t } = useTranslation();

  return (
    <Form
      fields={initialValues}
      onSubmit={onSubmit}
    >
      {
        ({ isSubmitting }) => (
          <>
            <FieldEmail
              labelText={t('labelEmail')}
            />

            <Row gap='lg' directionHorizontal>
              <Button
                color='gray'
                variant='opacity'
                onClick={onBack}
                type='button'>{t('back')
                }</Button>
              <Button
                disabled={isSubmitting}
                type='submit'
              >{t('continue')}</Button>
            </Row>
          </>
        )
      }
    </Form>
  );
};

export default FormForgotPassword;
