import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { useMainContext } from '../MainProvider';
import { useGetByIdWithCache } from '../hooks/useCache';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import {
  getMyCampaignsRequest,
  getListCampaignsRequest,
  modifyCampaignsRequest,
  createCampaignRequest,
  deleteCampaignRequest,
  CreateCampaignRequest,
  updateCampaignRequest,
  UpdateCampaignRequest,
  getConciseListCampaignsRequest,
} from '../api/campaigns';
import { getChatListCampaignsRequest } from '../api/chat';
import {
  ListCampaignsItem,
  ChatItemCampaignType,
  MyCampaign,
  ModifyCampaignsRequestAction,
  ListCampaignsItemConcise,
} from '../api/campaigns.types';
import {
  GetQueryDataFn,
  PageRequestProps,
  PromiseResolveValue,
  SetQueryDataFnTyped,
  UpdateQueryDataFn,
  UpdateQueryDataFnWithStatus,
  UpdateQueryFnStatus,
} from '../types';
import {
  CampaignMember,
  getCampaignMembersRequest,
  InvitedEvangelist,
  InvitedEvangelistAsLeader,
  inviteEvangelistOrLeaderRequest,
  inviteExistedDmAsLeaderRequest
} from '../api/account';
import { useNotificationContext } from '../PushNotificationsProvider';
import { NO_NOTIFICATIONS_RE_FETCH_INTERVAL} from '../utils/constants';
import { createChatItemUpdated, MessageFactors } from '../utils/chat';
import { createPredicateIsShowCampaignInChatList } from '../utils/filters';
import { QUERY_KEY_LEADER_EVANGELISTS } from './evangelists';
import { useParseCampaignMembersByRole } from '../hooks/useParseCampaignMembersByRole';
import { UploadBannerData, uploadCampaignBannerRequest } from '../api/file-storage';
import { Role } from '../utils/enums';
import { useGetAvailableLanguages } from './languages';
import { useGetCountries } from './locations';
import { prepareFullCampaigns } from '../hooks/usePreparedFullCampaigns';
import { isRoleInlcudesCM, isRoleIncludesLeader } from '../utils/profile';
import { ItemFilter } from './common.types';
import { ALL_TEAMS_CAMPAIGN_ID, ALL_TEAMS_ITEM } from '../utils/campaigns';

export const QUERY_KEY_MY_CAMPAIGNS = 'myCampaigns';
export const QUERY_KEY_LIST_CAMPAIGNS = 'listCampaigns';
export const QUERY_KEY_CHAT_LIST_CAMPAIGNS = 'chatListCampaigns';
export const QUERY_KEY_CAMPAIGN_MEMBERS = 'campaignMembers';
export const QUERY_KEY_CONCISE_LIST_CAMPAIGNS = 'conciseListCampaigns';
export const QUERY_KEY_CAMPAIGN_PREPARED_DATA = 'preparedCampaignData';

type QueryDataListCampaigns = PromiseResolveValue<ReturnType<typeof getListCampaignsRequest>>
  | undefined

export const getQueryDataListCampaigns: GetQueryDataFn<QueryDataListCampaigns> = (
  queryClient,
  uiRole,
) =>
  queryClient.getQueryData([QUERY_KEY_LIST_CAMPAIGNS, uiRole]);

const myCampaignsDefault: MyCampaign[] = [];

const getMyCampaignId = (item: MyCampaign) => item.campaignId;

const setQueryDataMyCampaigns: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getMyCampaignsRequest>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_MY_CAMPAIGNS, cb);
};

const setQueryDataListCampaigns: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getListCampaignsRequest>>
> = (queryClient, cb, options, uiRole) => {
  queryClient.setQueryData<any>([QUERY_KEY_LIST_CAMPAIGNS, uiRole], cb);
};

export const updateMyCampaignsItemById: UpdateQueryDataFn<{
  id: string;
  newProperties: Partial<MyCampaign>;
}> = (queryClient, options) => {
  setQueryDataMyCampaigns(queryClient, (oldData) => {
    if (oldData?.length) {
      const optionsId = options.id;

      return oldData.map((myCampaign) => {
        if (myCampaign.campaignId === optionsId) {
          return {
            ...myCampaign,
            ...options.newProperties,
          };
        }

        return myCampaign;
      });
    }

    return oldData;
  }, undefined);
};

export const pushCreatedCampaignToListCampaigns:UpdateQueryDataFn<
  CreateCampaignRequest & Pick<ListCampaignsItem, 'id' | 'website'>
> = (
  queryClient,
  options,
  uiRole,
) => {
  setQueryDataListCampaigns(queryClient, (oldData) => {
    if (oldData?.length) {
      return [...oldData, options as ListCampaignsItem]; // FIXME: remove after API updated
    }

    return oldData;
  }, undefined, uiRole);
};

export const updateListCampaignItemById: UpdateQueryDataFn<{
  id: string;
  newProperties: UpdateCampaignRequest;
}> = (queryClient, options, uiRole) => {
  setQueryDataListCampaigns(queryClient,(oldData) => {
    if (oldData?.length) {
      const optionsId = options.id;

      return oldData.map((item) => {
        if (item.id === optionsId) {
          return {
            ...item,
            ...options.newProperties,
            endDate: options.newProperties.endDate !== 'null'
              ? options.newProperties.endDate as string
              : null,
          };
        }

        return item;
      });
    }

    return oldData;
  }, undefined, uiRole);
};

export const deleteListCampaignById: UpdateQueryDataFn<{ campaignId: string, uiRole: Role }> = (
  queryClient,
  options,
) => {
  setQueryDataListCampaigns(queryClient, (oldData) => {
    if (oldData?.length) {
      return oldData.filter((item) => item.id !== options.campaignId);
    }

    return oldData;
  }, undefined, options.uiRole);
};

export const pushCreatedMyCampaignToMyCampaign: UpdateQueryDataFn<MyCampaign> = (
  queryClient,
  options,
) => {
  setQueryDataMyCampaigns(queryClient, (oldData) => {
    if (oldData?.length) {
      return [...oldData, options];
    }

    return oldData;
  }, undefined);
};

export const deleteMyCampaignsById: UpdateQueryDataFn<{
  campaignIds: string[];
}> = (queryClient, options) => {
  setQueryDataMyCampaigns(queryClient, (oldData) => {
    if (oldData?.length) {
      const removeIds = options.campaignIds;

      return oldData.filter((myCampaign) => {
        return !removeIds.includes(myCampaign.campaignId);
      });
    }

    return oldData;
  }, undefined);
};

export const useGetMyCampaigns = (options?: {
  enabled?: boolean,
}) => {
  const { userData } = useMainContext();

  const {
    data = myCampaignsDefault,
    isLoading,
    isError,
    refetch
  } = useQuery(QUERY_KEY_MY_CAMPAIGNS, () => getMyCampaignsRequest(userData), options);

  const get = useGetByIdWithCache(QUERY_KEY_MY_CAMPAIGNS, data, getMyCampaignId);

  return {
    isMyCampaignsLoading: isLoading,
    isMyCampaignsError: isError,
    getMyCampaignById: isLoading ? () => undefined : get,
    myCampaigns: data,
    refetchMyCampaigns: refetch,
  };
};

// Simply, returns only those campaigns are visible in campaigns chat list.
export const useGetMyCampaignsRoleFiltered = (config?: UseQueryOptions) => {
  const { isUiRoleNotDM } = useMainContext();
  const mayCampaigns = useGetMyCampaigns(config);

  const myCampaignsFiltered = useMemo(() => {
    return mayCampaigns.myCampaigns
      .filter(createPredicateIsShowCampaignInChatList(isUiRoleNotDM));
  }, [mayCampaigns.myCampaigns, isUiRoleNotDM]);

  const get = useGetByIdWithCache(QUERY_KEY_MY_CAMPAIGNS, myCampaignsFiltered, getMyCampaignId);

  return {
    ...mayCampaigns,
    myCampaignsFiltered,
    getMyCampaignsFilteredItemById: get,
  };
};

const getListCampaignsItemId = (item: ListCampaignsItem) => item.id;

type ListCampaignsParams = PageRequestProps & {
  all: boolean;
  extended: boolean;
  summary?: boolean;
  campaignIds?: Array<string>;
  agentToken?: string;
};

export const useGetListCampaigns = (
  params: ListCampaignsParams = { all: true, extended: true },
  options?: {
    enabled?: boolean,
    refetchInterval?: number,
    staleTime?: number,
    keepPreviousData?: boolean,
    cacheTime?: number,
  }
) => {
  const { uiRole, isRoleAdmin, userData } = useMainContext();
  console.log(userData.agentToken)

  const queryKey = [QUERY_KEY_LIST_CAMPAIGNS, uiRole, ...Object.values(params)];


  const result = useQuery<ListCampaignsItem[]>(
    queryKey,
    () => getListCampaignsRequest({ summary: isRoleAdmin, agentToken: userData.agentToken, id: userData.id, ...params }),
    options
  );

  const get = useGetByIdWithCache(queryKey, result.data, getListCampaignsItemId);

  return {
    ...result,
    getItem: get,
  };
};

const useFilteredMyCampaigns = (
  campaigns: ListCampaignsItemConcise[],
  isMyCampaigns: boolean | undefined
) => {
  const { isRoleAdmin, isUiRoleCM, isUiRoleLeader } = useMainContext();
  const { myCampaigns } = useGetMyCampaigns();

  const filteredCampaigns = useMemo(() => {
    if (isRoleAdmin || !isMyCampaigns) {
      return campaigns;
    }
    return campaigns.filter((item) => {
      const myCampaign = myCampaigns.find(
        (campaignItem) => campaignItem.campaignId === item.id
      );
      
      if (!myCampaign) {
        return false;
      }
      
      const campaignRole = myCampaign.role ? myCampaign.role : Role.DM;
      return (
        ((isRoleInlcudesCM(campaignRole) && isUiRoleCM) ||
          (isRoleIncludesLeader(campaignRole) && isUiRoleLeader)) &&
        item.startDate
      );
    });
  }, [isRoleAdmin, isMyCampaigns, campaigns, isUiRoleCM, isUiRoleLeader, myCampaigns]);

  return filteredCampaigns;
};

const conciseListCampaignsDefault = [] as ListCampaignsItemConcise[];

const getConciseListCampaignsItemId = (item: ListCampaignsItemConcise) => item.id;

type ConciseListCampaignsParams = {
  isMyCampaigns?: boolean;
};

export const useGetConciseListCampaigns = (params?: ConciseListCampaignsParams, options?: {
  enabled: boolean;
  refetchInterval?: number;
}) => {
  const { isMyCampaigns } = params || {};
  const { userData, uiRole, isRoleAdmin } = useMainContext();
  
  const { data = conciseListCampaignsDefault, isLoading, isFetching, isError, refetch } = useQuery(
    [QUERY_KEY_CONCISE_LIST_CAMPAIGNS, uiRole],
    () => getConciseListCampaignsRequest(userData),
    options
  );

  const dataFinal = isRoleAdmin ? [ALL_TEAMS_ITEM, ...data] : data;

  const get = useGetByIdWithCache(
    QUERY_KEY_CONCISE_LIST_CAMPAIGNS + (isRoleAdmin ? 'admin' : ''),
    dataFinal,
    getConciseListCampaignsItemId
  );

  const myCampaigns = useFilteredMyCampaigns(dataFinal, isMyCampaigns);

  return {
    isConciseListCampaignsLoading: isLoading,
    isConciseListCampaignsFetching: isFetching,
    isConciseListCampaignsError: isError,
    getConciseListCampaignsItemById: isLoading ? () => undefined : get,
    conciseListCampaigns: myCampaigns,
    refetchConciseListCampaigns: refetch
  };
};

export const useModifyCampaigns = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();

  const modifyCampaignsMutation = useMutation((payload: {
    evangelistId?: string,
    actions: ModifyCampaignsRequestAction[],
    successMsg?: string,
    doNotShowToastOnSuccess?: boolean,
  }) => {
    return modifyCampaignsRequest(userData, payload.actions, payload.evangelistId);
  }, {
    onSuccess: (response, payload) => {
      if (payload.evangelistId) {
        queryClient.invalidateQueries(QUERY_KEY_LEADER_EVANGELISTS);

        const campaignId = payload.actions[0]?.id;
        if (campaignId) {
          queryClient.invalidateQueries([QUERY_KEY_CAMPAIGN_MEMBERS, campaignId]);
        }
      } else {
        queryClient.invalidateQueries(QUERY_KEY_MY_CAMPAIGNS);
      }

      if (!payload.doNotShowToastOnSuccess) {
        showToast({ title: t(payload.successMsg || 'changesSuccess') });
      }
    }
  });

  return {
    modifyCampaignsMutation,
  };
};

const setQueryDataChatListCampaigns: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getChatListCampaignsRequest>>
> = (queryClient, cb) => {
  queryClient.setQueryData<any>(QUERY_KEY_CHAT_LIST_CAMPAIGNS, cb);
};

export const updateChatListCampaignsItem: UpdateQueryDataFnWithStatus<{
  filter: ItemFilter<ChatItemCampaignType>
  newProperties?: Partial<ChatItemCampaignType>,
  messagesCountsFactors?: Partial<MessageFactors>,
}> = (queryClient, options) => {
  let status: UpdateQueryFnStatus = '';

  setQueryDataChatListCampaigns(queryClient, (oldQueryData) => {
    if (oldQueryData) {
      const { filter, messagesCountsFactors, newProperties } = options;

      return oldQueryData.map((item) => {
        if (typeof filter === 'string' ? item.campaignId === filter : filter(item)) {
          status = 'success';

          return createChatItemUpdated(item, newProperties || {}, messagesCountsFactors);
        }

        return item;
      });
    }

    status = 'fail/no-data';

    return oldQueryData;
  }, undefined);

  return {
    status,
  };
};

export const deleteChatListCampaignItemsById: UpdateQueryDataFn<{
  campaignIds: string[];
}> = (queryClient, options) => {
  setQueryDataChatListCampaigns(queryClient, (oldData) => {
    if (oldData?.length) {
      const removeIds = options.campaignIds;

      return oldData.filter((item) => {
        return !removeIds.includes(item.campaignId);
      });
    }

    return oldData;
  }, undefined);
};

const getCampaignItemId = (item: ChatItemCampaignType) => item.campaignId;

const chatListCampaignsDefault: [] = [];

export const useGetChatListCampaigns = (config?: {
  refetchInterval?: number,
}) => {
  const {userData} = useMainContext();
  const {isAppReceivesNotifications} = useNotificationContext();

  const {
    isLoading,
    data = chatListCampaignsDefault,
    refetch,
    isError,
  } = useQuery(QUERY_KEY_CHAT_LIST_CAMPAIGNS, () => {
    return getChatListCampaignsRequest(userData);
  }, {
    refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
    staleTime: 0,
    ...config,
  });

  const get = useGetByIdWithCache(QUERY_KEY_CHAT_LIST_CAMPAIGNS, data, getCampaignItemId);

  return {
    isChatListCampaignsError: isError,
    isChatListCampaignsLoading: isLoading,
    getChatListCampaignById: isLoading ? () => undefined : get,
    chatListCampaigns: data,
    refetchChatListCampaigns: refetch,
  };
};

const campaignMembersDefault: [] = [];

const getCampaignMemberId = (item: CampaignMember) => item.id;

const setQueryDataCampaignMembers: SetQueryDataFnTyped<
  PromiseResolveValue<ReturnType<typeof getCampaignMembersRequest>>
> = (queryClient, cb) => {
  queryClient.setQueriesData<any>([QUERY_KEY_CAMPAIGN_MEMBERS], cb);
};

export const updateCampaignMembersItemById: UpdateQueryDataFn<{
  memberId: string;
  newProperties: Partial<CampaignMember>;
}> = (queryClient, options) => {
  setQueryDataCampaignMembers(queryClient, (oldData) => {
    if (oldData) {
      const oldDataKeys = Object.keys(oldData);
      if (oldDataKeys.length > 0 && oldData[oldDataKeys[0]].members) {
        const optionsId = options.memberId;
        oldData[oldDataKeys[0]].members = oldData[oldDataKeys[0]].members.map((member) => {
          if (member.id === optionsId) {
            return { ...member, ...options.newProperties };
          }
          return member;
        });
      }
    }
    
    return oldData;
  }, undefined);
};

// If need to fetch couple campaigns with the only one request, need to refactor the hook.
export const useGetCampaignMembersRequest = (campaignId: string, config?: {
  enabled: boolean,
}) => {
  const queryKey = [QUERY_KEY_CAMPAIGN_MEMBERS, campaignId];

  const {
    isLoading,
    isError,
    data,
    refetch,
  } = useQuery(queryKey, () => {
    return getCampaignMembersRequest([campaignId]);
  }, {
    enabled: !!campaignId,
    staleTime: 0,
    ...config,
  });

  const campaignMembers = data?.[campaignId]?.members || campaignMembersDefault;

  const get = useGetByIdWithCache(queryKey.toString(), campaignMembers, getCampaignMemberId);
  const campaignMembersRoleParsed = useParseCampaignMembersByRole(campaignMembers);

  return {
    isCampaignMembersLoading: isLoading,
    isCampaignMembersError: isError,
    campaignMembers,
    ...campaignMembersRoleParsed,
    refetchCampaignMembers: refetch,
    getCampaignMemberById: isLoading ? () => undefined : get,
  };
};

export const useMutateCampaignList = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { userData, uiRole } = useMainContext();
  const { showToast } = useToastEnhanced();

  const createCampaignMutation = useMutation((data: CreateCampaignRequest) => (
    createCampaignRequest(userData, data)
  ), {
    onSuccess: (data, variables) => {
      pushCreatedCampaignToListCampaigns(queryClient, {
        ...variables,
        id: String(data.campaignId),
        website: data.campaignData.website,
      }, uiRole);
      pushCreatedMyCampaignToMyCampaign(queryClient, {
        campaignId: String(data.campaignId),
        role: Role.CM,
        active: '1',
      });
    }
  });

  const updateCampaignMutation = useMutation((data: UpdateCampaignRequest) => (
    updateCampaignRequest(userData, data)
  ), {
    onSuccess: (data, variables) => {
      updateListCampaignItemById(queryClient, {
        id: String(variables.campaignId),
        newProperties: {
          ...variables,
        }
      }, uiRole);
    }
  });

  const uploadBannerMutation = useMutation((data: UploadBannerData) => (
    uploadCampaignBannerRequest(userData, data)
  ), {
    onSuccess: (data, variables) => {
      updateListCampaignItemById(queryClient, {
        id: String(variables.campaignId),
        newProperties: {
          banner: data.filename,
        }
      }, uiRole);
    }
  });

  const deleteCampaignMutation = useMutation((campaignId: string) => (
    deleteCampaignRequest(userData, campaignId)
  ), {
    onSuccess: (data, campaignId) => {
      deleteListCampaignById(queryClient, { campaignId, uiRole });
      showToast({ title: t('campaignDeletedMsg') });
    }
  });

  return {
    createCampaignMutation,
    updateCampaignMutation,
    uploadBannerMutation,
    deleteCampaignMutation,
  };
};

export const useInviteEvangelistOrLeaderToCampaign = () => {
  const { userData } = useMainContext();

  const inviteEvangelistOrLeaderToCampaign = useCallback((data:  InvitedEvangelist) => {
    return inviteEvangelistOrLeaderRequest(userData, data);
  }, [userData]);

  const inviteEvangelistAsLeaderToCampaign = useCallback((data: InvitedEvangelistAsLeader) => {
    return inviteExistedDmAsLeaderRequest(userData, data);
  }, [userData]);

  return {
    inviteEvangelistOrLeaderToCampaign,
    inviteEvangelistAsLeaderToCampaign,
  };
};

const getQueryKeyCampaignPreparedDataWithId = (id: string) => {
  return [QUERY_KEY_CAMPAIGN_PREPARED_DATA, id];
};

const HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

export const useGetCampaignPreparedData = (id: string) => {
  const isAllTeams = id === ALL_TEAMS_CAMPAIGN_ID;
  const {userData} = useMainContext();
  const { myCampaigns, isMyCampaignsLoading } = useGetMyCampaigns();
  const { getAvailableRequestLanguageByLanguage } = useGetAvailableLanguages();
  const { getCountryByAbbreviation } = useGetCountries();
  const { conciseListCampaigns } = useGetConciseListCampaigns(
    undefined,
    { enabled: isAllTeams }
  );

  const {
    data = [],
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(getQueryKeyCampaignPreparedDataWithId(id), () => (
    getListCampaignsRequest({
      all: true,
      extended: true,
      summary: isAllTeams,
      summaryOnly: isAllTeams,
      campaignIds: isAllTeams ? [] : [id || ''],
      agentToken: userData.agentToken,
      id: userData.id
    }, conciseListCampaigns)
  ), {
    enabled: !!id && conciseListCampaigns.length > 1,
    staleTime: HOUR_IN_MILLISECONDS,//1 hour
    refetchInterval: HOUR_IN_MILLISECONDS
  });
  
  const preparedFullCampaigns = useMemo(() => {
    return prepareFullCampaigns(
      data,
      myCampaigns,
      getAvailableRequestLanguageByLanguage,
      getCountryByAbbreviation,
    );
  }, [data, myCampaigns, getCountryByAbbreviation, getAvailableRequestLanguageByLanguage]);

  return {
    campaignData: data?.length > 0 ? preparedFullCampaigns[0] : null,
    isCampaignDataLoading: isMyCampaignsLoading || isLoading,
    isCampaignDataFetching: isFetching,
    isCampaignDataError: isError,
    refetchCampaignData: refetch
  };
};
