import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';
import useDebounce from '../../hooks/useDebounce';
import { 
  checkPasswordLength, 
  checkPasswordLettersCases, 
  checkPasswordNumberExistence 
} from '../form/Form.utils';
import Row from '../row/Row';
import Typography from '../typography/Typography';

import './password-validity-view.scss';

const PASSWORD_TIMEOUT = 1000;

type PasswordValidityType = {
  isLetterCaseMatch: boolean;
  isNumberExistMatch: boolean;
  isLengthMatch: boolean;
}

const passwordValidityStateDefault: PasswordValidityType = {
  isLetterCaseMatch: false,
  isNumberExistMatch: false,
  isLengthMatch: false
};

interface PasswordValidityViewProps {
  password: string
}

const PasswordValidityView: React.FC<PasswordValidityViewProps> = ({ password }) => {
  const { t } = useTranslation();
  const [passwordValidity, setPasswordValidity] = useState<PasswordValidityType>(
    passwordValidityStateDefault
  );
  
  const debouncedPassword = useDebounce(password, PASSWORD_TIMEOUT);

  useEffect(() => {
    const letterCaseCheckResult = checkPasswordLettersCases(debouncedPassword);
    const numberExistCheckResult = checkPasswordNumberExistence(debouncedPassword);
    const lengthCheckResult = checkPasswordLength(debouncedPassword);

    setPasswordValidity({
      isLetterCaseMatch: letterCaseCheckResult.valid,
      isNumberExistMatch: numberExistCheckResult.valid,
      isLengthMatch: lengthCheckResult.valid
    });
  }, [debouncedPassword]);

  const renderValidityRuleRow = (label: string, isRuleMatches: boolean) => {
    return (
      <Typography
        color={isRuleMatches ? 'green' : 'red-dark'}
        component="h5"
        className="password-rule__item">
        <FontIconView
          icon={isRuleMatches ? AeFontIcons.check : AeFontIcons.close}
          className="password-rule__icon"
        />
        {t(label)}
      </Typography>
    );
  };
  
  return (
    <Row gap='extra-sm' className='password-rule__wrap'>
      <Typography component="h4" color='gray-dark'>{t('yourPasswordNeedsTo')}</Typography>
      {renderValidityRuleRow('msgCaseMatch', passwordValidity.isLetterCaseMatch)}
      {renderValidityRuleRow('msgNumberMatch', passwordValidity.isNumberExistMatch)}
      {renderValidityRuleRow('msgLengthMatch', passwordValidity.isLengthMatch)}
    </Row>
  );
};

export default PasswordValidityView;
