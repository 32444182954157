import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import {createLocalStorageManager} from '../../utils/storage';
import {Loader, Row} from '../index';
import Scroll from '../scroll/Scroll';
import AudioPlayerProvider from '../audioplayer/AudioPlayerProvider';
import SplitPane from '../../third-party/split-panes/SplitPane';

import styles from './ChatWrapper.module.scss';

type ChatPageWrapperProps = {
  isFetching?: boolean,
  isLoading: boolean,
  chatId: string,
  storageId: string,
  messageElementsSlot: ReactNode,
  chatElementsSlot: ReactNode,
};

const ChatWrapper: React.FC<ChatPageWrapperProps> = (props) => {
  const { isLoading } = props;

  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);
  const [chatItemsScrollbars, setChatItemsScrollbars] = useState<any | null>(null);

  const splitPaneLocalStorage = useMemo(() => {
    return createLocalStorageManager<number>('splitPane' + props.storageId);
  }, [props.storageId]);

  const rootOffsetWidth = rootElement?.offsetWidth;
  // Min width is 1/4, but not less than 320.
  const splitPaneMinSize = Math.max(320, rootOffsetWidth ? rootOffsetWidth / 4 : 0);

  const scrollChatListToItemById = useCallback((id) => {
    const chatItemsScrollbarsView = chatItemsScrollbars?.view;

    if (chatItemsScrollbarsView) {
      const replyMessageItem = chatItemsScrollbarsView.querySelector(
        '[data-id="' + id + '"]'
      );
      if (replyMessageItem) {
        chatItemsScrollbarsView.scroll({ top: replyMessageItem.offsetTop, behavior: 'smooth' });
      }
    }
  }, [chatItemsScrollbars]);

  // Scrolls to the current item after list is loaded.
  useEffect(() => {
    if (!isLoading) {
      scrollChatListToItemById(props.chatId);
    }
    // Dependencies are missing intentionally.
  }, [isLoading, scrollChatListToItemById]); // eslint-disable-line

  return (
    <AudioPlayerProvider>
      <div
        ref={(el) => {
          if (el) {
            setRootElement(el);
          }
        }}
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <SplitPane
          size={splitPaneMinSize}
          minSize={splitPaneMinSize}
          defaultSize={splitPaneLocalStorage.load() || splitPaneMinSize}
          // Max width is 3/4, but not greater than 400.
          maxSize={Math.min(400, rootOffsetWidth ? rootOffsetWidth * 0.75 : Infinity)}
          onChange={splitPaneLocalStorage.save}
        >
          <Scroll
            ref={setChatItemsScrollbars}
            className={classnames(styles['list-wrap'], {
              [styles['fetching']]: props.isFetching && !props.isLoading,
            })}
            staticScrollView
          >
            {isLoading ? <Loader /> : props.chatElementsSlot}
          </Scroll>
          <Row layout='flex' style={{ height: '100%' }}>
            {props.messageElementsSlot}
          </Row>
        </SplitPane>
      </div>
    </AudioPlayerProvider>
  );
};

export default ChatWrapper;
